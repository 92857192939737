.content-row {
	display: flex;
	background: $color-white;

	&:hover {
		* {
			text-decoration: none;
		}

		& > a {
			border-color: $brand-purple;
		}

		.content-info {
			.content-type {
				.link-text {
					@extend .int-link;
					font-size: inherit;
					line-height: inherit;
					display: block;
				}
			}
		}
	}

	& > a {
		display: flex;
		width: 100%;
		padding: 1.25rem;
		border: 1px solid $color-gray-10;
	}

	.content-thumbnail {
		margin-right: 0.625rem;

		img {
			width: 40px;
			height: 40px;
			box-sizing: border-box;
			border: 2px solid $color-gray-10;
			border-radius: 2px;
		}
	}

	.content-info {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-right: 0.625rem;

		.content-type {
			display: flex;
			align-items: center;
			margin-bottom: 0.375rem;
			font-size: 0.625rem;
			font-weight: normal;
			line-height: 0.875rem;
			color: $color-gray-12;

			span {
				margin-left: 0.375rem;
				font-size: 0.625rem;
				text-transform: capitalize;
			}

			.link-text {
				display: none;
				font-weight: 400;
				color: $color-blue;
			}
		}

		.content-name {
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 1.1875rem;
		}
	}

	.content-relevancy {
		flex-shrink: 0;
		margin-top: 0.5rem;

		span {
			font-size: 1rem;
			font-weight: 600;
			line-height: 1.375rem;

			&.high-relevancy {
				color: $color-green;
			}
		}

		.match-info {
			&:hover {
				cursor: pointer;
			}
		}
	}
}
