.insights-page {
	padding: 1.875rem 2.5rem;

	.insights-page-header-row {
		.insights-row {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.export-menu {
				&.insights {
					.dropdown-toggle {
						font-size: .9rem;
						padding: .5rem 1.5rem;
						font-weight: 600;
						text-align: center;
						min-width: 190px;
						margin-left: auto;
					}
				}

				.export-link {
					.export-type {
						display: flex;
						justify-content: center;
						padding: 4px 0px;
						font-weight: normal;
	
						&:hover {
							background-color: $color-gray-11;
						}
					}	

					&.disabled {
						cursor: not-allowed;
					}	
	
				}
			}
		}
		.metric-date-range {
			margin-top: 2.375rem;
		}
	}
}
