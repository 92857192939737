.four-oh-three {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;
  
    > .fot-topbar {
      height: 10%;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 30px 0 30px;
      align-self: flex-start;
  
      .ab-fot-home-btn {
        color: $brand-font-color;
        text-decoration: none;
  
        &:visited {
          text-decoration: none;
        }
  
        > .fot-go-home-logo-btn {
          margin: 0;
          font-size: 20px;
          color: $brand-font-color;
        }
      }
    }
  
    > .fot-content {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: calc(100vh - 140px);
  
      > .fot-content-left {
        display: flex;
        flex: 1;
        flex-direction: column;
  
        > .fot-message {
          font-size: 26px;
          margin-top: 0;
          opacity: 0.75;
          max-width: 500px;
        }
  
        > .fot-error-code {
          font-size: 18px;
          opacity: 0.5;
          margin-top: 0;
        }
  
        .fot-go-home-btn {
          width: 200px;
          margin: 15px 0 0 0;
          height: 45px;
  
          > .fot-go-home-text {
            font-size: 14px;
          }
        }
      }
  
      .fot-content-right {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
  
        > .fot-illustration {
          height: auto;
          max-width: 500px;
        }
      }
    }
  }
  