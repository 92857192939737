$max-width: 800px;

.option-header {
    text-align: center;
    flex: 1;    
  }

.trans-option {
  height: 90%;
  margin: 1em 0 1em 0;
  border: 2px solid $color-gray-4;
  cursor: pointer;
  
  .card-body {
    display: flex;
    flex-direction: column;
  }
  
  .option-icon {
    margin-bottom: 1em;
    margin-left: auto;
    // margin-bottom: 1.5em;
  }

  &:hover {
    border-color: $color-gray-7;
  }
  
  &.hidden {
    display: none;
  }
}

.trans-option-text {
  
  &.hidden {
    display: none;
  }
}

.option-icon {
  height: 20px;
  width: 20px;
  margin: 0 5px 0 0;
}

.options-footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 10px 12px 10px;
  margin: 50px 0 0 0;

  .options-cancel-btn {
    width: 80px;
    text-transform: uppercase;
    height: 33px;
    margin: 0 6px;
  }

  .options-next-btn {
    margin: 0 0 0 20px;    
    height: 33px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .options-add-credits-btn {
    margin: 8px;
    cursor: pointer;
    outline: none;
    height: 50px;
    border: none;
    border-radius: 3px;
    transition: all 0.25s ease;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.84px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 12px;
    background-color: transparent;
    box-shadow: none;
    color: $brand-green;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }

  .options-action-btn {
    margin: 0 0 0 20px;
    background-color: $brand-purple;
    height: 33px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
 }

 .credits-description {
   margin: 30px 0 50px 0;
 }
 
 .credits-card {
   background: $color-gray-5;
   border-radius: 5px;
   padding: 1.5em;
   
   &.credits--total-credits {
     margin-bottom: 1em;
   }
   
   &.credits--cost {
     margin-bottom: 1em;
   }
 }

 .credits-icon {
   font-size: 1.2rem;
   margin-top: -2px;
   margin-right: .5em;
 }
 
 .credits-label {
   font-size: 1rem;
   font-weight: 300;
 }

 .credits-number {
   font-size: 18px;
   text-align: right;
   font-weight: bold;
 }

 .purchase-option {
  margin: 25px 0;
  cursor: pointer;
  text-align: center;
}

.upload-label {
  font-size: 11px;
  margin: 50px 0 7px 2px;
  text-transform: uppercase;
  font-weight: 600;
  color: $color-gray-8;
  letter-spacing: 1.37px;
}

.close-icon {
  position: absolute;
  top: 40px;
  right: 40px;
  color: $color-gray-8;
  height: 24px;
  width: 24px;
  cursor: pointer;

  &:hover {
    color: $brand-purple;
  }
}

.back-button {
  color: $brand-purple;
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  left: 40px;

  .back-icon {
    height: 16px;
    width: 16px;        
  }

  .back-label {
    padding-left: 10px;
  }
}

.transcript-wizard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 100px;
  position: relative;
}

.success-modal {
  position: relative;
  padding: 2em 2em 0 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .success-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: $brand-purple;
    background: $brand-light-purple;
    svg {
      font-size: 1.5rem;
    }
  }

  h3 {
    margin-bottom: 1em;
  }

  .confirm-btn {
    height: 33px;
    padding-left: 16px;
    padding-right: 16px;
  }
}