.new-show-manual-step {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;

  &.hidden {
    display: none;
  }

  .new-show-details-header-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    .new-show-details-header {
      margin: 10px 0 15px 0;
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      flex: 1;
    }

    .new-show-details-description {
      margin: 0;
      font-size: 14px;
      text-align: center;
      max-width: 70%;
      line-height: 21px;
      color: $color-gray-6;
    }
  }

  .new-show-details-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    padding: 30px 45px 25px 45px;

    .new-show-details-input-label {
      font-size: 11px;
      font-weight: 600;
      margin-bottom: 7px;
      margin-left: 2px;
      text-transform: uppercase;
    }
    .error {
      font-size: 11px;
      color: $alert-red;
    }

    .new-show-details-help-text {
      margin: 0 20px 5px 0;
      font-size: 12px;
      color: $color-gray-6;
    }

    .new-show-details-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      .new-show-details-row-col {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 1rem;

        &.row-col-large {
          flex: 3;
        }

        .new-show-details-form-input {
          padding: 11px 10px;
          font-size: 13px;
          border: 1px solid $color-gray-2;
          background-color: $color-off-white;
        }

        .new-show-details-description-textarea {
          border-radius: 3px;
          font-size: 13px;
          border: 1px solid $color-gray-2;

          .quill {
            padding-bottom: 40px;

            .ql-toolbar {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
            }
          }
        }

        .new-show-details-keywords {
          margin-bottom: 10px;
        }
      }
    }
  }

  .new-show-actions-container {
    display: flex;
    flex-direction: column;

    .new-show-actions-row {
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        height: 36px;
        text-transform: uppercase;
        padding: 4px 20px;
        display: flex;
        align-items: center;

        &.new-show-rss-btn {
          text-transform: none;
          font-size: 13px;
          font-weight: 500;
          margin-top: 13px;
          text-decoration: underline;
          letter-spacing: 0;

          &:hover {
            color: $brand-purple;
          }
        }
      }
    }
  }
}
