.transcript-nav {
    background: $color-white;
    padding: .5em;

    .export-menu {
        .dropdown-toggle::after {
            display: none;
        }
    }

    button:focus,
    button:hover,
    button:active,
    .show > .btn-primary.dropdown-toggle {
        background-color: $color-gray-4;
        color: $brand-font-color;
        border: none;
        text-decoration: none;
    }

    button,
    .generating-in-progress,
    .generating-error {
        font-size: 14px;
        font-weight: 600;
    }

    button {
        background: $color-white;
        border: $color-white;
        color: $brand-font-color;
        margin: 0 3px;
        transition: .1s all ease-in;

        &:disabled {
            background: $color-white;
            border: $color-white;
            color: $color-gray-12;
        }

        &.discard-changes {
            border: 1px solid $brand-red;
        }

        &.discard-changes,
        &.discard-changes svg {
            color: $brand-red;
        }

        &.save-changes {
            border: 1px solid $brand-green;
        }

        &.save-changes,
        &.save-changes svg {
            color: $brand-green;
        }

        &.discard-changes:hover,
        &.save-changes:hover {
            color: $color-white;
            transition: .1s all ease;

            svg {
                color: $color-white;
                transition: .15s all ease;
            }
        }

        &.discard-changes:hover {
            background: $brand-red;
        }

        &.save-changes:hover {
            background: $brand-green;
        }

        svg {
            margin: 0 0 3px 3px;
            color: $brand-font-color;
        }

        &#generate-suggestions {
            border: 1px solid $ai-blue;
            color: $ai-blue;

            svg {
                margin: 0 3px 0 0;
            }
        }
    }

    .options-dropdown {
        label {
            white-space: nowrap;
            overflow: visible;
        }

        #ai-sparkle {
            margin: 0px 3px;
        }
    }

    .generating-in-progress {
        color: $ai-blue;

        .spinner-grow {
            height: 1em;
            width: 1em;
            margin-right: 3px;
        }
    }

    #generate-error-icon {
        color: $brand-red;
        margin-right: 3px;
    }
}

#generate-error-tooltip {
    
    * {
        color: $color-white;
    }

    p {
        margin: 0;
    }
}

#ai-toast-link {
    text-decoration: underline;
    font-weight: 700;
}