.collection-permissions-toggle {
	color: #007994;
	display: flex;
	align-items: center;
	font-weight: 600;

	.toggle-icon {
		margin-right: 4px;
	}

	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.collection-permissions-tooltip {
	cursor: pointer;
	&.tooltip.show {
		opacity: 1 !important;
		.arrow {
			display: none !important;
		}
	}

	&.tooltip {
		.arrow {
			display: none !important;
		}
	}

	.tooltip-inner {
		min-width: 325px;
		background: white;
		color: black;
		border: 1px solid #c4c4c4;
		border-radius: 4px;
		padding: 5px 0px;

		.list-group {
			.collection-permissions-list {
				border: none !important;
				padding: 12px 20px;
				text-align: left;
				p {
					margin-bottom: 0;
				}
				.role--name {
					font-weight: bold;
				}
			}
			:hover {
				background: #e9ecef;
			}
		}
	}
}
