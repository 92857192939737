.well {
  box-sizing: border-box;
  padding: 16px 16px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .well-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .well-label {
    color: $color-gray-8;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.7px;
  }

  .well-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}