.new-show-collection-permissions-modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 500px;

	&.hidden {
		display: none;
	}

	.user-search-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		.user-search-input {
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			border: 1px solid $color-gray-10;
			border-radius: 0.375em;
			outline: none;
			min-height: 2.625rem;
			padding-left: 1rem;

			input {
				font-size: 1em;
				border: none;
				outline: none;
				width: 99%;
				padding: 1rem 0.3rem;
			}
		}

		.user-search-results {
			z-index: 1;
			position: absolute;
			top: 3.625rem;
			width: 100%;
			background: #f2f2f2;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
			padding: 0.15rem 1rem;
		}
	}

	.user-permissions-list-container {
		height: 80%;
		width: 95%;
		padding-top: 1rem;
		overflow-y: scroll;
	}

	.user-result-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		border-bottom: 1px solid $color-gray-10;

		&:nth-last-child(1) {
			border: none;
		}

		.user-result-empty {
			padding: 0.5rem;
		}

		.user-list-action {
			padding-right: 0.25rem;
		}

		.user-info {
			min-height: 3.5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.user-name {
				text-transform: none;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 25px;
			}

			.user-email {
			}
		}

		.dropdown-role-action {
			border: none;
			background: none;
			position: unset;

			.action-label {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 22px;
				text-transform: none;
				padding-right: 0.5rem;
				color: #007994;
			}

			.action-icon {
				color: #007994;
			}
		}

		.dropdown-role-choice {
			text-transform: none;
			.role-choice-name {
				text-transform: none;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
			}

			.role-choice-description {
				text-transform: none;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
			}

			.remove-role {
				color: red;
				border-top: 1px solid $color-gray-10;
				padding-top: 0.5rem;
			}
		}

		.user-search-action {
			display: flex;
			flex-direction: row;
			align-items: center;
			color: #007994;

			.action-label {
				padding-left: 0.5rem;
			}
		}
	}

	.user-permissions-save-btn {
		padding: 0.5rem 3rem;
	}
}
