.new-collection-wrapper {
	margin: 0 -1.5rem -1.5rem -1.5rem;

	.collection-types-wrapper {
		display: flex;
		flex-direction: row;
		flex-basis: 100%;
		justify-content: center;
		background-color: $brand-background;
		padding: 0 1.5em;

		.collection-type {
			flex-basis: 50%;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 0em 0.75em;
			justify-content: space-between;

			&.border-left {
				padding-left: 1.625em;
			}

			&.border-right {
				padding-right: 1.625em;
			}

			.collection-type-details {
				display: flex;
				flex-direction: column;

				div {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: row;

					.header {
						justify-content: center;
						font-size: 1em;
						font-weight: 600;
						padding: 1.25em 0;
					}

					.icon {
						margin: 0.5em;
						flex-shrink: 0;
					}
				}

				img {
					width: 1em;
				}

				.collection-type-detail {
					padding-bottom: 1.5em;

					&.option-three {
						padding-bottom: 3em;
					}
				}
			}

			.new-collection-btn {
				width: 100%;
			}

			.collection-type-button {
				display: flex;
				justify-content: center;
				width: 100%;
				padding-bottom: 1.875em;
			}
		}
	}
}
