.episode-list--episode {
	.episode--row {
		padding: 1em 0;
		margin: 0 1px 1em;
		background: $color-white;
		border: 1px solid $color-gray-2;
		border-radius: 4px;
		align-items: center;
		justify-content: space-between;
	}

	.episode--thumbnail {
		width: 100%;
		max-width: 70px !important;
		height: auto;
		border-radius: 2px;
	}

	.episode--name {
		margin-bottom: 0;
	}
	.episode--duration {
		font-size: 0.8rem;
		color: $color-gray-7;
		opacity: 0.75;
	}
	.episode--pub-date {
		font-size: 0.8rem;
		color: $color-gray-7;
		opacity: 0.75;
	}

	.episode--status {
		display: flex;
		align-items: center;
		justify-content: center;

		&.status--syncing {
			justify-content: space-around;
			width: 400px;
		}

		* {
			margin-left: 0.5rem;

			&:first-child {
				margin-left: 0;
			}
		}

		.upload-type {
			display: flex;
			background: $color-gray-4;
			color: darken($color-gray-4, 35%);
		}

		.status--sync-step {
			text-transform: uppercase;
			font-size: 0.8rem;
			color: $color-gray-7;
			opacity: 0.75;
		}
		.progress {
			margin-left: 1em;
			flex: 1;
			border-radius: 2em;
		}
	}

	.episode--public-status {
		&.badge-light {
			background: $color-gray-4;
		}
	}
	.episode--sync-action {
		margin-left: 1em;
	}

	.episode--featured .hover {
		background-color: #4c446b;
	}

	.episode--actions {
		text-align: right;
		padding-right: 0.5em;
		.dropdown-toggle {
			display: inline-block;
			border-radius: 100%;
			width: 30px;
			height: 30px;
			text-align: center;
			align-items: center;
			border: 1px solid $color-gray-7;
			color: $color-gray-7;
			cursor: pointer;
			transition: all 0.1s ease-in-out;
			svg {
				margin-top: 1px;
			} // don't ask me how I can tell, but I can
			&:hover {
				background: $color-gray-7;
				color: $color-white;
			}
			&:after {
				content: none;
				display: none;
			}
		}
	}
}

// When episode is inactive or loading
.episode-list--episode {
	&:hover {
		.episode--row {
			border-color: $color-gray-2;
		}
	}

	.episode--row {
		background: rgba($color-white, 0.6);
	}
	.episode--thumbnail,
	.episode--name,
	.episode--duration {
		opacity: 0.5;
	}
	.episode--pub-date {
		opacity: 0.5;
	}
}

// When episode is ready
.episode-status--active,
.episode-status--indexing,
.episode-status--downloading,
.episode-status--converting,
.episode-status--transcribing,
.episode-status--queued.hosted {
	&:hover {
		.episode--row {
			cursor: pointer;
			pointer-events: all;
			border-color: $brand-purple;
		}
	}

	.episode--row {
		background: $color-white;
	}
	.episode--thumbnail,
	.episode--name,
	.episode--duration {
		opacity: 1;
	}
	.episode--pub-date {
		opacity: 1;
	}
}
