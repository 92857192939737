.casted-form {
	&.form {
	}
	&.form-row {
	}
	&.form-group {
		margin-bottom: 1.5rem;
	}
	&.form-control {
		font-size: 0.875rem;
		line-height: 0.75rem;
		border: 1px solid $color-gray-11;
		border-radius: 3px;
	}
	&.form-text {
	}
	&.form-check {
	}
	&.custom-switch {
		.custom-control-input:checked ~ .custom-control-label::before {
			border-color: $brand-green;
			background-color: $brand-green;
		}
	}
	.sub-label {
		font-size: 0.75rem;
		padding-top: 0.25rem;
	}
}
