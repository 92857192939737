.integration-scopes-modal {
	
	.scope-modal-dialouge {
		margin-top: 1em;
		margin-bottom: 40px;
		text-align: center;
		text-transform: none;
	}

	.scope-form-row {
		margin-bottom: 14px;
	}

	.scope-details-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		border: 1px solid #bebebe;
		box-sizing: border-box;
		border-radius: 8.14226px;

		.scope-label {
			width: 100%;
			padding: 5px;
		}

		.scope--check {
			.form-check-input:checked {
				background-color: $color-green-1;
				border-color: $color-green-1;
			}
		}
	}

	.scope-submit-button {
		margin-top: 28px;
		margin-bottom: 10px;
		width: 100%;
		height: 49px;
	}
}
