.tags-input {
	background-color: $color-off-white;
	overflow: hidden;
	padding: 6px 10px;
	border: 1px solid $color-gray-2;
	border-radius: 3px;
	display: flex;
	min-height: 96px;

	> span {
		flex: 1;
	}

	.tags-input-input {
		background: transparent;
		border: 0;
		font-size: 11px;
		outline: none;
		padding: 5px 0;
		flex: 1;
		align-self: stretch;
		width: 100%;
		max-width: 200px;

		&::placeholder {
			opacity: 0.6;
		}
	}

	.tags-input-tag {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: $brand-purple;
		padding: 3px 9px;
		border-radius: 4px;
		color: $color-white;
		display: inline-block;
		font-size: 13px;
		font-weight: 400;
		margin-right: 6px;
		margin-bottom: 5px;
		flex: 1;
	}

	.tags-input-remove-tag {
		cursor: pointer;
		font-weight: bold;

		a::before {
			content: ' ×';
		}
	}
}
