.episode-info {
	.section-heading {
		h5 {
			margin-bottom: 0.25rem;
		}

		p {
			color: #7c8a95;
		}

		.capitalize {
			text-transform: capitalize;
		}
	}

	.generate-all-btn {
		padding: 10px 15px;
	}

	.generate-all-btn {
		padding: 10px 15px;

		&:disabled {
			cursor: not-allowed;
		}
	}
	.transcript-error-icon {
		margin-left: 3px;
	}

	.show-notes--editor {
		height: 300px;
		position: relative;
	}

	.meta--editor {
		position: relative;
		// below copied from/adjusted to match quill editors
		line-height: 1.42;
		white-space: pre-wrap;
    	word-wrap: break-word;
		::-webkit-input-placeholder {
			font-style: italic;
			color: rgba(0,0,0,0.6);
		}
		:-moz-placeholder {
			font-style: italic;  
			color: rgba(0,0,0,0.6);
		}
		::-moz-placeholder {
			font-style: italic;  
			color: rgba(0,0,0,0.6);
		}
		:-ms-input-placeholder {  
			font-style: italic; 
			color: rgba(0,0,0,0.6);
		}
		* {
			font-family: Helvetica, Arial, sans-serif;
			font-size: 13px;
			color: $brand-font-color;
		}
	}

	.upload-wizard-open-zone {
		padding: 1rem;
		cursor: pointer;

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 120px;
			color: rgba(0, 0, 0, 0.6);
			font-size: 0.6875rem;
			line-height: 1.0625rem;
			text-align: center;
			font-weight: 700;
			text-transform: uppercase;
			background: $brand-background;
		}
	}

	.rss-audio-uploader {
		.replace-rss-label {
			cursor: pointer;
			margin-top: 10px;
			color: rgba(0, 0, 0, 0.6);
			font-size: 1rem;
		}
	}

	.episode-uploader {
		height: 300px;

		.file-uploader,
		.file-container,
		.file-edit-overlay {
			height: 120px;
		}
	}

	.episode-info--player {
		margin-bottom: 2em;

		.video-player {
			margin-bottom: 0.5em;
		}
	}

	.explicit-option {
		display: flex;
	}

	.thumbnail-uploader:not(.guest-image-upload) {
		width: 100%;
		min-height: 400px;
		margin-right: 0;

		.thumbnail-upload-overlay {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.6);
			color: $color-white;
			font-size: 11px;
			line-height: 17px;
			text-align: center;
			font-weight: 700;
			text-transform: uppercase;
		}

		&.hosted:hover {
			cursor: pointer;

			.thumbnail-upload-overlay {
				display: flex;
				pointer-events: none;
			}
		}

		.thumbnail-container {
			width: 100%;
			height: 100%;
			background: $color-gray-2;
			border: 2px dashed transparent;
			margin-bottom: 0;
			color: $color-gray-6;

			&.dragging {
				border-color: $brand-green;
			}
		}
	}

	.generate-item-info {
		position: absolute;
		right: 20px;
		top: 50%;

		.generate-spinner {
			max-height: 1.5em;
			max-width: 1.5em;
		}

		#generate-error-icon {
			color: $brand-red;
			margin-right: 3px;
		}

		.generate-title-button {
			&:hover {
				cursor: pointer;
				
				svg {
					color: $ai-blue;
				}
			}
		}
	}

	.edit-episode-name {
		margin-left: auto;
		margin-right: 1em;
		.toggle-edit--edit .icon-circle {
			color: $color-white;
			border-color: $color-white;
		}
	}

	.remove-item {
		border: 1px solid;
		border-radius: 100%;
		width: 1.5em;
		height: 1.5em;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 0;
		transition: background-color 0.1s ease, color 0.15s ease;

		&:hover {
			background: $brand-primary;
			color: $color-white;
			cursor: pointer;
		}
	}

	.remove-item--dropdown {
		display: none;
		position: absolute;
		top: 1.5em;
		right: 0;
		z-index: 999;
		background-color: $color-white;
		box-sizing: border-box;
		border: 1px solid #dddddd;
		border-radius: 4px;
		text-align: center;
		transition: background-color 0.1s ease, color 0.15s ease;

		p {
			margin: 0;
			padding: 0.5em 1em;
			font-size: 16px;
			transition: 0.3s all ease;
		}

		&.visible {
			display: block;
			box-shadow: $box-1;

			&:hover {
				background: $brand-primary;
				color: $color-white;
				cursor: pointer;
			}
		}
	}

	.add-item {
		border: 1px solid $color-gray-2;
		border-radius: 3px;
		background-color: $color-white;
		transition: 0.1s all ease-in;
		max-height: 115px;

		&:hover {
			transform: translate(0px, -3px);
			-webkit-box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
			-moz-box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
			box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
			cursor: pointer;
		}

		.add-item--inner {
			background-color: $color-gray-2;
			text-align: center;

			p {
				margin: 0;
				font-weight: 700;
			}

			.add-item--icon {
				font-size: 24px;
			}

			p,
			.add-item--icon {
				color: $color-gray-8;
				opacity: 0.5;
			}
		}
	}

	.description-and-tags {
		padding-top: 40px;

		.section-heading {
			font-size: 14px;
			letter-spacing: 1px;
			font-weight: 700;
			margin-bottom: 20px;
			&.nav-link {
				margin-right: 20px;
				margin-bottom: 0.75em;
				padding: 0 0.5em 0.25em;
				text-transform: uppercase;

				&.active {
					border-bottom: 3px solid $color-gray-8;
				}
				&:focus {
					outline: none;
				}
				svg {
					margin-top: -3px;
				}
			}
		}

		.episode-tags {
			width: 100%;
			overflow: hidden;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;

			.tag {
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: $brand-light-purple;
				padding: 6px 8px;
				margin-right: 8px;
				margin-bottom: 8px;
				border-radius: 3px;
				color: $brand-dark-purple;
				display: inline-block;
				font-size: 14px;
				font-weight: 500;

				.remove-tag {
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}
	}

	.hint {
		font-style: italic;
		font-size: 12px;
	}
}

#generate-summary-modal {

	.modal-body {
		padding: 0 2em;
	}
}
