.new-show-rss-step {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;

  &.hidden {
    display: none;
  }

  .new-show-details-header-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    .new-show-details-header {
      margin: 10px 0 15px 0;
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      flex: 1;
    }

    .new-show-details-description {
      margin: 0;
      font-size: 14px;
      text-align: center;
      max-width: 70%;
      line-height: 21px;
      color: $color-gray-6;
    }
  }

  .new-show-details-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    padding: 30px 45px 10px 45px;

    .new-show-details-input-label {
      font-size: 11px;
      font-weight: 600;
      margin-bottom: 7px;
      margin-left: 2px;
      text-transform: uppercase;
    }

    .new-show-details-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      align-items: flex-end;

      .new-show-details-row-col {
        flex-basis: 78%;

        .new-show-details-form-input {
          padding: 9px 10px;
          font-size: 13px;
          border: 1px solid $color-gray-2;
          background-color: $color-off-white;
        }
      }
      
    }
  }
  
  .new-show-rss-button {
    
    .new-show-save-btn {
      margin: 1em auto !important;
      height: 39px;
      text-transform: uppercase;
      padding: 2px 15px;
      display: flex;
      align-items: center;
    }
    
    .button-error {
      background-color: $brand-red;
    }
  }
  
  .errorMsg {
    margin: 1em auto;
    color: $brand-red;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }

  .new-show-actions-container {
    display: flex;
    flex-direction: column;

    .new-show-actions-row {
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        height: 36px;
        text-transform: uppercase;
        padding: 4px 20px;
        display: flex;
        align-items: center;

        &.new-show-manual-btn {
          text-transform: none;
          font-size: 13px;
          font-weight: 500;
          text-decoration: underline;
          letter-spacing: 0;

          &:hover {
            color: $brand-purple;
          }
        }
      }
    }
  }
}