.clip-engagement-container {
	display: flex;
	flex-direction: column;

	.clip-dropdown {
		justify-content: normal;
		margin-top: 18px;
		display: inline-flex;

		.casted-dropdown-button {
			&.clip-options {
				.btn {
					background: #ffffff;
					border: 0.143646px solid #c2c2c2;
					min-width: 226px;
					height: 40px;

					&:hover {
						color: black;
					}
				}
			}
		}

		.clip-options {
			.tracker-value {
				display: flex;
				justify-content: end;
			}

			.clip-option {
				font-weight: 600;
				margin-right: 8px;
			}

			.clip-date {
				margin-left: 8px;
			}
		}
	}

	.compare-button {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;

		color: #646464;
		background: #ffffff;
		border: 0.8px solid #676767;
		border-radius: 4px;
		padding: 15px 41px;

		&:hover {
			text-decoration: none;
		}
	}
}
