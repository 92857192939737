.lozenge {
  border-radius: 10px;
  padding: 4px 10px 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;

  &.default {
    background-color: $color-gray-2;
  }

  &.success {
    background-color: $brand-green;
  }

  &.error {
    background-color: $brand-red;
  }

  &.warning {
    background-color: $brand-yellow;
  }

  &.in-progress {
    opacity: 0.7;
    background-color: $brand-secondary;
  }

  > .lozenge-text {
    margin: 0;
    color: $color-white;
    font-size: 13px;

    &.default {
      color: $brand-primary;
      opacity: 0.45;
    }

    &.success {
      color: $color-white;
    }

    &.error {
      color: $color-white;
    }

    &.warning {
      color: $color-white;
    }

    &.in-progress {
      color: $color-white;
    }
  }
}