.skeleton-shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 75% 100%; 
  display: inline-block;
  position: relative;
  animation-duration: 1.5s;
  animation-name: placeholderShimmer;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.skeleton-fullwidth-box {
  display: block !important;
  height: 80px;
  border-radius: 8px;
}

.skeleton-small-box {
  height: 84px;
  width: 80px;
  margin-right: 10px;
  border-radius: 8px;
}

.skeleton-three-lines {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.skeleton-horizontal-line {
  display: inline-flex !important;
  height: 7px;
  margin: 2px 10px 8px 0;
  border-radius: 2px;
}

.skeleton-vertical-line {
  display: inline-flex !important;
  width: 5px;
  margin: 0 5px 0 5px;
  border-radius: 2px;
}

.skeleton-headline {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 0 10px 0;
}

.skeleton-circle {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

@keyframes placeholderShimmer {
  from {
    // transform: translateX(-100%);
    background-position: -200% 0;
  }

  to {
    // transform: translateX(100%);
    background-position: 300% 0; 
  }
}

.stacked-area-load-state--css-draw .chart-line {
  stroke: #EFF2F5;
  stroke-width: 4; 
  fill: none;
  stroke-dasharray: 1066.1628910290706;
  stroke-dashoffset: 1066.1628910290706;
  animation: drawStroke 1.5s linear infinite forwards;
}

@keyframes drawStroke {
  0% {
    opacity: 0;
    stroke: #C3C6CF;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}