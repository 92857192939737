.metric-empty {
  height: 100%;
  min-height: 8em;
  box-shadow: none!important;
  
  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .column-title {
    text-transform: uppercase;
    color: $color-gray-6;
  }
}