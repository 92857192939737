.theme-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1.875rem 2.5rem 2.25rem;

	.theme-nav {
		margin-bottom: 2rem;

		a {
			display: inline-block;
			padding: 0;
		}

		h2 {
			display: flex;
			align-items: center;
			width: max-content;
			margin: 0;
			text-transform: capitalize;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.arrow-left {
			margin-right: 0.5rem;
			font-size: 1rem;
		}
	}

	.theme-body {
		display: flex;
		column-gap: 2.5rem;
		height: 100%;

		.theme-loading-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		.theme-sidebar {
			flex-shrink: 0;
			width: 300px;
			padding: 1.25rem;
			background: $color-white;
			border: 1px solid $color-gray-10;
			border-radius: 4px;
		}

		.theme-content {
			display: flex;
			flex-direction: column;
			width: 100%;
			position: relative;

			.theme-content-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				margin-bottom: 2.5rem;

				.casted-toggle-button-group {
					width: auto;
				}
			}

			.theme-content-actions {
				display: flex;
				position: absolute;
				top: 0;
				right: 0;

				.theme-active-filter {
					margin-right: 1rem;
					padding: 0.4375rem 0.75rem;
					border-radius: 4px;
					background: $color-gray-13;
					cursor: pointer;

					span {
						margin-right: 0.625rem;
						text-transform: capitalize;
						font-size: 0.875rem;
						font-weight: 600;
						line-height: 0.75rem;
					}
				}

				.theme-content-filter {
					p {
						margin: 0.375rem 0;
						padding: 0 0.75rem;

						&:first-child {
							margin-top: 0;
						}
					}

					a {
						text-transform: capitalize;
					}
				}
			}

			.theme-content-body {
				display: flex;
				flex-direction: column;
				row-gap: 0.5rem;
				margin-top: 1.5rem;
			}

			.theme-content-empty {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				height: 100%;

				.podcat {
					width: 400px;
				}

				p {
					margin-top: 1.5rem;
					max-width: 450px;
					font-size: 1.5rem;
					font-weight: 600;
					line-height: 2.0625rem;
					text-align: center;
				}
			}
		}
	}
}
