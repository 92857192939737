.segments-table-container {
  padding-bottom: 30px;

  .segments-theme-badge {
		padding: 0.375rem 0.625rem;
		color: $color-white;
		font-size: 0.8125rem;
		box-sizing: border-box;
		border-radius: 0;

		&.no-color {
			color: $brand-font-color;
			border: 1px solid $brand-font-color;
		}
	}

  .empty-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    background-color: $color-gray-2;

    .podcat {
      height: 150px;
    }

    .segments-empty-state {
      text-align: left;
      padding: 40px 0;
      font-weight: 600;
      line-height: 1.75;
      color: $color-gray-6;
      margin-left: 30px;
  
      .bold {
        color: $color-gray-9;
      }
    }
  }

  .segments-table-filters {
    display: flex;
		column-gap: 1.5rem;
		margin-bottom: 1.125rem;

    .casted-dropdown-button {
      .dropdown-toggle {
        width: 100%;
        padding: 0px 15px;

        .segment-value {
          display: flex;
          justify-content: space-between;
        }    
      }

      .segment-menu {
        .header {
          padding-top: 8px;
        }

        .list {
          padding-bottom: 8px;
          font-size: 12px;
        }
      }
    }

    .empty-value {
      display: flex;
      align-items: center;
      color: $color-gray-6;

      .plus-icon {
        font-size: 10px;
        margin-right: 8px;
      }
    }
  }
}