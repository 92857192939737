.studio--transcript {
	display: flex;
	flex-direction: column;
	min-height: 0;
	overflow: hidden;
	height: 100%;

	.DraftEditor-root {
		position: relative;
	}

	& > .row {
		&:first-child {
			& > div {
				border: 1px solid $color-gray-4;
				border-bottom: none;

				&.sidebar-hidden {
					border-right: none;
				}

				&.in-transcript {
					border-left: none;
				}
			}
		}

		&:last-child {
			& > div {
				border: 1px solid $color-gray-4;
				border-top: none;

				&.sidebar-hidden {
					border-right: none;
				}

				&.in-transcript {
					border-left: none;
				}
			}
		}

		& > div {
			background: $color-white;
		}
	}
}

.transcript-actions {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5em 2em 0.5em 1em;

	.toggle-publish-transcript {
		margin-left: 1.75rem;
		font-size: 12px;
		line-height: 24px;

		input,
		label {
			cursor: pointer;
		}

		label {
			text-transform: capitalize;
		}
	}

	.show-highlights {
		font-weight: 500;
		font-size: 16px;
		margin: 0.5em 0;

		svg {
			padding-bottom: 3px;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.transcript-container {
	display: flex;
	flex: 1;
	min-height: 0;
	overflow-y: scroll;
	overflow-x: hidden;
	font-size: 14px;
	color: $color-gray-7;
	line-height: 23px;
	letter-spacing: 0;

	&::-webkit-scrollbar {
		@extend %scrollbar-base;
	}

	&::-webkit-scrollbar-thumb {
		@extend %scrollbar-thumb;
	}

	&::-webkit-scrollbar-track {
		@extend %scrollbar-track;
	}
	// This does literally nothing apparently
	&.prevent-select * {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}

// Text Selection
.studio--transcript .DraftEditor-editorContainer {
	.public-DraftStyleDefault-block span {
		outline: none;
		line-height: 1.6rem;
		color: $brand-primary;
		&.select-layer.selected,
		&::selection {
			background-color: $brand-light-purple;
			color: $brand-purple;
		}
		&.select-layer.highlighted {
			background-color: $brand-light-purple;
			color: $brand-purple;
			transition: 0.2s all ease;
			&.faded {
				background-color: $color-gray-4;
			}
		}
		&.select-layer.linked-selected {
			background-color: rgba($brand-green, 0.15);
		}
		&.select-layer.suggested {
			background-color: rgba($ai-blue, .3);
		}
	}

	// handles
	span.select-handle {
		display: inline-block;
		width: 4px;
		height: 24px;
		margin-bottom: -7px;
		border-radius: 3px;
		background-color: $brand-purple !important;
		cursor: col-resize !important;

		&.select-handle--start {
			margin-left: 0;
			margin-right: 2px;
		}
		&.select-handle--end {
			margin-left: 2px;
			margin-right: 0;
		}
	}
}

.select-clear {
	position: absolute;
	left: 0rem;
	width: 30px;
	height: 30px;
	cursor: pointer;
	&:after {
		content: '\e436';
		font-family: 'uifont';
		display: block;
		vertical-align: top;
		text-align: center;
		line-height: 25px;
		font-size: 1rem;
		color: black;
	}
}

// Transcript Editor Container
.studio--transcript .DraftEditor-editorContainer .public-DraftEditor-content {
	border: 1px solid transparent;
	max-height: none;
	background-color: none;
	caret-color: transparent;
}

.studio--transcript.is-editing {
	&::selection {
		background-color: lightblue;
		color: inherit;
	}

	.DraftEditor-editorContainer .public-DraftEditor-content {
		border: 2px dashed rgba($brand-green, 0.4);
		caret-color: $brand-green;

		.public-DraftStyleDefault-block span {
			cursor: text;

			&::selection {
				background-color: lightblue;
				color: default;
			}
		}
	}
}

.transcript-container.no-speakers div[data-block='true'] > div {
	display: block;
	padding: 0 1em;
}

.show-transcript-modal {
	.modal-dialog {
		max-width: 800px !important;
	}
}

.hidden {
	display: none !important;
}
