.share-content-options {
	.share-content-button {
		font-size: 12px;
		font-weight: 700;
		line-height: 18px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		padding: 11px 12px;
		line-height: 150%;

		&.btn {
			color: #72a8d7 !important;
			border-color: #72a8d7 !important;
		}

		&.active,
		&:hover {
			background-color: #01315f !important;
			border-color: #01315f !important;
			color: white !important;
		}
	}

	.share-audio-option {
		padding: 0.75rem;
		border-radius: 3px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		margin-top: 1em;
		border: 1px solid $color-gray-11;
		width: fit-content;
	}
}
