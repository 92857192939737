.toggle-edit--action {
  padding: 0;
  margin-left: .5em;

  .icon-circle {
    transition: .4s all ease-in-out;
    width: 30px;
    height: 30px;
    svg {
      transition: .3s all ease-in-out;
      width: 12px;
      height: 12px;
    }
  }
  
  &:hover {
    .icon-circle {
      border: 2px solid;
      padding: 5px;
      svg {
        transform-origin: center;
        width: 13px;
        height: 13px;
      }
    }
  }
  
  &.toggle-edit--cancel {
    .icon-circle {
      border-color: $brand-red;
      color: $brand-red;
    }
  }
  
  &.toggle-edit--confirm {
    .icon-circle {
      border-color: $brand-green;
      color: $brand-green;
    }
  }
}

.toggle-edit--tooltip {
  .tooltip-inner {
    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}