$progress-bar-height: 20px;

.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 700px;

  .file-container {
    display: flex;
    height: 120px;
    width: 100%;
    max-width: 700px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 0;
    box-sizing: border-box;
    border: 1px dashed $color-gray-2;
    border-radius: 3px;
    object-fit: cover;    

    input.file-upload-input {
      display: none;
    }

    &:focus {
      outline: none;
    }

    .file-edit-overlay {
      position: absolute;
      top: 6px;
      right: 6px;
      bottom: 6px;
      left: 6px;
      align-items: center;
      display: flex;
      justify-content: center;
      z-index: 1;
      cursor: pointer;
      font-size: 11px;
      line-height: 17px;
      text-align: center;
      font-weight: 700;
      color: rgba(0,0,0,0.6);
      text-transform: uppercase;
      border-radius: 3px;
      background-color: $brand-background;

      .file-browse-txt {
        color: $brand-blue;
        cursor: pointer;
        font-size: 12px;
        margin-left: 3px;
      }

      .upload-progress {
        flex: 1;
        background: $color-white;
        padding: 3px;
        box-sizing: border-box;
        height: $progress-bar-height;
        border-radius: 4px;
        margin: 0 30px;

        .bar {
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.7s ease-out;
          border-radius: 4px;
          background: $brand-purple;
          width: 0;
          height: $progress-bar-height - 6;
          color: white;
        }
      }
    }
  }
  
  &.upload-complete {
    .file-container {
      border-color: transparent;
    }
    .file-edit-overlay {
      color: $brand-purple;
    }
  }
}