.ql-toolbar {
	border-radius: 0.25rem 0.25rem 0 0;
}

.ql-container {
	border-radius: 0 0 0.25rem 0.25rem;
	p.ql-align-justify{
	  margin-top: 1em;
	  margin-bottom: 1em;
	}
	p.ql-align-justify:first-of-type{
		margin-top: 0;
		margin-bottom: 1em;
	  }
}

.meta-quill {
	.ql-container {
		border-radius: 0.25rem;
	}
}
