#youtube-form {
	.youtube-header {
		padding: 30px 0px 20px 30px;
		border-bottom: 1px solid $color-gray-4;
		margin: 0px;
	}
	.youtube-form-body {
		max-height: 450px;
		overflow: auto;

		&.small-body {
			max-height: 300px;
		}

		.youtube-input {
			padding: 0px 30px 30px 30px;

			&.title {
				padding-top: 15px;
			}

			.youtube-radio {
				padding-top: 20px;

				.radio-option {
					font-weight: 600;

					.radio-description {
						font-size: 14px;
						font-weight: normal;
					}
				}
			}

			.youtube-form-thumbnail {
				display: flex;
				padding-top: 16px;

				.thumbnail-image {
					width: 162px;
					height: 162px;
					object-fit: cover;
				}
			}

			input[type='file'] {
				display: none;
			}

			.thumbnail-upload {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border: 1px dashed $color-gray-3;
				width: 200px;
				height: 162px;
				border-radius: 9px;
				font-weight: 600;
				cursor: pointer;
			}
		}
	}

	.youtube-footer {
		&.modal-footer {
			margin: 0px;
		}
		.youtube-upload-button {
			width: 100%;
			display: flex;
			justify-content: center;
			padding: 30px 0px;
			border-top: 1px solid $color-gray-4;
			margin: 0px;

			&.small-footer {
				padding: 30px 30px 0px 0px;
				justify-content: flex-end;
			}
		}
	}
}
