.episode-tips {
  margin-top: 2em;
  
  .tips--title-icon {
    margin-top: -7px;
    margin-right: 10px; 
    color: $brand-dark-purple;
  }
  
  .list-group {
    .list-group-item {
      margin: 0;
      padding: 0;
      
      a, .btn-link {
        color: $brand-dark-purple;
      }
    }
  }
  
  .tip-icon--done {
    color: $brand-green;
  }
  .tip-icon--not-done {
    color: $color-gray-3;
  }
  
}