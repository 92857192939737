.content-page {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1.875rem 2.5rem;

	.content-page-header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.5rem;

		.content-page-header {
			margin: 0;
		}

		.content-page-button {
			display: flex;
			padding: 1.25rem 1.5rem;
			border: 1px solid $color-gray-4;
			background-color: $color-white;
			border-radius: 53px;
			align-items: center;
			justify-content: space-around;
			font-size: 1.25rem;

			.plus-icon {
				margin-right: 0.5rem;
				color: $brand-purple;
			}

			.content-page-button-text {
				font-weight: 600;
			}
		}
	}

	.content-page-body {
		.content-page-loading-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		.content-page-search {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 2em;
			box-sizing: border-box;
			font-size: 1em;
			border: 0.05em solid #c6d0d3;
			border-radius: 0.2em;
			outline: none;
			min-height: 2.625rem;
			padding-left: 2em;
			background-color: white;

			input {
				font-size: 1em;
				border: none;
				outline: none;
				width: 99%;
				padding: 0px 0.3em;
			}
		}
	}
}
