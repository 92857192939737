.sidebar .show-nav {
	margin-top: 0;
	.list-group-item {
		position: relative;
		padding: 0.5em 1em 0.5em 1.5em;
		font-size: 1rem;
		border: none;
		border-left: 6px solid transparent;
		svg {
			font-size: 0.85rem;
			margin-top: -2px;
			margin-right: 10px;
			color: $color-gray-7;
		}
		a {
			font-weight: 400;
			color: $color-gray-7;
			&:hover {
				text-decoration: none;
			}
		}

		.hover-icon {
			display: none;
			position: absolute;
			top: 50%;
			transform: translateY(-25%);
			right: 1px;

			color: $color-gray-6;
		}
		&:hover {
			.hover-icon {
				display: inline-block;
			}
		}

		&:hover,
		&.show-nav--active {
			background: $color-gray-2;
		}
		&.show-nav--active {
			border-left-color: $brand-purple;
		}
	}
}
