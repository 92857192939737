.metric-date-range {
  margin-top: 2em;
  align-items: center;
  .date-range--dates {
    color: $color-gray-7;
  }
}
.metric-date-range-trigger {
  min-width: 175px;
  svg {
    margin-left: 5px;
    margin-top: -5px;
  }
}

.metric-date-range-trigger {
  display: block;
  width: auto;
  margin-left: auto;
  border: 1px solid $color-gray-4;
  background: $color-white !important;
}



// Overwrite library styles
.metric-date-range-popover {
  max-width: 100%;
  .popover-body {
    padding: 1rem .75rem;
  }
  .rdrInputRanges {
    padding: 0;
  }
  .rdrStaticRanges .rdrStaticRange:last-child{
    border-bottom: none;  
  }
  .rdrMonth {
    padding-bottom: 0;
  }
  
  .rdrStaticRangeSelected {
    color: $brand-purple !important;
  }
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: $brand-purple !important;
  }
  .rdrDayInPreview {
    border-color: $brand-purple !important;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: $color-gray-6;
  }
  .rdrDateDisplayItem {
    border-color: $color-gray-3 !important;
    box-shadow: none !important;
  }
}