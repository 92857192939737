.syncing-episodes {
  position: relative;
  padding: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  
  
  .progress {
    width: 100%;
    margin-top: 3em;
  }
  
  .success-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: $brand-purple;
    background: $brand-light-purple;
    svg {
      font-size: 1.5rem;
    }
  }
  
  h3 {
    margin-bottom: 1em;
  }
  
  p {
    max-width: 400px;
  }

  .close-btn {
    position: absolute;
    right: 30px;
    top: 25px;
    height: 14px;
    width: 14px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
}