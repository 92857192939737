.button {
	// margin: 8px;
	cursor: pointer;
	outline: none;
	font-size: 11px;
	height: 50px;
	border: none;
	border-radius: 3px;
	box-shadow: $box-1;
	transition: all 0.25s ease;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.84px;
}

.button-contained {
	background-color: $brand-green;
	color: $color-white;

	&:hover {
		background-color: darken($brand-green, 5%);
	}
}

.button-outlined {
	background-color: transparent;
	color: $brand-primary;
	border: 1px solid $brand-primary;
	outline: none;
	box-shadow: none;
	transition: background-color 0.1s ease color 0.15s ease;

	&:hover {
		background-color: $brand-primary;
		color: $color-white;
	}

	&.disabled {
		background-color: transparent;
		color: $brand-primary;
	}
}

.button-outlined-light {
	background-color: transparent;
	color: $color-white;
	border: 1px solid $color-white;
	outline: none;
	box-shadow: none;
	transition: background-color 0.1s ease color 0.15s ease;

	&:hover {
		background-color: $color-white;
		color: $brand-primary !important;
	}

	&.disabled {
		background-color: transparent;
		color: $color-white;
	}
}

.button-flat {
	border: none;
	background-color: transparent;
	box-shadow: none;
	color: $brand-primary;

	&:hover,
	&:active,
	&:link,
	&:focus,
	&:visited {
		background-color: transparent !important;
		color: $brand-primary !important;
	}
}

.button-icon {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	padding: 0;
	background-color: transparent;
	box-shadow: none;
	margin: 0;
}

.disabled {
	opacity: 0.55;
	cursor: auto;
}

.button-link {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	box-shadow: none;

	&:hover {
		text-decoration: none;
	}

	&:focus {
		outline: none;
	}
}

.button-loading {
	width: 34px !important;
	height: 34px !important;
	border-radius: 50% !important;
	background-color: $color-white !important;
	border: 3px solid #cccccc;
	border-left: 3px solid $brand-green;
	animation: rotate-loader 1s 0.25s linear infinite;
	margin: 0 !important;
	padding: 0 !important;
	cursor: default !important;

	&:after {
		content: '';
	}
}

@keyframes rotate-loader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.casted-button {
	min-width: 5em;
	min-height: 2.375rem;
	font-size: 0.75rem;
	font-weight: 800;
	letter-spacing: 0.075rem;
	line-height: 1.1rem;
	text-align: center;
	text-transform: uppercase;

	&.btn-cancel {
		color: #343a40;
	}
}
