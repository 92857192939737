.show-admin--container {
	position: relative;
}

.show-admin--header {
	position: sticky;
	top: 0;
	z-index: 99;
	padding: 1.5em 0;
	margin-bottom: 2em;
	background: $brand-background-blue;

	.show-nav--title {
		margin: 0;
		color: $color-white;
		text-transform: uppercase;
		font-weight: 600;

		a {
			text-decoration: none;
		}

		&.active {
			border-bottom-color: $brand-purple;
		}
	}

	.nav-link {
		padding: 0.5em 0;
		margin: 0 1em;

		p {
			opacity: 0.5;
		}

		&:hover {
			p {
				opacity: 0.9;
			}
		}

		&:focus {
			outline: none;
		}

		&.active {
			border-bottom: 2px solid $color-white;

			p {
				opacity: 1;
			}
		}
	}
}

.collection-admin-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
