.mock-browser {
	border: 5px solid rgba(0, 0, 0, 0.05);
	border-radius: 10px;

	.mock-browser--top-bar {
		background: rgba(0, 0, 0, 0.05);
		padding: 0.5em 1em;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;

		.mock-browser--button {
			display: inline-block;
			border-radius: 50%;
			width: 10px;
			height: 10px;
			margin-right: 5px;
			background: rgba(0, 0, 0, 0.2);
		}

		.mock-browser--label {
			color: rgba(0, 0, 0, 0.3);
			margin-left: 0.5em;
			font-weight: bold;
		}

		.mock-browser--favicon {
			width: 16px;
			height: 16px;
			margin-left: 1em;
		}
	}
}

.mock-player {
	margin: 1em 1.5em;
	padding: 0.5em;
	background: $color-white;
	border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 4px;

	.mock-player--thumbnail {
		width: 75px;
		height: 75px;
		border-radius: 4px;
		background: rgba(0, 0, 0, 0.05);

		img {
			height: 100%;
			width: 100%;
			aspect-ratio: 16/9;
		}
	}

	.mock-player--title {
		max-width: 200px;
		height: 1.5em;
		margin: 1em 0 1em;
		border-radius: 4px;
		background: rgba(0, 0, 0, 0.05);
		padding: 0 0 1em 1em;
		text-align: start;
		size: 0.5em;
		font-weight: 400;
	}

	.mock-player--audio {
		> .row {
			justify-content: flex-start;
			align-items: center;
		}

		svg {
			color: rgba(0, 0, 0, 0.2);
			margin-right: 5px;
		}
		.mock-player--bar {
			position: relative;
			width: 100%;
			height: 0.75em;
			margin-left: -1em;
			border-radius: 20px;
			background-color: $color-gray-2;
			border: 1px solid $color-gray-3;
		}
		.mock-player--progress {
			position: absolute;
			top: 0;
			left: 0;
			width: 25%;
			height: 100%;
			border-radius: 20px;
			background: rgba(0, 0, 0, 0.05);
			z-index: 1;
			.mock-player--playhead {
				position: absolute;
				right: -1px;
				top: -2px;
				width: 15px;
				height: 15px;
				border-radius: 100%;
				border: 3px solid rgba(0, 0, 0, 0.05);
				background: $color-white;
			}
		}
		.mock-player--takeaway {
			position: absolute;
			left: 50%;
			top: -0.6em;
			width: 4em;
			height: 2em;
			border: 2px solid rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			background: rgba(0, 0, 0, 0.05);
		}
	}
	.mock-player--body {
		max-width: 400px;
		margin: 2em 0 1em;
		border-radius: 4px;
		background: rgba(0, 0, 0, 0.05);
		padding: 0 0 1em 1em;
		text-align: start;
		font-weight: 100;
	}
}

.mock-footer {
	height: 2em;
	background-color: $color-white;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.mock-navbar {
	padding: 1em;
	margin-bottom: 3em;
	background: $color-white;

	> .row {
		justify-content: space-between;
		align-items: center;
	}

	.mock-navbar--nav-item {
		display: inline-block;
		width: 4em;
		height: 1em;
		background: rgba(0, 0, 0, 0.05);
		margin-right: 0.5em;
	}
}
