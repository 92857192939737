.playlist-list-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 80px;
	max-height: 550px;
	position: relative;
	padding: 24px 0px;
	margin-bottom: 2.5rem;
	background-color: white;
	border: 1px solid $color-gray-4;
	border-radius: 6px;
	border-top:  none;
	overflow: scroll;
}
