.modal-dialog.modal-wide {
	max-width: 60%;

	.modal-content {
		border-radius: 0.5em;
	}
}

.user-modal {
	padding: 1em 2em;

	.profile-thumbnail {
		flex-direction: column;
		align-items: center;

		.user-avatar-upload {
			margin-right: 0;

			.thumbnail-container,
			.thumbnail-image {
				border-radius: 999px;
			}
		}
	}

	label {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;
	}

	.submit-user {
		font-size: 14px;
		font-weight: 800;
		line-height: 19px;
		width: 25%;
	}

	.remove-user-buttons button {
		margin: 0px 10px;
		@extend .submit-user;
	}

	.role-container {
		.user-role-select {
			.dropdown-toggle {
				width: 100%;
				text-align: left;
				border-color: $color-gray-6;
				&:after {
					float: right;
					margin-top: 0.55em;
				}
				&:hover,
				&:focus,
				&:active,
				&.active {
					background-color: transparent;
					color: inherit;
					box-shadow: none;
				}
			}

			.dropdown-item {
				padding: 0.5rem 1.5rem;
				p {
					margin-bottom: 0;
				}
				.role--name {
					font-weight: bold;
				}
			}
		}
	}
}

.user-permissions-empty {
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 40px 0px;
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}
