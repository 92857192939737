.show-details {
  display: flex;
  flex-direction: column;

  .show-details-sticky-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 25px;
    height: 50px;
    background-color: $brand-primary;
    z-index: 10;
    box-sizing: border-box;

    .show-details-thumbnail {
      height: 35px;
      width: 35px;
      margin-right: 15px;
      object-fit: cover;
      border: 2px solid $color-white;
      box-sizing: border-box;
    }

    .show-details-name {
      font-size: 15px;
      color: $color-white;
    }
  }
}

.show-add-episode-modal {
  .modal-dialog {
    max-width: 800px !important;
  }
}