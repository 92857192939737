.company-profile-container {
	.profile-text {
		color: $color-gray-9;
	}

	.company-logo {
		max-height: 80px;
	}

	.metric-date-range {
		margin-top: 0;
	}

	.table-logo {
		max-height: 3em;
		margin-right: 1em;
	}

	.content-mix-and-theme-container {
		margin-left: unset;
		margin-right: unset;

		.content-mix-container {
			flex: 1;
			margin-right: 3%;
			max-width: 220px;

			.card {
				min-width: 220px;
				max-height: 350px;
			}

			.content-mix--loading {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
			}
		}

		.theme-aggregate-container {
			flex: 4 1 100px;

			.rdt_TableHeadRow {
				font-size: 10px;
			}

			.insights-theme-badge {
				padding: 0.375rem 0.625rem;
				color: $color-white;
				font-size: 0.8125rem;
				box-sizing: border-box;
				border-radius: 1.5px;

				&.no-color {
					color: $brand-font-color;
					border: 1px solid $brand-font-color;
				}
			}
		}
	}
}
