.casted-pagination {
	display: flex;
	padding: 1rem 0 1.125rem;

	.pagination-list {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		justify-content: center;
		width: 100%;

		.pagination-item {
			background: #e4e8ed;
			margin-right: 0.3125rem;
			padding: 0.125rem 0.375rem;
			border-radius: 0.125rem;
			font-size: 0.875rem;
			letter-spacing: 0;
			line-height: 1.1875rem;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			&.pagination-active {
				background: #7769e9;
				color: #fff;
			}

			&.pagination-disabled {
				background: none;
				color: #526173;
				cursor: default;
			}
		}
	}
}
