.publish-episode-modal {
	padding: 30px 80px;
	display: flex;
	flex-direction: column;

	.pem-header-row {
		display: flex;
		flex-direction: row;
		justify-content: center;

		.pem-header-title {
			font-size: 30px;
			margin: 0;
			font-weight: 700;
		}

		.pem-close-btn {
			position: absolute;
			right: 30px;
			top: 25px;
			height: 14px;
			width: 14px;
			margin: 0;
			padding: 0;

			.pem-close-icon {
				height: 13px;
				width: auto;
			}
		}
	}

	.pem-schedule-container {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: space-between;
		padding: 50px 50px 20px 50px;
		margin-bottom: 2em;
		border-radius: 3px;
		min-height: 76px;
		box-sizing: border-box;

		input,
		select {
			font-size: 13px;
			color: #666;
		}

		.pem-schedule-episode-text {
			flex: 1;
			border: none;
			outline: none;
			font-size: 13px;
			font-weight: 700;
			margin-right: 15px;
			background-color: transparent;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 0.5em 0;
		}

		.pem-schedule-episode-label {
			font-size: 13px;
			padding: 0 1em;
		}

		.pem-schedule-episode-date {
			padding-bottom: 2.5em;
		}

		.pem-schedule-episode-time {
			padding-bottom: 2.5em;
		}

		.pem-schedule-episode-submit {
			padding: 2em 0 0 0;
			width: 100%;
			justify-content: center;
			display: flex;
			flex-direction: row;
		}

		.pem-schedule-btn {
			margin: 1em auto !important;
			height: 39px;
			text-transform: uppercase;
			padding: 2px 15px;
			display: flex;
			align-items: center;
		}
	}

	.youtube-visibility-input {
		padding-top: 40px;

		.label {
			padding-bottom: 12px;
			display: flex;
			justify-content: center;
		}

		.youtube-visibility-radio {
			margin-left: 20px;
			padding-bottom: 12px;
		}
	}
}

.react-date-picker__inputGroup__month,
.react-date-picker__inputGroup__year,
.react-date-picker__inputGroup__day {
	pointer-events: none;
}

.react-date-picker__calendar .react-calendar {
	border-color: #ced4db;
	border-radius: 6px;
	margin-top: 0.5em;
}
.react-date-picker__wrapper {
	border: none;
}
.react-calendar {
	.react-calendar__month-view__days__day--weekend {
		color: $color-gray-7;
	}
	.react-calendar__tile--active {
		background: $brand-purple;
		color: $color-white;
		&:hover {
			background: $brand-purple;
		}
	}
}

.rc-time-picker.form-control {
	width: auto;
	display: inline-block;
	margin-right: 1em;
}
.rc-time-picker-input {
	padding: 0;
	height: auto;
	width: 7em;
	font-size: 1rem;
	color: $color-gray-7;
	border: none;
}

.rc-time-picker-panel {
	z-index: 100000;
}
