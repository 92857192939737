.imported-items-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1.875rem 2.5rem;

	.imported-items-nav {
		margin-bottom: 2rem;

		h2 {
			display: flex;
			align-items: center;
			width: max-content;
			margin: 0;
			text-transform: capitalize;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.arrow-left {
			margin-right: 0.5rem;
			font-size: 1.125rem;
		}
	}

	.imported-content-loading-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.imported-items-search {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 3.25em;
		box-sizing: border-box;
		font-size: 1em;
		border: 0.05em solid #c6d0d3;
		border-radius: 0.2em;
		outline: none;
		min-height: 2.625rem;
		padding-left: 2em;
		background-color: white;

		input {
			font-size: 1em;
			border: none;
			outline: none;
			width: 99%;
			padding: 0px 0.3em;
		}
	}

	.imported-items-content {
		display: flex;
		column-gap: 2.125rem;

		.content-sidebar {
			max-width: 159px;

			.content-info {
				margin-bottom: 0.625rem;
				background: $color-white;
				border: 1px solid $color-gray-10;
				box-sizing: border-box;
				border-radius: 8px;
				overflow: hidden;

				.content-name {
					padding: 0.625rem;
					text-align: center;
					font-size: 1rem;
					font-weight: 700;
					line-height: 21.79px;
				}

				.content-thumbnail {
					border-bottom: 1px solid $color-gray-10;

					img {
						width: 100%;
					}
				}
			}

			.remove-content {
				color: $brand-red;
				text-align: center;
				font-size: 1rem;
				font-weight: 500;
				line-height: 18.75px;
				cursor: pointer;
			}
		}

		.imported-content-body {
			display: flex;
			flex-direction: column;
			width: 100%;

			.content-rows {
				width: 100%;

				.imported-content-row {
					.content-actions {
						display: flex;
						align-items: center;
						text-align: right;
						padding-right: 0.5em;

						.dropdown-toggle {
							display: inline-block;
							border-radius: 100%;
							width: 30px;
							height: 30px;
							text-align: center;
							align-items: center;
							border: 1px solid $color-gray-7;
							color: $color-gray-7;
							cursor: pointer;
							transition: all 0.1s ease-in-out;
							svg {
								margin-top: 1px;
							}
							&:hover {
								background: $color-gray-7;
								color: $color-white;
							}
							&:after {
								content: none;
								display: none;
							}
						}

						.dropdown-item {
							display: flex;
							align-items: center;
							font-size: 0.75rem;
							font-weight: 600;
							line-height: 16.34px;

							.icon {
								margin-left: 0.25rem;
							}
						}
					}
				}
			}

			.imported-content-empty {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				height: 100%;

				.podcat {
					width: 400px;
				}

				p {
					margin-top: 1.5rem;
					max-width: 450px;
					font-size: 1.5rem;
					font-weight: 600;
					line-height: 2.0625rem;
					text-align: center;
				}
			}
		}
	}
}
