.engaged-companies-container {
	padding-top: 50px;

	.header {
		display: flex;
		justify-content: space-between;
	}

	.empty-container {
		display: flex;
		width: 100%;
		background: #f6f9fa;
		align-items: center;

		.podcat {
			height: 119px;
			margin: 40px 0;
			margin-left: 30px;
		}

		.empty-state {
			padding: 40px 30px;
			color: #646464;

			.bold {
				font-weight: 600;
			}

			.bold-header {
				font-size: 18px;
				font-weight: 600;
				padding-bottom: 12px;
			}
		}
	}

	.engaged-company-name {
		display: flex;
		width: 100%;
		justify-content: space-between;
		padding-right: 20px;
	}

	.engaged-company-tracker {
		padding: 30px 0px;
		width: 100%;
	}

	.engaged-companies-search {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;
		border: 0.05em solid #c6d0d3;
		border-radius: 0.2em;
		min-height: 40px;
		padding-left: 10px;
		background-color: white;
		width: 25%;

		input {
			font-size: 16px;
			border: none;
			outline: none;
			width: 99%;
			padding: 0px 8px;
		}
	}
}
