.clip-list-column {
	transition: 0.4s all ease;

	&.in-transcript {
		position: relative;
		right: 0;

		&.hidden {
			right: 100%;
		}

		&.visible {
			right: 0;
			padding-top: 0;

			.create-clip-card {
				background-color: $color-white;
				margin: 0 0 0 2px;
				border: 1px solid $color-gray-3;
			}

			.editing,
			.create-clip-card {
				width: 95%;
			}
		}
	}

	&.in-sidebar {
		overflow-y: auto;
		position: absolute;
		min-height: 100px;
		width: 100%;
		padding: 0;
		border-bottom: 1px solid $color-gray-3;
		right: -100%;

		h4 {
			padding-top: 1em;
			padding-left: 1rem;
			font-weight: 400;
			font-size: 20px;
		}

		&.clip-container {
			height: calc(100% - 121px);
		}

		&.visible {
			right: 3rem;

			& > div {
				width: calc(100% - 12px);
			}
		}
	}

	&.hide {
		background: none !important;
		visibility: hidden;
		display: none;
	}

	.drawer-header {
		text-transform: capitalize;
	}
}

.clip-list--no-clips {
	color: $color-gray-6;
	text-align: center;
	line-height: 2em;
	padding: 2em;
	border-radius: 0;

	p {
		margin-bottom: 0;
	}
}

.clip-card {
	opacity: 0.7;
	transition: 0.4s all ease;
	border: 1px solid transparent;
	border-radius: 2px;
	// margin: 2px;
	width: 85%;
	background-color: $color-white;

	.ticker--time {
		width: 46%;
	}

	&.suggested-clip {
		color: $ai-blue;
	}

	&.active {
		opacity: 1;

		.processing {
			opacity: 0.5;
		}

		&:not(.sidebar-visible) {
			border: 1px solid $color-gray-4;
		}

		&.sidebar-visible {
			background-color: transparent;
			border-bottom: 1px solid $color-gray-4;
			width: 100%;
			padding: 0.5em 1em;
			transition: none;
		}

		.clip--header button {
			display: block;
			padding: 0 !important;
		}

		&:not(.suggested-clip) .clip--name {
			border-bottom: 1px solid $color-gray-2;
		}

		&.suggested-clip {
			color: $brand-font-color;
		}

		.clip--links {
			.clip-link {
				display: block;
			}
		}

		.clip--suggestion-reason {
			display: block;
			border-bottom: 1px solid $color-gray-2;
		}
	}

	&.selected {
		&:not(.sidebar-visible) {
			border: 1px solid $color-gray-8;
		}
	}

	.sidebar-visible {
		&.editing {
			padding: 0;
		}
	}

	.clip-processing {
		position: absolute;
		z-index: 1;
		padding-left: 40%;
		padding-top: 10%;

		.label {
			margin-left: -25%;
			padding-top: 5%;
			font-size: 1rem;
			line-height: 1.25rem;
		}
	}

	.clip-dot {
		svg {
			font-size: 10px;
		}

		span {
			display: none;
		}

		&.link {
			svg {
				color: $brand-green;
			}
		}
		&.takeaway {
			svg {
				color: $brand-purple;
			}
		}
		&.processing {
			svg {
				color: $brand-orange;
			}
		}
		&.error {
			svg {
				color: $brand-red;
			}
		}
		&.suggested {
			svg {
				color: $ai-blue;
			}
		}
	}

	.clip--header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		button {
			font-weight: 100;
			display: none;
			&:after {
				content: '';
				display: none;
			}
		}

		.clip-has-audiogram {
			margin-left: 1em;
			background: $color-gray-4;
			color: darken($color-gray-4, 35%);
			.audiogram-done {
				cursor: pointer;
			}
		}

		.clip--duration {
			margin: 0 0 0 1em;
			font-weight: 600;
			font-size: 12px;
		}
	}

	.clip--duration {
		font-weight: 600;
		font-size: 12px;
	}

	.clip--suggestion-reason,
	.clip--links .clip-link {
		display: none;
	}

	.clip--suggestion-reason {
		color: $brand-font-color;

		.question {
			font-weight: 600;
		}

		p {
			margin: 0;
		}
	}

	.clip--links {
		.clip-link {
			color: #007bff;
			font-weight: 600;
			margin: 0;
			text-align: center;

			&:not(.disabled):hover {
				cursor: pointer;
			}
		}
	}
}

.in-transcript {
	.clip-card {
		&.active {
			.clip-dot {
				svg {
					display: none;
				}

				span {
					display: inline;
					padding: 0.15rem 0.5rem;
					font-size: 11px;
					font-weight: bold;
					letter-spacing: 0.5px;
					line-height: 8px;
					color: $color-white;
					border-radius: 19px;
				}

				&.link {
					span {
						background: $brand-green;
					}
				}
				&.takeaway {
					span {
						background: $brand-purple;
					}
				}
				&.processing {
					span {
						background: $brand-orange;
					}
				}
				&.error {
					span {
						background: $brand-red;
					}
				}
				&.suggested {
					span {
						background: $ai-blue;

						svg {
							display: inline;
						}
					}
				}
			}
		}
	}
}

.clip--preview {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1em;
	margin-bottom: 0.5em;
	padding: 0.5em;
	border: 1px solid $color-gray-4;

	&.share-clip {
		border: none;
		margin: 0;
	}

	.preview--icon {
		color: $brand-purple;
	}

	.preview--bar {
		position: relative;
		flex: 1;
		height: 10px;
		margin: 0 10px;
		background: $color-gray-4;
		border-radius: 5px;
		.preview--highlightedBar {
			position: absolute;
			height: 100%;
			max-width: 100% !important;
			background: $brand-purple;
			border-radius: 10px;
		}
	}

	.preview--duration {
		font-size: 0.7rem;
		font-weight: 500;
		color: $brand-purple;
		text-transform: uppercase;
	}
}
