.insights-page-body {
	h4 {
		font-size: 1.5rem;
	}

	.insights-table-filters {
		display: flex;
		column-gap: 1.5rem;
		margin-bottom: 1.125rem;

		.insights-filter {
			display: flex;

			.casted-dropdown-button {
				box-sizing: border-box;

				.dropdown-menu {
					max-width: 427px;
					min-width: 427px;
					padding: 0;

					.dropdown-item {
						padding: 0;
					}

					a {
						white-space: normal;
					}

					h6 {
						font-weight: 700;
					}
				}
			}

			.selected-value {
				display: flex;
				align-items: center;

				.value-name {
					padding-left: 8px;
					padding-right: 6px;
				}

				.recharts-legend-wrapper {
					transform: translateY(-23px);
				}

				.filter-legend {
					position: absolute;
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					height: 100%;

					.legend-percent {
						margin: 0;
						font-size: 0.625rem;
						font-weight: 600;
					}

					.legend-total {
						margin: 0;
						font-size: 0.4rem;
						font-weight: 400;
						color: $color-gray-6;
					}
				}
			}
		}

		.default-value {
			color: $color-gray-6;
		}

		.toggle-include-impressions {
			padding-top: 10px;
		}
	}

	.base-table {
		.rdt_TableRow {
			height: 80px;
		}
	}

	.insights-theme-badge {
		padding: 0.375rem 0.625rem;
		color: $color-white;
		font-size: 0.8125rem;
		box-sizing: border-box;
		border-radius: 0;

		&.no-color {
			color: $brand-font-color;
			border: 1px solid $brand-font-color;
		}
	}
}
