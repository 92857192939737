.sidebar {
	position: relative;
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	width: $sidebar-width;
	transition: margin-left 250ms ease-out, transform 250ms ease-out;
	background-color: $color-white;
	box-shadow: 0 -5px 5px -1px rgba(21, 27, 38, 0.15);
	z-index: $sidebar-z-index;

	&.sidebar-collapsed {
		margin-left: -$sidebar-width;
	}

	.sidebar-header {
		padding: 40px 10px 40px 40px;
		// height: $appbar-height + 1px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.sb-casted-logo {
			height: 34px;
			width: auto;
			cursor: pointer;
		}
	}

	& > .sidebar-option {
		border-top: 1px solid $color-gray-4;

		& > a {
			width: 100%;
			display: inline-block;
			padding: 1rem;
		}

		.sidebar-option-link {
			align-items: center;
			color: $color-gray-7;
			text-transform: capitalize;
			
			&.active {
				background-color: $color-gray-2;
			}
		}

		.option-icon {
			margin-right: 10px;
			margin-top: -2px;
			color: $color-gray-7;
		}
	}

	.sidebar-main-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		padding-top: 64px;

		.sidebar-nav-list {
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;

			.sidebar-nav-list-link {
				text-decoration: none;
				color: $brand-font-color;
				border-left: 6px solid transparent;

				&.sidebar-nav-list-link-active {
					background-color: $brand-light-purple;
					border-left: 6px solid $brand-purple;
				}
			}
		}
	}
}
