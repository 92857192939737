.metric-ranked-list {
	height: 100%;

	.data-column-title {
		text-align: right;

		cursor: pointer;

		&.sorting {
			padding-right: 0;
			padding-left: 0;
		}
	}

	.header-row {
		flex-direction: row-reverse;

		.sort-icon {
			margin-right: 10px;
		}
	}

	.list-row {
		.list-row--name {
			font-size: 16px;
			padding: 10px 10px 8px 20px;
			margin-bottom: 0;
			display: flex;

			.subtype {
				font-size: 12px;
				padding-left: 10px;
				padding-top: 4px;
				text-transform: uppercase;

				&.takeaway {
					color: $brand-purple;
				}

				&.link {
					color: $brand-green;
				}
			}
		}

		.list-row--text {
			padding: 10px;
			white-space: nowrap;
		}

		.list-row--subtext {
			color: $color-gray-6;
			font-weight: 700;
			letter-spacing: 1px;
			font-size: 12px;
			padding-left: 45px;
		}

		.data-column {
			text-align: right;
			.list-row--text {
				padding-right: 1.5em;
			}
		}
		&.has-link {
			cursor: pointer;
			&:hover {
				background: $color-gray-2;
			}
		}
		&.loading {
			padding: 10px;
		}
	}

	.list-row.clickable:hover {
		outline: 2px solid $brand-secondary;
		cursor: pointer;
	}
}
