.episode-theme-badges {
	display: flex;
	flex-wrap: wrap;
	column-gap: 0.25rem;
	row-gap: 0.25rem;

	.episode-theme-badge {
		padding: 0.5rem 1rem;
		font-size: 0.875rem;
		font-weight: 600;
		line-height: 1.1875rem;
		color: $color-white;
		background-color: $color-gray-9;
	}
}
