.preview-instructions {
	margin-bottom: 1.5em;
}

.share-copy {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	margin-top: 2em;
	padding: 1em;
	border-radius: 5px;
	background: $color-gray-2;

	.share-copy--text {
		flex: 1;
		border: none;
		outline: none;
		font-size: 16px;
		font-weight: bold;
		color: $color-gray-8;
		margin-right: 15px;
		background-color: transparent;
		white-space: nowrap;
	}

	&.share {
		flex-direction: column;
		align-items: normal;

		.copy-share {
			margin-top: 20px;
		}
	}
}

.social-card-preview {
	.card {
		border-radius: 5px;
	}
	.card-img-top {
		height: 252px;
		max-height: 56.25%;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		border-radius: 0;
	}
	.card-body {
		padding: 0.5em;
		p {
			margin: 0;
		}
		&.link-details {
			background-color: #f4f6f8;
		}
	}

	.mock-profile {
		display: flex;
		align-items: center;
		margin-bottom: 1em;
		.mock-profile-img {
			width: 48px;
			height: 48px;
			border-radius: 100%;
			background-color: $color-gray-4;
		}
		.mock-profile-name > div {
			width: 128px;
			height: 12px;
			margin-left: 1em;
			margin-bottom: 0.5em;
			background-color: $color-gray-4;
			border-radius: 2px;
			&:nth-child(2) {
				width: 96px;
				height: 6px;
			}
		}
	}
	.mock-description {
		font-size: 14px;
	}
}

.embed-preview {
	.embed-content-container {
		display: flex;
		flex-direction: row;
		padding: 1em;
		box-sizing: border-box;
		border: 2px solid $color-gray-2;
		border-radius: 4px;
		flex-wrap: wrap;
		margin-top: 1em;

		.embed-thumbnail {
			display: block;
			height: 150px;
			width: 150px;
			margin: 0;
			margin-right: 17px;
			border-radius: 4px;
		}
		.embed-video {
			width: 100%;
			height: 472px;
			margin-right: 17px;
			object-fit: cover;
			border-radius: 4px;
		}
		.embed-content-info {
			display: flex;
			flex-direction: column;
			flex: 1;
		}
		.embed-meta {
			color: $color-gray-7;
			margin: 0;
			margin-bottom: 0.5em;
		}
	}

	.clip-player {
		margin-top: 1.5em;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.audio-controls {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			&.left {
				margin-right: 12px;
			}

			.control {
				display: inline-block;
				margin: 0 5px;
				padding: 0;
				-webkit-appearance: none;
				border: none;
				box-shadow: none;
				background: transparent;
				font-size: 1rem;
				color: $brand-primary;
			}
		}

		.audio-progress {
			flex: 1;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-right: 16px;

			.current-time {
				font-size: 10px;
				font-weight: bold;
				color: $brand-primary;
				margin-right: 8px;
				width: 28px;
				text-align: center;
				letter-spacing: 1.14px;

				&:last-of-type {
					margin-right: 0;
				}
			}

			.bar {
				flex: 1;
				background-color: $color-gray-2;
				border: 1px solid $color-gray-3;
				height: 8px;
				border-radius: 8px;
				margin-right: 8px;
				margin-left: 8px;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: flex-start;
				cursor: pointer;
				position: relative;

				.progress {
					position: relative;
					background-color: $brand-primary;
					border-right: none;
					height: 100%;
					transition: width 0.1s;
					border-radius: 8px;
					pointer-events: none;
					z-index: 4;
					&:after {
						content: '';
						position: absolute;
						top: -3px;
						right: -8px;
						width: 8px;
						height: 8px;
						border-radius: 100%;
						background: $color-white;
						border: 3px solid $brand-primary;
					}
				}
			}

			.clip-bound {
				position: absolute;
				height: 8px;
				top: -1px;
				background: rgba($brand-primary, 0.4);
				border-top: 1px solid $brand-primary;
				border-bottom: 1px solid $brand-primary;
				z-index: 1;
				overflow: visible;
				&:before,
				&:after {
					content: '';
					position: absolute;
					width: 0px;
					height: 32px;
					top: -13px;
					left: 0;
					border-radius: 2px;
					border-right-width: 4px;
					border-right-style: solid;
					border-right-color: inherit;
				}
				&:after {
					left: auto;
					right: 0;
				}
			}
		}
	}
}
