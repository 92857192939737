.share-content-modal {
	display: flex;
	flex-direction: column;

	.modal-dialog {
		.modal-content {
			padding: 0;

			.modal-header {
				justify-content: center;
				padding: 1.875rem 0 0;
			}
		}
	}

	.scm-share-status-banner {
		margin: 0 1em;
	}

	.scm-share-content-container {
		display: flex;
		flex-direction: row;
		position: relative;
		padding: 1em;
		box-sizing: border-box;
		border-radius: 4px;
		flex-wrap: wrap;
		margin-top: 1em;

		.embed-loading {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: $color-white;
		}

		.scm-content-thumbnail {
			height: 93px;
			width: 93px;
			margin-right: 1rem;
			object-fit: cover;
			border-radius: 4px;
		}

		.scm-content-video {
			width: 100%;
			margin-right: 1rem;
			object-fit: cover;
			border-radius: 4px;
			height: 472px;
		}

		.share-takeaways {
			flex-direction: row;
			flex-basis: 100%;
			margin-top: 1em;
		}

		.share-takeaway {
			margin: 0.5em;
			font-size: 0.75rem;
		}

		.scm-share-content-info {
			display: flex;
			flex-direction: column;
			flex: 1;

			.scm-share-content-subtitle {
				font-size: 0.6875rem;
				margin-top: 0;
				margin-bottom: 0.625rem;
				font-weight: 700;
				text-transform: uppercase;
				opacity: 0.52;
			}

			.scm-share-content-name {
				margin-top: 0;
				margin-bottom: 0.625rem;
			}

			.scm-share-content-duration {
				font-size: 0.75rem;
				margin-top: 0;
				margin-bottom: 1rem;
			}

			ol li {
				list-style: decimal;
			}
			ul li {
				list-style: disc;
			}
		}
	}
}

.share-content-via-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0 auto;

	.share-header-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 20px 47px 0px 30px;

		.share-header-info {
			display: flex;

			.share-header-text {
				width: 50%;
				padding-bottom: 15px;

				.share-header-title {
					font-weight: normal;
					color: $color-gray-9;
				}
			}
		}
	}

	.share-via-header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		box-sizing: border-box;

		label {
			font-size: 0.75rem;
			font-weight: bold;
			letter-spacing: 1.37px;
			text-transform: uppercase;
			margin: 0.625rem 0.3125rem 0.625rem 0;
		}

		.share-via-options {
			flex: 1;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-start;

			.nav-link {
				box-sizing: border-box;
				padding: 0 0.75rem;
				border-bottom: 2px solid transparent;
				cursor: pointer;
				color: $color-gray-9;

				&:hover {
					border-bottom-color: $color-gray-8;
					color: $color-gray-8;
				}

				&.active {
					border-bottom-color: $color-gray-8;
					color: $color-gray-8;
				}

				h5 {
					display: flex;
					align-items: center;
					margin: 0;
				}

				svg {
					height: 14px;
					margin-right: 0.625rem;
				}

				img {
					height: 18px;
				}
			}
		}
	}

	.share-via-content {
		width: 100%;
		padding: 1.5rem 1.875rem;
		background-color: $color-gray-15;

		.share-via-link-content {
			.scm-share-content-container {
				background-color: $color-white;
			}

			.share--checkbox {
				font-size: 0.875rem;
			}
		}

		.share-via-embed-content {
			&.active {
				display: flex;

				& > div {
					width: 50%;

					&.embed-options {
						margin-right: 2.5em;
					}

					&.embed-preview {
						flex-shrink: 0;

						.scm-share-content-container {
							padding: 0;

							iframe {
								border: none;
							}
						}
					}
				}
			}

			.share--checkbox {
				font-size: 0.875rem;
			}
		}

		.share-via-link-content-inner {
			padding: 1.25rem;
			background-color: $color-white;
			border-radius: 3px;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			box-sizing: border-box;
			margin-top: 1em;
			border: 1px solid $color-gray-11;

			.shared-clip-link-text {
				flex: 1;
				border: none;
				outline: none;
				font-size: 1rem;
				font-weight: bold;
				color: $color-gray-8;
				margin-right: 1rem;
				background-color: transparent;
				white-space: nowrap;
			}
		}

		.copy-to-clipboard {
			margin: 0;
		}

		.share--switch {
			.custom-control-input:checked ~ .custom-control-label::before {
				background-color: $color-green-1;
				border-color: $color-green-1;
			}
		}
	}
}

.share-content-player {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	.audio-controls {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		&.left {
			margin-right: 0.75rem;
		}

		.control {
			display: inline-block;
			margin: 0 5px;
			padding: 0;
			-webkit-appearance: none;
			border: none;
			box-shadow: none;
			background: transparent;
			font-size: 1rem;
			color: $brand-primary;
		}
	}

	.audio-progress {
		flex: 1;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-right: 1rem;

		.current-time {
			font-size: 0.625rem;
			font-weight: bold;
			color: $brand-primary;
			margin-right: 0.5rem;
			width: 28px;
			text-align: center;
			letter-spacing: 1.14px;

			&:last-of-type {
				margin-right: 0;
			}
		}

		.bar {
			flex: 1;
			background-color: $color-gray-2;
			border: 1px solid $color-gray-3;
			height: 8px;
			border-radius: 8px;
			margin-right: 0.5rem;
			margin-left: 0.5rem;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			cursor: pointer;
			position: relative;

			.progress {
				position: relative;
				background-color: $brand-primary;
				border-right: none;
				height: 100%;
				transition: width 0.1s;
				border-radius: 8px;
				pointer-events: none;
				z-index: 4;
				&:after {
					content: '';
					position: absolute;
					top: -3px;
					right: -8px;
					width: 8px;
					height: 8px;
					border-radius: 100%;
					background: $color-white;
					border: 3px solid $brand-primary;
				}
			}
		}
	}
}
