.content-group-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 95%;
	padding-bottom: 1em;
}

.content-group-expand {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 1em;
	width: 95%;

	.content-group-expand-text {
		padding-right: 4px;
		cursor: pointer;
	}
	
	.content-group-expand-icon {
		cursor: pointer;
	}
}

.content-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	column-gap: 2.5em;
	row-gap: 1em;

	&.display-less {
		max-height: calc((243px + 1em) * 2);
		overflow: hidden;
	}
}
