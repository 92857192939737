.playlists {
	display: flex;
	flex-direction: column;
	padding: 1.875rem 2.5rem;

	.playlists-header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;

		.playlists-header {
			margin: 0;
			margin-bottom: 1.5625rem;
		}
	}
	.playlists-search {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 20px 0px;
		border: 0.05em solid #c6d0d3;
		border-radius: 0.2em;
		min-height: 40px;
		padding-left: 10px;
		background-color: white;
		width: 40%;

		input {
			font-size: 16px;
			border: none;
			outline: none;
			width: 99%;
			padding: 0px 8px;
		}
	}
	.playlists-empty-container {
		padding: 250px 0px;
	}
}

.playlists-loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
