.imported-content-row {
	display: flex;
	margin-bottom: 1.25rem;
	padding: 1rem;
	background: $color-white;
	border: 1px solid $color-gray-10;
	transition: box-shadow 0.1s ease;

	&:hover {
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
		cursor: pointer;

		* {
			text-decoration: none;
		}
	}

	.content-thumbnail {
		margin-right: 0.625rem;

		img {
			width: 40px;
			height: 40px;
			box-sizing: border-box;
			border: 2px solid $color-gray-10;
			border-radius: 2px;
		}
	}

	.content-info {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-right: 0.625rem;

		.content-type {
			display: flex;
			align-items: center;
			margin-bottom: 0.375rem;
			font-size: 0.625rem;
			font-weight: normal;
			line-height: 0.875rem;
			color: $color-gray-12;

			span {
				margin-left: 0.375rem;
				font-size: 0.625rem;
				text-transform: capitalize;
			}
		}

		.content-name {
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 1.1875rem;
		}
	}
}
