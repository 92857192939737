.engagements-body {
	margin: 30px 36px;
	margin-bottom: 0px;
	width: 100%;
	background: white;

	.engagements-header {
		padding: 20px 56px 0px 56px;

		.header {
			display: flex;
			width: 100%;
			align-items: baseline;

			.title {
				flex-grow: 1;
				margin-bottom: 13px;
				font-size: 24px;
				font-weight: 600;
			}

			.datepicker {
				flex-grow: 10;

				.metric-date-range {
					.col {
						.casted-button {
							&.metric-date-range-trigger {
								background: #ffffff;
								border: 0.143646px solid #c2c2c2;
								box-shadow: 4.30937px 4.30937px 5.74583px -2.87292px rgba(0, 0, 0, 0.15);
								border-radius: 12px;
								min-width: 170px;

								display: flex;
								justify-content: space-between;
								align-items: center;
								font-weight: 600;
								font-size: 16px;
								letter-spacing: normal;
								text-transform: none;
								min-height: auto;
								margin-top: 2px;

								&:hover {
									color: black;
								}
							}
						}
					}
				}
			}

			.tracker-dropdown {
				margin-left: 8px;
			}
		}

		.description {
			font-size: 16px;
			font-weight: 400;
		}
	}

	.engagement-tracker {
		padding: 54px;
	}
}
