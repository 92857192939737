.copy-to-clipboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 6px;
  min-width: 100px;

  .copy-to-clipboard-btn {
    margin: 0 4px 0 0;
    height: 30px;
    padding: 0 20px;
  }

  .copy-success-msg {
    font-size: 14px;
    opacity: 0.7;
    font-weight: 600;
  }
}