.syndicated-platforms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .syndicated-platforms-pill {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-gray-2;
    padding: 3px 9px;
    border-radius: 4px;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin-right: 6px;
    margin-bottom: 5px;
  }
}