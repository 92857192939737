.theme-group {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 2.5rem 0 0.75rem;
	border-bottom: 1px solid $color-gray-3;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-bottom: none;
	}

	.themes-list-header {
		margin: 0 0 0.75rem;
	}

	.themes-cards {
		display: flex;
		flex-wrap: wrap;
		column-gap: 1.5rem;
		row-gap: 1.5rem;
	}
}
