.table-confirmation-toggle {
	color: $brand-red;
	display: flex;
	align-items: center;
	font-weight: 600;

	.toggle-icon {
		margin-right: 4px;
	}
}

.table-confirmation-tooltip {
	cursor: pointer;
	&.tooltip.show {
		opacity: 1 !important;
		.arrow {
			display: none !important;
		}
	}

	&.tooltip {
		.arrow {
			display: none !important;
		}
	}

	.tooltip-inner {
		min-width: 429px;
		background: white;
		color: black;
		border: 1px solid #c4c4c4;
		border-radius: 4px;
		padding: 5px 0px;

		.table-confirmation-header {
			border-bottom: none;
		}

		.table-confirmation-body {
			text-align: left;
		}

		.table-confirmation-footer {
			border-top: none;
			display: flex;
			justify-content: flex-start;
		}
	}
}
