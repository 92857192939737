.password-set {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $brand-primary;

	.password-set-box {
		display: flex;
		flex-direction: column;
		background: $color-white;
		box-shadow: $box-1;
		padding: 18px 15px;
		border-radius: 6px;
		width: 600px;

		.password-set-header {
			margin-top: 0;
			margin-bottom: 2px;
		}

		.pw-minimum-length-msg {
			font-size: 16px;
			opacity: 0.4;
			margin: 0 0 15px 0;
		}

		.password-set-label {
			font-size: 16px;
			opacity: 0.8;
			margin-bottom: 7px;
			margin-left: 2px;
		}

		.password-set-form-input {
			padding: 11px 10px;
			font-size: 16px;
			border: 1px solid $color-gray-2;
			background-color: $color-off-white;
		}

		.password-set-btn {
			margin: 25px 0 0 0;
			height: 38px;
			background-color: $brand-secondary;
		}

		.password-set-goto-login-btn {
			height: 38px;
			margin: 5px 0 0 0;
			font-size: 16px;
		}
	}
}
