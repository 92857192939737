.social-layout {
	display: flex;

	.embed-options {
		width: 40%;

		.share-content-options {
			.share-type-buttons {
				.share-type-button {
					font-size: 12px;
					font-weight: 700;
					line-height: 18px;
					letter-spacing: 1.2px;
					text-transform: uppercase;
					padding: 11px 12px;
					line-height: 150%;

					&.btn {
						color: #72a8d7 !important;
						border-color: #72a8d7 !important;
					}

					&.active,
					&:hover {
						background-color: #01315f !important;
						border-color: #01315f !important;
						color: white !important;
					}
				}
			}

			.share-audio-option {
				padding: 0.75rem;
				border-radius: 3px;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				margin-top: 1em;
				border: 1px solid $color-gray-11;
				width: fit-content;
			}
		}
	}

	.embed-preview {
		margin-left: 50px;
		flex-shrink: 0;
		flex: auto;

		.scm-share-content-container {
			padding: 0;
			height: 90%;

			iframe {
				border: none;
			}
		}
	}
}

.copy-share {
	margin-top: 50px;

	.copy-share-link {
		width: fit-content;
	}

	.tooltip {
		&.link-copied-tooltip {
			.arrow {
				display: none !important;
			}
		}
	}
}
