.promotion-card {
	padding: 1.5rem;
	background: $color-white;
	box-shadow: 0px 4px 4px rgba(106, 106, 106, 0.15);
	border-radius: 4px;

	.promotion-card-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		* {
			margin-bottom: 0;
		}

		h4 {
			margin-bottom: 0.75rem;
		}

		.date-range-text {
			text-align: right;
		}

		.promotion-banner {
			margin-bottom: 1rem;
			padding: 0.25rem 0.875rem;
			font-size: 0.75rem;
			font-weight: 600;
			line-height: 14.62px;
			background: $brand-purple;
			color: $color-white;
			border-radius: 4px;
		}
	}

	.promotion-content {
		display: flex;
		column-gap: 1.125rem;

		.promotion-thumbnail {
			height: 118px;
			border-radius: 4px;
			overflow: hidden;

			img {
				height: 100%;
			}
		}

		.promotion-text {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.promotion-actions {
				display: flex;
			}
		}

		p {
			width: 490px;
			margin-right: 170px;
		}

		button {
			width: 220px;
			margin-top: 1.5rem;
		}
	}
}
