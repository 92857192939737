.manage-collection-permissions {
	.collection-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.permission-toggle {
			.custom-control-input:checked ~ .custom-control-label::before {
				cursor: pointer !important;
			}
			.custom-control-input:checked ~ .custom-control-label::after {
				cursor: pointer !important;
			}
			.custom-control-input ~ .custom-control-label::before {
				cursor: pointer !important;
			}
			.custom-control-input ~ .custom-control-label::after {
				cursor: pointer !important;
			}
		}

		.details {
			display: flex;
		}

		.show-thumbnail {
			width: 100%;
			max-width: 42px !important;
			height: auto;
			border-radius: 4px;
			object-fit: cover;
			margin-right: 10px;
		}

		.podcast-name {
			color: #007994;
			margin-bottom: 0px;
		}
	}

	.user-permission-search {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;
		border: 0.05em solid #c6d0d3;
		border-radius: 0.2em;
		min-height: 40px;
		padding-left: 10px;
		background-color: white;
		width: 40%;

		.search-icon {
			color: #007994;
		}

		input {
			font-size: 16px;
			border: none;
			outline: none;
			width: 99%;
			padding: 0px 8px;
		}
	}

	.manage-collection-search {
		width: 40%;
	}
}
