.playlist-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1.875rem 2.5rem 2.25rem;

	.playlist-nav {
		margin-bottom: 2rem;

		a {
			display: inline-block;
			padding: 0;
		}

		h2 {
			display: flex;
			align-items: center;
			width: max-content;
			margin: 0;
			text-transform: capitalize;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.arrow-left {
			margin-right: 0.5rem;
			font-size: 1rem;
		}
	}

	.playlist-body {
		display: flex;
		flex-direction: column;
	}
}

.playlist-loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
