.shares {
	display: flex;
	flex-direction: column;
	padding: 50px 60px 50px 50px;

	.shares-header-row {
		display: flex;
		flex-direction: column;
		align-items: baseline;

		.shares-header {
			margin: 0;
			margin-bottom: 0.5rem;

			.name {
				font-weight: normal;
			}
		}

		p {
			margin-bottom: 2rem;
		}
	}

	.share-sections {
		.share-content-empty {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;

			.podcat {
				width: 400px;
			}

			p {
				margin-top: 1.5rem;
				max-width: 450px;
				font-size: 1.5rem;
				font-weight: 600;
				line-height: 2.0625rem;
				text-align: center;
			}
		}

		.shares-list {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			height: 100%;
			padding-top: 12px;

			.share-list-empty {
				display: flex;
				justify-content: center;
				min-height: 100px;
			}

			.selected-shares {
				display: flex;

				.shares-selected {
					margin-right: 26px;
				}

				.archive-shares {
					cursor: pointer;
					color: #007994;
					font-weight: 600;
				}
			}

			.shares-pagination {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

.casted-modal {
	&.share-edit {
		.modal-content {
			padding: 0 !important;
			border-radius: 4px;

			.modal-header {
				padding: 1.5rem 1.5rem 0 1.5rem;
				background-color: $color-gray-1;
			}

			.modal-body {
				padding: 0 1.5rem;
			}

			.modal-footer {
				padding: 0 1.5rem 1.5rem 1.5rem;
			}

			.edit-share-header {
				padding-top: 22px;
				padding-bottom: 23px;
			}
		}

		.share-title {
			font-weight: normal;
			color: $color-gray-9;
		}
	}
}

.share-header {
	.share-description {
		font-size: 18px;
	}

	.share-link {
		display: flex;
		font-size: 18px;
		padding: 13px 0 40px 0;
		cursor: pointer;
	}

	.light-text {
		color: #838f9b;

		&.link {
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 500px;
			display: block;
			overflow: hidden;
			margin-left: 8px;
		}
	}
}

.share-loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
