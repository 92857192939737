.tracker-dropdown {
	display: flex;
	justify-content: end;
	margin-top: -20px;

	.casted-dropdown-button {
		&.tracker-options {
			.btn {
				background: #ffffff;
				border: 0.143646px solid #c2c2c2;
				box-shadow: 4.30937px 4.30937px 5.74583px -2.87292px rgba(0, 0, 0, 0.15);
				border-radius: 12px;
				min-width: 170px;

				&:hover {
					color: black;
				}
			}
		}
	}

	.tracker-value {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 600;

		.tracker-carrot {
			margin-left: 8px;
		}
	}
}

.tracker-loading {
	display: flex;
	justify-content: center;
}

.tracker-container {
	&.clip {
		padding-top: 60px;

		.clip-title {
			margin-bottom: 20px;
			font-size: 20px;
			font-weight: 600;
		}
	}

	.tracker-legend {
		display: flex;
		align-items: center;
		padding-bottom: 52px;

		.legend-title {
			font-weight: 600;
			padding-right: 21px;
			font-size: 14px;
		}

		.legend-color {
			width: 16px;
			height: 16px;
			border-radius: 4px;

			&.low {
				background: #d9d9d9;
			}

			&.medium {
				background: #8563f8;
			}

			&.high {
				background: #633ce7;
			}
		}

		.legend-text {
			font-size: 12px;
			font-weight: 400;
			padding-left: 5px;
			padding-right: 19px;
		}
	}

	.tracker-graph {
		margin-bottom: 21px;

		.empty-graph {
			height: 30px;
			width: 100%;
			background: #ebecec;
		}

		.empty-clip {
			width: 100%; 
			background: #ebecec; 
			height: 30px; 
			position: relative;

			.empty-clip-border {
				position: absolute;
				width: 5px; 
				border: 1px dashed #5d5fef; 
				height: 30px;
			}
		}
	}

	.tracker-xaxis {
		width: 100%;
		height: 3px;
		background: gray;
		margin-bottom: 52px;
		display: flex;
		justify-content: space-between;

		.tracker-markers {
			height: 16px;
			width: 1px;
			background: gray;
			margin-top: -6.5px;
			display: flex;
			flex-direction: column;
			align-items: center;

			&.end {
				background: white;
			}

			.marker {
				display: flex;
				padding-top: 20px;
				font-size: 10px;
				color: gray;
			}
		}
	}

	&.company .tracker-xaxis {
		width: 100%;
		height: 3px;
		background: gray;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;

		.tracker-markers .marker {
			display: flex;
			padding-top: 20px;
			font-size: 7px;
			color: gray;
			min-width: 20px;
		}
	}

	.graph-container {
		height: 30px;
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;

		.tracker-segment {
			height: 30px;
			cursor: pointer;

			&.hover {
				height: 36px;
				border: 2px solid #beabfa;
				border-radius: 4px;
			}

			&.High {
				background-color: #633ce7;
			}

			&.Medium {
				background-color: #8563f8;
			}

			&.Low {
				background-color: #d9d9d9;
			}
		}

		.clip-border {
			position: absolute;
			border: 1px dashed #5d5fef;

			&.height {
				height: 33px;
				width: 1px;
			}

			&.top {
				width: 30px;
				height: 2px;
				top: 98%;
			}

			&.bottom {
				width: 30px;
				height: 2px;
				bottom: 98%;
			}
		}
	}
}

.graph-tooltip {
	.engagement-level {
		font-weight: 600;
		padding-bottom: 8px;

		&.High {
			color: #633ce7;
		}

		&.Medium {
			color: #8563f8;
		}

		&.Low {
			color: #d9d9d9;
		}
	}

	.tooltip-time {
		margin-bottom: 6px;
	}

	.tooltip-numbers {
		display: flex;

		.engagement-range {
			padding-left: 4px;
		}
	}
}
