.sidebar {
	& > .nav {
		display: flex;
		flex-direction: column;
		border-top: 1px solid $color-gray-4;
		color: $color-gray-7;

		.nav-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1em;
			font-weight: 600;
			color: $color-gray-7;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.nav-link {
			display: flex;
			padding: 0;

			&:hover {
				background-color: $color-gray-2;
			}

			a {
				width: 100%;
				text-decoration: none;
			}

			.nav-option {
				padding: 0.5rem 0.5rem 0.5rem 1.875rem;
				font-size: 0.875rem;
				font-weight: normal;
				line-height: 1.1875rem;
				color: $color-gray-7;
			}

			.nav-active {
				border-left: 6px solid $brand-purple;
				width: 100%;
				background-color: $color-gray-2;
			}
		}
	}
}
