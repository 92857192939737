.login-container-sign-up {
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100vh;
	width: 100vw;
	box-sizing: border-box;

	> .left {
		display: flex;
		flex-direction: column;
		flex: 1;
		background-color: #01042b;

		> .sign-up-left-content {
			display: flex;
			flex-direction: column;
			margin: 96px 120px;
			box-sizing: border-box;
			justify-content: center;
			flex: 1;

			.sign-up-welcome-to-casted-section {
				display: flex;
				flex-direction: column;
				margin-bottom: 40px;

				.sign-up-welcome-headline {
					img {
						display: block;
						margin: 0 auto;
						width: 100%;
						max-width: 350px;
						height: auto;
					}
				}
			}
		}
	}

	> .right {
		display: flex;
		flex: 1;
		flex-direction: column;
		padding: 65px 40px 10px 40px;

		.sign-up-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			.sign-in-text {
				font-size: 15px;
				opacity: 0.8;

				.sign-in-btn {
					font-size: 15px;
					margin: 0;
					margin-left: 10px;
				}
			}
		}
	}
}

.terms-and-policies {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	.t-and-c-btn {
		margin: 8px;
	}

	.t-and-c-text {
		font-size: 14px;
		opacity: 0.3;
		color: $brand-primary;
		font-weight: 700;

		&.sign-in {
			color: $color-white;
		}
	}
}

.login-and-signup-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;

	.lasc-box {
		display: flex;
		flex-direction: column;
		width: 350px;
		box-sizing: border-box;

		.lasc-box-tabs {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 25px;

			.casted-button {
				flex: 1;
				margin: 0;
				height: 50px;
				color: $color-gray-6;
				padding: 0;
				transition: border-bottom 0.25s ease, color 0.25s ease;
				border-bottom: 2px solid transparent;
				border-radius: 0;
				margin-bottom: -1px;
				font-size: 16px;
				font-weight: 600;
				color: $brand-purple;
				border-bottom: 2px solid $brand-purple;

				&:hover {
					background: transparent;
					color: $brand-purple !important;
				}
			}
		}

		.lasc-box-content {
			display: flex;
			flex-direction: column;

			.sign-in-up-label {
				font-size: 12px;
				opacity: 0.8;
				margin-bottom: 7px;
				margin-left: 2px;
			}

			.sign-in-up-form-input {
				padding: 11px 10px;
				font-size: 13px;
				border: 1px solid $color-gray-2;
				background-color: $color-off-white;
			}

			.pw-minimum-length-msg {
				font-size: 11px;
				opacity: 0.4;
				margin: 3px 0 5px 4px;
			}

			.error-message {
				margin: 10px 0;
				font-size: 12px;
				color: $brand-red;
			}

			.error-message-link {
				margin: 10px 0;
				font-size: 12px;
				color: $brand-purple;
				text-decoration: underline;
			}

			.sign-in-up-btn,
			.send-reset-password-btn {
				margin: 10px 0 0 0;
				height: 38px;
				background-color: $brand-green;
			}

			.reset-password-go-back-btn {
				margin: 10px 0 0 0;
				height: 38px;
				font-size: 12px;
			}

			.forgot-password-btn,
			send-confirm-btn {
				margin: 10px 0 0 0;
				height: 30px;
				font-size: 12px;
				opacity: 0.8;
			}

			.sign-in-up-or-section {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-top: 10px;

				.sign-in-up-or {
					text-align: center;
					opacity: 0.4;
					margin-left: 10px;
					margin-right: 10px;
					font-size: 14px;
					margin-top: 10px;
				}

				.sign-in-up-or-line {
					flex: 1;
					height: 1px;
					background-color: $color-gray-3;
				}
			}

			.reset-password-header {
				font-size: 22px;
				margin: 0 0 20px 0;
			}

			.login-buttons {
				display: flex;
				flex-direction: column;
				margin-top: 5px;
				justify-content: space-around;
				align-items: center;

				.login-link {
					text-decoration: none;
					color: $brand-font-color;

					&:visited {
						color: $brand-font-color;
					}
				}
			}
		}
	}
}
