.campaign-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.campaign-modal-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-height: 190vh;
  max-width: 800px;
  overflow-y: auto;

  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

}
