.audio-player {
	video {
		margin: 0;
	}

	.audio-controls {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-right: 12px;

		.control {
			display: inline-block;
			margin: 0 5px;
			padding: 0;
			-webkit-appearance: none;
			border: none;
			box-shadow: none;
			background: transparent;
			font-size: 1rem;
			color: $brand-primary;
			cursor: pointer;
			&:hover {
				color: $brand-purple;
			}
		}
	}

	.media-progress {
		position: relative;
		width: 100%;
		height: 0.5em;
		background: $color-gray-4;
		box-shadow: none;
		margin: 0 1em;
		border-radius: 1em;
		cursor: pointer;

		.bar {
			height: 100%;
			background: $color-gray-4;
			border-radius: 1em;

			.progress {
				position: relative;
				background-color: $brand-purple;
				border-right: none;
				height: 100%;
				transition: width 0.1s;
				border-radius: 8px;
				pointer-events: none;
				z-index: 4;
				overflow: visible;

				&:after {
					content: '';
					position: absolute;
					top: -2px;
					right: -4px;
					width: 12px;
					height: 12px;
					border-radius: 100%;
					background: $color-white;
					border: 3px solid $brand-purple;
				}
			}

		}
	}
}

.audio-only {
	height: 0;
}
