.export-menu {
	display: flex;
	justify-content: flex-end;

	&.insights {
		.dropdown-toggle {
			font-size: 0.9rem;
			padding: 0.5rem 1.5rem;
			font-weight: 600;
			text-align: center;
			min-width: 190px;
			margin-left: auto;
		}

		.export-link {
			text-decoration: none;

			.export-type {
				display: flex;
				justify-content: left;
				padding: 0.25rem 1.5rem;
				font-weight: normal;

				&:hover {
					background-color: $color-gray-11;
				}
			}
		}
	}
}
