.filter-card {
	display: flex;
	column-gap: 0.625rem;
	cursor: pointer;
	align-items: flex-start;
	padding: .5rem 1rem;

	&:hover {
		background-color: $color-gray-2;
	}

	.recharts-legend-wrapper {
		transform: translateY(-23px);
	}

	.filter-legend {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;

		.legend-percent {
			margin: 0;
		}

		.legend-total {
			margin: 0;
			font-size: 0.625rem;
			font-weight: 400;
			line-height: 0.625rem;
		}
	}

	.filter-text {
		font-size: 1rem;
		font-weight: 600;
	}

	p {
		margin: 0;
		font-size: 0.6rem;
		font-weight: normal;
		line-height: 15px;
	}
}
