.metrics-screen {
	margin-top: 1.5rem;
	padding: 0;

	> .row {
		margin-bottom: 2rem;

		&.total-cards {
			background: white;
			margin: 0;

			.card {
				box-shadow: none;
			}
		}

		&.metric-toggle {
			width: 100%;
			background: white;
			margin: 0;
			display: flex;
			justify-content: start;
			padding: 25px 0px 24px 34px;
			align-items: center;

			.toggle-text {
				font-weight: 700;
				font-size: 14px;
				color: #758ea5;
				line-height: 17px;
				text-transform: uppercase;
				margin-right: 9px;
			}

			.percent-toggle {
				.custom-control-input:checked ~ .custom-control-label::before {
					cursor: pointer !important;
				}
				.custom-control-input:checked ~ .custom-control-label::after {
					cursor: pointer !important;
				}
				.custom-control-input ~ .custom-control-label::before {
					cursor: pointer !important;
				}
				.custom-control-input ~ .custom-control-label::after {
					cursor: pointer !important;
				}
			}
		}
	}

	.metric-date-range {
		margin-top: 0;
	}

	.btn-export-metrics span {
		color: $color-white;
	}

	.export-link {
		text-decoration: none;

		.export-type {
			display: flex;
			justify-content: left;
			padding: 0.25rem 1.5rem;
			font-weight: normal;

			&:hover {
				background-color: $color-gray-11;
			}
		}

		&.disabled {
			cursor: not-allowed;
		}
	}

	.metrics-title {
		display: flex;
		justify-content: space-between;
		padding-top: 40px;
		width: 100%;
		flex-wrap: wrap;
	}

	.tracker-card {
		min-height: 150px;
		padding: 32px 47px 38px 43px;

		.card-body {
			padding: 0px;
		}

		.tracker-title {
			color: #758ea5;
		}

		.view-insights {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;

			color: #646464;
			background: #ffffff;
			border: 0.8px solid #676767;
			border-radius: 4px;
			padding: 15px 41px;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
