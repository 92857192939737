.permission-badge {
	font-size: 12px;
	color: white;
	font-weight: 600;
	line-height: 15px;

	&.green {
		background: $color-green-1;
	}
	&.navy {
		background: #01315f;
	}
	&.purple {
		background: $brand-purple;
	}
	&.blue {
		background: $color-blue;
	}
	&.gray {
		background: $color-gray-9;
	}
	&.red {
		background: $brand-red-dark;
	}
}
