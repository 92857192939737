.casted-dropdown-button {
	box-sizing: border-box;

	.dropdown-menu {
		max-width: 300px;
		min-width: 300px;
		border-radius: 0.25em;

		a {
			white-space: normal;
		}

		h6 {
			font-weight: 700;
		}
	}

	&.btn-outline-light {
		.btn {
			background-color: $color-white;
			color: $color-gray-8;
			border: 1px solid $color-white;
			outline: none;
			box-shadow: none;
			transition: background-color 0.1s ease color 0.15s ease;

			&:hover {
				background-color: $brand-green;
				color: $color-white;
			}

			&.disabled {
				background-color: transparent;
				color: $color-white;
			}
		}
	}

	&.btn-outline-dark {
		.btn {
			background-color: $color-white;
			color: $brand-primary;
			border: 1px solid $brand-primary;
			outline: none;
			box-shadow: none;
			transition: background-color 0.1s ease color 0.15s ease;

			&:hover {
				background-color: $brand-primary;
				color: $color-white;
			}

			&.disabled {
				background-color: transparent;
				color: $brand-primary;
			}
		}
	}

	&.btn-round {
		display: flex;
		border: 1px solid $color-gray-4;
		background-color: $color-white;
		border-radius: 53px;
		align-items: center;
		justify-content: space-around;
		outline: none;
		box-shadow: none;
		transition: background-color 0.1s ease color 0.15s ease;

		&.lg {
			height: 63px;
			width: 127px;
		}
		
		&.sm {
			height: 46px;
			min-width: 170px;
		}

		&:hover {
			background-color: $brand-background;
			color: $color-white;
		}

		.btn {
			&:focus,
			&:active {
				height: inherit;
				width: inherit;
				min-width: inherit;
				border-radius: inherit;
			}
		}
	}

	&.btn-plain-arrow {
		border: 1px solid $color-gray-8;
		border-radius: 3px;
		background: $color-gray-15;

		.btn {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			font-size: 0.75rem;
			font-weight: 600;
			line-height: 13.61px;
			letter-spacing: 0.91px;
			text-transform: uppercase;
		}

		.dropdown-menu {
			.dropdown-item {
				font-size: 0.75rem;
				font-weight: 600;
				line-height: 13.61px;
				letter-spacing: 0.91px;
				text-transform: uppercase;
			}
		}
	}

	&.no-arrow {
		.btn {
			&:after {
				display: none;
			}
		}
	}

	&.no-border {
		.btn {
			border: none;
		}
	}
}
