.modal-share-clip {
	.modal-body {
		padding: 0;
	}
	.share-clip-tabs {
		justify-content: center;
		border-bottom: 2px solid $color-gray-4;
		padding-bottom: 1em;
		.nav-link {
			margin: 0 1em;
			border-bottom: 2px solid transparent;
			&.active {
				color: $brand-purple;
				border-color: $color-gray-7;
			}
		}
	}
	.tab-content {
		padding: 1.5em;
	}
	.som-share-status-banner {
		margin: 0 1.5em 1em;
		font-size: small;
	}
}

.clip-tags {
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;

	.tag {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: $brand-light-purple;
		padding: 6px 8px;
		margin-right: 8px;
		margin-bottom: 8px;
		border-radius: 3px;
		color: $brand-dark-purple;
		display: inline-block;
		font-size: 14px;
		font-weight: 500;

		.remove-tag {
			margin-left: 10px;
			cursor: pointer;
		}
	}
}

.hidden-clip {
	.header-container {
		background: $color-gray-4;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 30px 0 40px 0;

		.header {
			font-weight: 700;
		}
	}

	.clip-reason {
		padding: 10px 20px;

		.reason {
			width: 100%;
			height: 20px;
			white-space: nowrap;
			text-overflow: ellipsis;
			display: block;
			overflow: hidden;
		}

		.full-reason {
			width: 100%;
			overflow: visible;
		}
	}

	.unhide {
		display: flex;
		justify-content: end;
		margin-bottom: 20px;
	}
}

.clip-card-modal {
	border: 1px solid $color-gray-3;

	&.suggested-card {
		border: none;
		box-shadow: none;
	}

	.clip-close {
		cursor: pointer;
	}

	.clip-reason {
		padding: 10px 20px;

		.reason {
			width: 100%;
			height: 20px;
			white-space: nowrap;
			text-overflow: ellipsis;
			display: block;
			overflow: hidden;
		}

		.full-reason {
			width: 100%;
			overflow: visible;
		}
	}

	.form-group {
		.form-check {
			margin-top: 0.25rem;
			.form-check-label {
				margin-left: 0.25rem;
			}
		}
	}

	button {
		font-size: 12px;
		font-weight: 800;
		letter-spacing: 1.2px;
		line-height: 18px;
		text-transform: uppercase;
	}
}

// Styles when sidebar is open
.in-sidebar {
	&.visible {
		& > .clip-card {
			&.active {
				margin: 0;
				border: none;
				border-left: 2px solid #343a40;
			}
		}
	}
}
