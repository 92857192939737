.playlist-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	margin: 0px 32px 10px 32px;
	border: 1px solid $color-gray-4;
	padding: 15px 14px 11px 15px;

	.item-left {
		display: flex;
		align-items: center;

		.drag-icon {
			margin-right: 15px;
			color: $color-gray-6;
		}

		.item-thumbnail {
			margin-right: 15px;
			height: 32px;
			width: 32px;

			.thumbnail {
				max-width: 100%;
    		max-height: 100%;
				border: 2px solid $color-gray-10;
			}

			.no-thumbnail {
				max-width: 100%;
				max-height: 100%;	
				background: $color-purple-1;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 2px solid $color-gray-10;
			}
		}
	
		.name {
			margin-bottom: 0;
		}
	}

	.remove-icon {
		color: $color-gray-8;
		cursor: pointer;
	}

}
