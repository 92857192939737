.add-hosts {
	// @extend .resources;
	padding: 0 !important;

	.add-resource.resource .add-resource--inner {
		padding: 30px 0 !important;
	}
}

.host-row {
	padding-bottom: 20px;

	.delete-host {
		.icon-circle-container {
			top: 10px;
			right: 5px;
		}
	}

	.icon-circle-container {
		height: 20px;
		width: 20px;
		border-radius: 50%;
		border: 1px solid $color-gray-6;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-right: 12px;
		position: absolute;
		z-index: 1;

		.icon-trash {
			font-size: 12px;
			color: $color-gray-6;
		}

		&:hover {
			border-color: $brand-red;
			.icon-trash {
				color: $brand-red;
			}
		}
	}
}
