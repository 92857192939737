.playlist-header-container {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $color-gray-4;
	border-radius: 6px;
	padding: 20px 32px;
	background-color: white;
	width: 100%;
	align-items: center;
	min-height: 80px;

	.playlist-form {
		display: flex;
		column-gap: 1.25rem;

		.playlist-name {
			margin-bottom: 0;

			.name-input {
				border: none;
				border-radius: 0px;
				border-bottom: 1px solid lightgray;
				font-weight: 600;
				font-size: 16px;
			}
		}

		.form-group {
			display: flex;
			align-items: center;
			margin-bottom: 0;

			label {
				margin-right: 1.25rem;
				white-space: pre;
			}

			select {
				cursor: pointer;
			}
		}
	}

	.playlist-attributes {
		display: flex;
		align-items: center;
		column-gap: 1.25rem;

		& > div {
			display: flex;
		}

		.playlist-name {
			font-weight: 600;
			font-size: 16px;
		}

		label {
			display: flex;
			align-items: center;
			margin-bottom: 0;
			margin-right: 1rem;
			font-size: 0.75rem;
			font-weight: 700;
			line-height: 150%;
			letter-spacing: 1.2px;
			text-transform: uppercase;
		}

		.playlist-color-container {

			.playlist-color {
				height: 2em;
				width: 4em;
				position: relative;
				border-radius: 4px;
				border: 1px solid #343a40;
			}
		}
	}

	.playlist-buttons {
		.save {
			width: 200px;
		}

		.edit {
			width: 200px;
			margin-right: 20px;
			background: $color-gray-9;
			border: $color-gray-9;

			&:hover {
				background: $color-gray-12;
				border: $color-gray-12;
			}
		}

		.share {
			width: 100px;
		}
	}
}
