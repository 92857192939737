.content-drawer {
  
  
  .content-drawer--content {
    position: relative;
    overflow-y: hidden;
    transition: all .5s ease-in-out;
  }
  
  
  .content-drawer--fade {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2.5em;
    opacity: 1;
    transition: all .5s ease-in-out;
  }
  
  .content-drawer--toggle {
    cursor: pointer;
  }

  &.expanded {
    .content-drawer--content {
      max-height: 50vh !important;
      overflow-y: scroll;
    }
    .content-drawer--fade {
      opacity: 0;
    }
  }
}
