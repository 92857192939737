.waveform--tool {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.zoom--icon {
		cursor: pointer;
		text-align: center;
	}

	p {
		font-size: 12px;
		font-weight: 600;
	}
}
