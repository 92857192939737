.color-picker-input {
	height: 2em;
	width: 4em;
	// border-radius: 50%;
	cursor: pointer;
	position: relative;
	border-radius: 4px;
	border: 1px solid $brand-primary;
	display: flex;
	justify-content: center;
	align-items: center;

	&:focus,
	&:active,
	&:hover {
		border-color: $brand-green;
	}
	#color-picker-no-color {
		width: 3px;
		height: inherit;
		transform: skew(299deg);
		background: grey;
		z-index: 0;
	}
}

.color-picker-popover-content {
	padding: 0;

	.chrome-picker {
		box-shadow: none;
	}
}
