.account-integrations-page {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1.875rem 0;

	.account-integrations-header-row {
		padding: 0 2.5rem 2.5rem;
		margin-bottom: 1.5rem;
		border-bottom: 1px solid $color-gray-16;

		h2 {
			display: flex;
			align-items: center;
			width: max-content;
			margin: 0;
			text-transform: capitalize;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.arrow-left {
			margin-right: 0.5rem;
			font-size: 1.125rem;
		}
	}

	.account-integrations-content {
		padding: 1.875rem 2.5rem;

		.integration {
			display: flex;
			column-gap: 2rem;

			.description {
				width: 490px;

				img {
					max-width: 165px;
					margin-bottom: 0.75rem;
				}

				p {
					font-size: 0.9375rem;
					font-weight: 400;
					line-height: 21.75px;
					letter-spacing: 0.16px;
				}
			}

			.actions {
				display: flex;
				flex: 1;
				flex-direction: column;
				padding: 1.375rem 0;
				background: $color-white;
				border: 1px solid $color-gray-17;
				border-radius: 6px;

				h5 {
					text-transform: uppercase;
				}

				.actions-help {
					padding: 0 1.375rem;

					p {
						font-size: 1rem;
						font-weight: 400;
						line-height: 21.28px;
						letter-spacing: 0.16px;
						color: $color-gray-18;
					}

					.disableCTA {
						cursor: none;
						pointer-events: none;
					}

					.saleforce-button {
						margin-right: 5px;
					}
				}

				.active-integrations {
					display: flex;
					flex-direction: column;
					row-gap: 1.5rem;
					padding: 1.375rem 1.375rem 0;
					margin-top: 1.375rem;
					border-top: 1px solid $color-gray-17;

					.active-integration {
						.integration-name {
							margin-bottom: 1rem;

							h5 {
								display: inline-block;
								margin: 0;
								text-transform: initial;
							}
						}
					}
				}
			}
		}
	}
}
