.audiogram-template {
	position: relative;
	overflow: hidden;
	margin-bottom: 1.5em;
	border: 2px solid $color-gray-4;
	border-radius: 5px;
	cursor: pointer;
	max-width: 100%;

	&.selected {
		border-color: $brand-green;
	}
	canvas {
		position: relative;
		max-width: 100%;
		z-index: 10;
	}
	.template-backdrop {
		position: absolute;
		top: -25%;
		left: -25%;
		width: 150%;
		height: 150%;
		background-repeat: no-repeat;
		background-position: center center;
		filter: blur(30px) brightness(0.6);
		z-index: 0;
	}
}

.triangle-badge {
	position: absolute;
	top: 0.25em;
	right: 1.15em;
	border-top: 1.5em solid #fff;
	border-right: 1.5em solid #fff;
	border-left: 1.5em solid transparent;
	border-bottom: 1.5em solid transparent;

	.video-icon {
		color: $brand-purple;
		position: absolute;
		top: -20px;
		right: -10;
	}
}

.audiogram-next-step {
	.btn {
		display: block;
		margin-left: auto;
	}
	.button {
		justify-content: flex-end;
		color: #000;
	}
}

.audiogram-creating-audiogram {
	.audiogram-progress {
		margin: 3em 0;
	}
	.progressbar--label {
		text-align: right;
		font-size: 0.9rem;
		margin-top: 1em;
		margin-right: 0.5em;
		color: $color-gray-6;
	}
}

.audiogram-actions {
	margin: 2em 0 1em;
	justify-content: space-between;
	align-items: center;
	.audiogram-download {
		display: block;
		margin-left: auto;
	}
	.audiogram-start-over {
		margin-left: -1.5em;
		color: #000;
	}
}

.audiogram-player {
	video,
	video:active {
		outline: none !important;
	}
}

.create-audiogram {
	.embed-type-toggle {
		padding-bottom: 40px;

		.btn {
			border-color: $color-gray-4;
			font-weight: 600;

			&.active {
				background: $brand-green !important;
				border-color: $color-gray-4;
			}
		}
	}
	.create-social-share {
		display: flex;
		justify-content: center;
	}
}
