.authorize-container {
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100vh;
	width: 100vw;
	box-sizing: border-box;

	> .left {
		display: flex;
		flex-direction: column;
		flex: 1;
		background-color: #01042b;

		> .authorize-left-content {
			display: flex;
			flex-direction: column;
			margin: 96px 120px;
			box-sizing: border-box;
			justify-content: center;
			flex: 1;

			.authorize-welcome-to-casted-section {
				display: flex;
				flex-direction: column;
				margin-bottom: 40px;

				.authorize-welcome-headline {
					img {
						display: block;
						margin: 0 auto;
						width: 100%;
						max-width: 350px;
						height: auto;
					}
				}
			}
		}
	}

	> .right {
		display: flex;
		flex: 1;
		flex-direction: column;
		padding: 75px 40px 10px 40px;

		.authorize--text {
			font-size: 16px;
			line-height: 1.5;
			margin-top: 6px;
			color: $color-gray-7;
			overflow-y: hidden;
			text-overflow: ellipsis;
		}

		.authorize--account-list {
			margin-top: 10px;
		}
	}
}

.account-list--account {
	.account-row {
		padding: 1em 0;
		margin: 0 1px 1em;
		background: $color-white;
		border: 1px solid $color-gray-2;
		border-radius: 4px;
		align-items: center;
		justify-content: space-between;
	}

	.account-list--name {
		padding-left: 1.5em;
	}

	.account-list--action {
		text-align: right;
		padding-right: 1.5em;
	}
}
