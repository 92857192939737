.account-navigation {
	margin-top: auto;
	padding: 1em 0.5em;
	border-top: 1px solid $color-gray-4;
	cursor: pointer;
	&:hover {
		background: $color-off-white;
	}

	.logged-in-user-info {
		margin-left: 0.5em;
		p {
			margin: 0;
		}
	}

	.account-navigation--icon {
		color: $color-gray-6;
	}
}

.account-nav.popover {
	width: 92%;
	border-radius: 0;
	border-color: $color-gray-4;
	box-shadow: 0 0 2px $color-gray-4;

	.popover-body {
		padding: 0;
	}

	.account-nav--label {
		margin: 0.75rem 1rem;
		font-size: 1rem;
	}

	.accounts-nav--list {
		max-height: 250px;
		overflow-y: scroll;
	}

	.account-nav--logout {
		padding: 0.5em 0;
		border-top: 1px solid $color-gray-4;
	}

	.account-nav--users {
		@extend .account-nav--logout;
		border-top: 0px;
	}

	.account-nav--settings {
		padding-top: 1em;
		border-top: 1px solid $color-gray-4;
	}

	.nav-link {
		font-weight: 600;
		text-transform: uppercase;
		border-left: 6px solid transparent;
		color: $brand-primary;
		text-align: left;
		&.selected {
			border-color: $brand-purple;
			background: rgba($brand-purple, 0.2);
			// color: $brand-purple;
		}
	}
}
