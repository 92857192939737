$thumbnail-size: 162px;

.thumbnail-uploader {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	height: $thumbnail-size;
	width: $thumbnail-size;
	border: 1px solid $color-gray-2;
	padding: 0.25rem;
	box-sizing: border-box;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		box-shadow: $box-1;
	}

	.thumbnail-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		margin: 0;
		background-color: $brand-background;
		box-sizing: border-box;

		&.dragging {
			box-shadow: $box-1;
		}

		input.thumbnail-upload-input {
			display: none;
		}

		&:focus {
			outline: none;
		}

		> img.thumbnail-image {
			width: 100%;
			max-width: 386px;
			object-fit: cover;
			cursor: pointer;
		}

		.thumbnail-edit-overlay {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			z-index: 1;
			cursor: pointer;
			text-align: center;
			font-weight: 700;
			font-size: 13px;
			line-height: 17px;

			.overlay-msg {
				min-width: 75px;
				padding: 6px 14px;
				border: 1px solid $brand-primary;
				border-radius: 3px;
				color: $brand-primary;
				transition: background-color 0.1s ease, color 0.15s ease;

				&:hover {
					background-color: $brand-primary;
					color: $color-white;
				}
			}
		}
	}
}
