.upload-wizard-modal {
	.modal-content {
		padding: 0 !important;

		.modal-header {
			margin: 0 !important;
		}
	}

	.upload-wizard-container {
		&.youtube-setup {
			padding: 0;
		}

		.label {
			font-size: 0.875rem;
			text-transform: uppercase;
			letter-spacing: 0.08125rem;
			font-weight: 700;
		}

		.youtube-checkbox {
			display: flex;
		}

		.upload-limit-container {
			padding-bottom: 0.5em;
		}

	}
}
