.checkbox {
	margin-right: 11px;
	margin-top: 3px;

	> input {
		height: 17px;
		width: 17px;
	}
}

.rc-checkbox-disabled > .rc-checkbox-input {
	cursor: not-allowed !important;
}

.rc-checkbox-inner {
	border: 1px solid $color-gray-6;
	border-radius: 3px;
	height: 16px;
	width: 16px;

	&::after {
		top: 1px;
		left: 5px;
	}
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
	border-color: $brand-green;
}

.rc-checkbox-checked .rc-checkbox-inner {
	border-color: $brand-green;
	background-color: $brand-green;

	&::after {
		top: 1px;
		left: 5px;
	}
}

.rc-checkbox-input {
	background-color: $color-gray-5;
}
