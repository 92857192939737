@import '../shows/episodes/episodesList/episodeListRow/episodeListRow';

.user-list--user {
	@extend .episode-list--episode;
	.user--row {
		@extend .episode--row;

		.user-list--avatar-thumbnail {
			border-radius: 999px;
			height: 70px;
			width: 70px;
			background-position: center;
			background-size: cover;

			&.initials {
				background-color: $color-gray-2;
			}
		}

		.expired {
			color: $brand-red;
		}

		.dropdown-toggle {
			cursor: pointer;
			&::after {
				display: none;
			}
		}

		#user-actions {
			border: 1px solid $brand-primary;
			border-radius: 999px;
			padding: 2px 4px 3px;
		}
	}
}
