.loading-bar {
  width: 100%;
  height: 14px;
  background-color: #f2f4f5;
  border-radius: 7px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .progress {
    background: $brand-purple;
    background-image: linear-gradient(-45deg, $brand-purple, darken($brand-purple,15%));
    background-size: 300% 100%; 
    animation-name: loadingBar;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-radius: 7px;
    transition: all .2s;
    min-width: 10%;

    .percentage {
      font-size: 9px;
      font-weight: bold;
      letter-spacing: .99px;
      color: $color-white;
      margin-right: 8px;
    }
  }
}

@keyframes loadingBar {
  from {
    background-position: 100% 0;
  }

  to {
    background-position: 0% 0; 
  }
}