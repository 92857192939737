.app {
	display: flex;
	height: 100%;
	background-color: $brand-background;
	color: $brand-primary;
	flex-direction: column;

	.app-container {
		display: flex;
		flex-direction: column;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		z-index: 0;
		position: fixed;
		overflow: hidden;

		main.app-main {
			display: flex;
			flex-direction: row;
			width: 100%;
			height: 100%;

			.app-content {
				display: flex;
				flex: 1;
				height: 100%;
				flex-direction: column;
				position: relative;
			}

			.app-page-content {
				overflow: scroll;
				height: 100%;
				max-width: calc(100vw - 230px);
			}

			.search-help-content {
				position: relative;
				box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.2);
				z-index: 10;

				a {
					position: absolute;
					z-index: 10;
					top: 50%;
					right: 1.25rem;
					transform: translateY(-50%);
					font-size: 1rem;
				}
			}
		}
	}

	%toast-msg {
		padding: 1.25rem;
		color: $color-white;
		border-radius: 0.4375rem;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
		cursor: default;

		p {
			margin: 0 0 0.5rem 1.5rem;
			margin-left: 1.5rem;
			font-size: 1rem;
			letter-spacing: 0;
			line-height: 1.5rem;

			&:first-child {
				display: flex;
				align-items: center;
				margin-left: 0;
				font-weight: bold;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.fa-icon {
				margin-right: 0.5rem;
			}

			a {
				font-size: 1rem;
				text-decoration: underline;
				color: $color-white;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.toastify {
		z-index: $toast-z-index;

		.Toastify__toast-icon {
			display: none;
		}
	}

	.Toastify__toast {
		@extend %toast-msg;

		&.toast-success {
			background-color: $brand-green;
		}

		&.toast-error {
			background-color: $brand-red;
		}

		&.toast-warning {
			background-color: $brand-yellow;
		}

		&.toast-info {
			background-color: $brand-primary;
		}
	}
}
