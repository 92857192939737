.clip-suggestions-drawer {
	width: 100% !important;
	padding: 0 20px;

	.suggested-clips-header-container {
		display: flex;

		.suggested-clips-header {
			display: flex;
			justify-content: space-between;
			padding-left: 8px;
			font-size: 20px;
			font-weight: 600;
			width: 100%;

			.header-controls {
				font-size: 18px;
				font-weight: normal;

				.chevron {
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.empty-state {
		color: #000;
		padding-top: 20px;

		.title {
			font-weight: 700;
		}

		p {
			padding-top: 4px;
		}
	}
}

.loading-clip-suggestions {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
