.show-settings {
	.show-settings--container {
		position: relative;
	}

	.show-settings--header {
		position: sticky;
		top: 0;
		z-index: 99;
		padding: 1.5em 0;
		margin-bottom: 2em;
		background: $brand-background-blue;

		.show-header {
			justify-content: space-between;
			align-items: center;
		}

		.show-nav--title {
			margin: 0;
			color: $color-white;
			text-transform: uppercase;
		}

		.show-nav--actions {
			.btn-toolbar {
				justify-content: flex-end;
			}
			.btn {
				padding-left: 2em;
				padding-right: 2em;
				margin-left: 1em;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 0.9rem;
			}
		}
	}

	.nav-link {
		padding: 0.5em 0;
		margin: 0 1em;

		p {
			opacity: 0.5;
		}

		&:hover {
			p {
				opacity: 0.9;
			}
		}

		&:focus {
			outline: none;
		}

		&.active {
			border-bottom: 2px solid $color-white;

			p {
				opacity: 1;
			}
		}
	}

	.form-label {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 700;
		color: $brand-primary;
		letter-spacing: 1px;
	}

	.youtube-auto-publish {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.form-text {
		font-size: 12px;
		color: #7c8a95;
	}

	form {
		p {
			@extend .form-text;
		}

		input[type='text'] {
			height: 50px;
		}

		textarea {
			resize: none;
		}

		.input-group {
			flex-direction: row;
		}
	}

	.color-picker--title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
		h5 {
			margin: 0%;
		}
	}

	.color-picker-input-group {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1em;
		padding-bottom: 1em;
		border-bottom: 1px solid $color-gray-2;
		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.ctrl-label {
			font-size: 1rem;
			color: $color-gray-7;
			margin-bottom: 0;
		}
	}

	.show-settings--section {
		padding-bottom: 2em;
		margin-bottom: 2em;
		border-bottom: 1px solid $color-gray-4;
		&:last-child {
			border-bottom: none;
		}

		.settings-section--title {
			margin-bottom: 1em;
			text-transform: capitalize;
		}

		.new-show-details-help-text {
			margin: 0 20px 5px 0;
			font-size: 12px;
			color: $color-gray-6;
			text-align: center;
		}

		.resource {
			input {
				padding: 0.375rem 0.75rem;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5rem;
			}
		}
	}

	.show-settings--logo-upload {
		height: auto;
		width: auto;
		max-width: 100%;
		margin-right: 0;
		.thumbnail-container,
		.thumbnail-edit-overlay {
			width: 100%;
			height: 100px;
			img {
				width: auto;
				height: auto;
				max-height: 100px;
			}
		}
	}

	.show-settings--artwork-upload {
		height: auto;
		width: auto;
		max-width: 100%;
		margin-right: 0;
		.thumbnail-container,
		.thumbnail-edit-overlay {
			width: 100%;
			height: 200px;
			img {
				width: fit-content;
				max-width: 400px;
			}
		}

		.thumbnail-container {
			.thumbnail-image {
				width: auto;
			}
		}
	}

	.mocked-logo {
		max-height: 100px;
	}

	.show-settings--description {
		height: 300px;
	}

	.host-photo-upload {
		align-items: flex-start;
		padding: 4px;
		border: 1px solid $color-gray-2;
		border-radius: 2px;
		width: 190px;
		height: 190px;

		.thumbnail-container {
			margin: 0;
			width: 180px;
			height: 180px;
		}
	}

	.host-section {
		padding-left: 30px;
	}

	.show-settings--custom-tags {
		code {
			color: $brand-purple;
		}

		.ace_editor {
			@extend .form-control;
		}
	}

	#tooltip-drift-help {
		cursor: pointer;
		text-transform: unset;
		font-size: inherit;
	}

	#tooltip-custom-domain-help {
		cursor: pointer;
		text-transform: unset;
		font-size: inherit;
	}

	#tooltip-youtube-help {
		cursor: pointer;
		text-transform: unset;
		font-size: inherit;
	}

	#tooltip-hubspot-help {
		cursor: pointer;
		text-transform: unset;
		font-size: inherit;
	}

	.show-settings--checkbox {
		font-size: 14px;
		margin-top: 0.5em;
	}
}
