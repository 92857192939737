.show-detail-header {
	margin-top: 2em;

	.show-detail {
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 60px;

		.show-details--thumbnail {
			width: 100%;
			max-width: 175px !important;
			height: auto;
			border-radius: 4px;
			object-fit: cover;
			margin-right: 30px;
		}

		.show-details--info {
			.show-name {
				margin-bottom: 8px;
				color: $color-gray-8;
			}

			.show-episode-count {
				color: $color-gray-7;
				margin-top: 0;
				margin-bottom: 1em;
				text-transform: capitalize;
			}

			.show-description {
				font-size: 16px;
				line-height: 1.5;
				margin-top: 6px;
				color: $color-gray-7;
				overflow-y: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.show-detail--actions {
		align-items: center;
		justify-content: space-between;
		.show-actions--header {
			justify-content: flex-start;
			margin: 0;
			text-transform: uppercase;
		}

		.collection-buttons {
			display: flex;
			justify-content: flex-end;

			.collection-share {
				padding-right: 12px;
			}
		}

		.show-actions--btn-container {
			justify-content: flex-end;

			.show-actions--filter {
				margin-right: 0.75rem;
			}

			.show-actions--sync {
				.option {
					margin-bottom: 0.25rem;
					font-size: 14px;
				}
				.helper-text {
					font-size: 0.75rem;
					font-weight: normal;
					text-transform: none;
				}
			}
			.btn {
				min-width: 5em;
				min-height: 2.375rem;
				font-size: 0.75rem;
				font-weight: 800;
				letter-spacing: 0.075rem;
				line-height: 1.125rem;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}
}
