.tag-list-input {
	textarea {
		resize: none;
		width: 100%;
		min-height: 70px;
		margin-bottom: 0.5625rem;
		padding: 0.5625rem 0.75rem;
		border: 1px solid #ced4da;
		border-radius: 4px;
		font-size: 0.875rem;
		letter-spacing: 0;
		line-height: 1.125rem;
		color: $color-gray-12;
		box-sizing: border-box;

		&::placeholder {
			line-height: 1.125rem;
		}
	}

	.tag-list {
		display: flex;
		flex-wrap: wrap;
		row-gap: 0.25rem;
		column-gap: 0.25rem;

		span {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.1875rem 0.5rem;
			background: $color-purple;
			border-radius: 3.3px;
			cursor: pointer;

			svg {
				margin-left: 0.5rem;
				font-size: 0.5rem;
			}
		}
	}
}
