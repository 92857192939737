.promotions-list-container {
	display: flex;
	flex-direction: column;
	padding: 2rem;

	h3 {
		margin-bottom: 2rem;
	}

	.promotions-body {
		
		.promotions-list {
			.promotion-card {
				margin-bottom: 3.25rem;
			}
		}

		.promotions-list-empty {

			.empty-podcat {
				height: 130px;
			}

			p {
				margin: 0;
			}

			.add-promotion {
				justify-content: flex-start;
				padding: 1em 0 0 0;
				margin: 0;
			}
		}

		.add-promotion {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 3.25rem;
			padding: 1rem;
			font-size: 1.125rem;
			font-weight: 700;
			line-height: 1.125rem;
			letter-spacing: 1.2px;
			color: $color-blue-1;
			cursor: pointer;
			text-transform: uppercase;
		}
	}

	.promotion-loading-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}