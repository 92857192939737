$avatar-img-size: 82px;

.avatar-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  position: relative;

  .avatar-img-container {
    border-radius: 11px;
    display: flex;
    flex: 0 0 $avatar-img-size;
    height: $avatar-img-size;
    width: $avatar-img-size;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 0;
    box-sizing: border-box;
    border: 1px solid $color-gray-2;
    object-fit: cover;

    input.avatar-editor-file-upload-input {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }

    > img.avatar-image {
      height: $avatar-img-size;
      width: $avatar-img-size;
      border-radius: 10px;
      object-fit: cover;
    }

    span.avatar-upload-initials {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: $avatar-img-size;
      width: $avatar-img-size;
      border-radius: 10px;
      background-color: $brand-primary;
      color: $color-gray-2;
      font-size: 24px;
    }

    .avatar-img-edit-overlay {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.25s;
      z-index: 1;
      background-color: $brand-background;
      cursor: pointer;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      font-weight: 700;

      &.drag-active, &:hover {
        opacity: 0.7;
        color: $brand-primary;
      }
    }
  }
}