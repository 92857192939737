.metric-line-chart.card {
	position: relative;

	.chart-header {
		padding-bottom: 0;

		.chart-title {
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 1px;
			font-weight: 600;
			padding: 20px 20px 0px;
		}

		.display-by-dropdown {
			text-align: right;

			.dropdown-toggle {
				@extend .chart-title;
			}
		}
	}

	.chart-container {
		width: 100%;
		height: 400px;
		padding: 1.5em 0;
	}

	.recharts-surface {
		margin-left: -0.75em;
	}

	.timeline-tooltip {
		background: $white;
		padding: 0.5rem 0;
		box-shadow: 0 3px 2px rgba(black, 0.15);
		border-radius: 3px;
		border: 1px solid $color-gray-2;
		color: $color-gray-7;

		.tooltip-stat {
			color: $color-gray-7;
			.label {
				margin-right: 0.25em;
			}
			.value {
				color: $brand-purple;
			}
		}

		p {
			margin: 0;
			padding: 0 0.5rem;
		}

		ul {
			margin: 3px 0 0;
			padding: 0 1rem;
			li {
				font-size: 0.9rem;
			}
		}

		hr {
			margin: 0.5rem 0;
			border-color: $color-gray-4;
			border-style: solid;
		}
	}

	// Graph
	.recharts-cartesian-axis-tick {
		text {
			fill: #9aa1a9;
			stroke-width: 0;
			font-size: 0.7rem;
		}
	}
	.recharts-curve.recharts-area-area {
		fill-opacity: 0.5;
	}

	// Override chart legend
	.recharts-legend-wrapper {
		padding-left: 2em;
	}
	.recharts-legend-item {
		margin-right: 2em !important;
	}
}
