.dot-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .loading-dot {
    height: 10px;
    width: 10px;
    background-color: $color-white;
    border-radius: 50%;
    margin-right: 5px;
    animation: dot-loader 1.5s linear 0s infinite;

    &:nth-child(1) {
      animation-delay: .1s;
    }

    &:nth-child(2) {
      animation-delay: .3s;
    }

    &:nth-child(3) {
      animation-delay: .5s;
    }
  }
}

@keyframes dot-loader {
  15% {
    transform: translateY(-10px);
  }

  45% {
    transform: translateY(0);
  }
}