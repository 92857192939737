._35S67 {
  display: flex;
  justify-content: center;
  padding: 0.5em;
  text-align: center;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  z-index: 10;
}
._35S67 ._3ckq6 {
  max-width: 600px;
  width: 100%;
  padding: 0.25em;
  background: #f2f4f5;
  border-radius: 3px;
  font-family: proxima-nova, "Montserrat", sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  color: #495057;
  cursor: pointer;
}
._35S67 ._3ckq6 ._3CGl9 {
  margin-right: 0.5rem;
}
._WPOG6 {
  display: flex;
  align-items: center;
}
._WPOG6 ._KCNIv {
  margin-right: 0.5rem;
  font-size: 1.125rem;
  cursor: pointer;
}
._WPOG6 ._KCNIv._30_QM {
  color: #adb5bd;
}
._WPOG6 ._KCNIv._YdAyj {
  color: #3ebb85;
}
._WPOG6 > input[type=checkbox] {
  display: none;
}
._WPOG6 ._10LtG {
  margin: 0;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.1875rem;
  color: #212529;
  cursor: pointer;
}
._xA-mE {
  margin-bottom: 1.25rem;
  padding: 0;
  border: none;
}
._xA-mE h3 {
  margin: 0 0 0.625rem;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.085625rem;
  line-height: 1.0625rem;
  color: #031c33;
  text-transform: uppercase;
}
._xA-mE ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
._xA-mE ul li {
  display: flex;
  margin-bottom: 0.6875rem;
}
._xA-mE ul li:last-child {
  margin-bottom: 0;
}
._xA-mE ._3hGNQ {
  margin-top: 0.6875rem;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.125rem;
  color: #007bff;
  cursor: pointer;
}
._xA-mE ._3hGNQ._wE_0d {
  display: none;
}
._1zz1O h3 {
  margin: 0 0 0.625rem;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.085625rem;
  line-height: 1.0625rem;
  color: #031c33;
  text-transform: uppercase;
}
._1zz1O ._vOuXw {
  position: relative;
}
._1zz1O ._vOuXw ._OfdMR {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5625rem;
  padding: 0.5625rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 0.75rem;
  color: #6c757d;
  box-sizing: border-box;
  cursor: pointer;
}
._1zz1O ._vOuXw ._3Odc3 {
  position: relative;
  top: -42px;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  z-index: 1;
}
._1zz1O ._vOuXw ._3Odc3._3ZMWF {
  display: none;
}
._1zz1O ._vOuXw ._3Odc3 li {
  padding: 0.5625rem 0.75rem;
  border-bottom: 1px solid #ced4da;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 0.75rem;
  background: #fff;
  cursor: pointer;
}
._1zz1O ._vOuXw ._3Odc3 li:last-child {
  border: none;
}
._1zz1O ._vOuXw ._3Odc3 li:hover {
  background: #ced4da;
}
._2FPG1 fieldset {
  padding: 0;
  border: none;
}
._2FPG1 h3 {
  margin: 0 0 0.625rem;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.085625rem;
  line-height: 1.0625rem;
  color: #031c33;
  text-transform: uppercase;
}
._2FPG1 input {
  width: 100%;
  margin-bottom: 0.5625rem;
  padding: 0.5625rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 0.75rem;
  color: #6c757d;
  box-sizing: border-box;
}
._2FPG1 ._1L8Rn {
  display: flex;
  flex-wrap: wrap;
}
._2FPG1 ._1L8Rn span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 105px;
  margin: 0 0.125rem 0.25rem 0;
  padding: 0.1875rem 0.5rem;
  background: #ced5ec;
  border-radius: 3.3px;
  cursor: pointer;
}
._2FPG1 ._1L8Rn span svg {
  margin-left: 0.5rem;
  font-size: 0.5rem;
}
._iT-vm fieldset {
  margin-bottom: 0.6875rem;
  padding: 0;
  border: none;
}
._iT-vm fieldset h3 {
  margin: 0 0 0.625rem;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.085625rem;
  line-height: 1.0625rem;
  color: #031c33;
  text-transform: uppercase;
}
._iT-vm fieldset ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
._iT-vm fieldset ul li {
  display: flex;
  margin-bottom: 0.6875rem;
}
._iT-vm fieldset ul li:last-child {
  margin-bottom: 0;
}
._1IOgS fieldset {
  margin-bottom: 0.6875rem;
  padding: 0;
  border: none;
}
._1IOgS fieldset h3 {
  margin: 0 0 0.625rem;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.085625rem;
  line-height: 1.0625rem;
  color: #031c33;
  text-transform: uppercase;
}
._1IOgS fieldset ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
._1IOgS fieldset ul li {
  display: flex;
  margin-bottom: 0.6875rem;
}
._1IOgS fieldset ul li:last-child {
  margin-bottom: 0;
}
._g1eeD fieldset {
  padding: 0;
  border: none;
}
._g1eeD fieldset h3 {
  margin: 0 0 0.625rem;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.085625rem;
  line-height: 1.0625rem;
  color: #031c33;
  text-transform: uppercase;
}
._2JTcV {
  display: flex;
  margin: 1rem 0 1.125rem;
}
._2JTcV ._1FAAw {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  width: 100%;
}
._2JTcV ._1FAAw._30mUY {
  justify-content: left;
}
._2JTcV ._1FAAw._28KRO {
  justify-content: center;
}
._2JTcV ._1FAAw._3tlRs {
  justify-content: right;
}
._2JTcV ._1FAAw ._2hF2G {
  background: #e4e8ed;
  margin-right: 0.3125rem;
  padding: 0.125rem 0.375rem;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.1875rem;
  cursor: pointer;
}
._2JTcV ._1FAAw ._2hF2G:last-child {
  margin-right: 0;
}
._2JTcV ._1FAAw ._2hF2G._GbCMy {
  background: #7769e9;
  color: #fff;
}
._2JTcV ._1FAAw ._2hF2G._1gI7M {
  background: none;
  color: #526173;
  cursor: default;
}
._2xRF3 fieldset {
  padding: 0;
  border: none;
}
._2xRF3 h3 {
  margin: 0 0 0.625rem;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.085625rem;
  line-height: 1.0625rem;
  color: #031c33;
  text-transform: uppercase;
}
._2xRF3 ._3tDqj {
  padding: 0.25rem 0.625rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
  color: #fff;
  border: none;
  border-radius: 9px;
  cursor: pointer;
}
._2xRF3 ._3tDqj svg {
  margin-left: 0.625rem;
}
._1CfNd fieldset {
  margin-bottom: 0.6875rem;
  padding: 0;
  border: none;
}
._1CfNd fieldset hr {
  color: #d9d9d9;
}
._1CfNd fieldset ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
._1CfNd fieldset ul li {
  display: flex;
  margin-bottom: 0.6875rem;
}
._1CfNd fieldset ul li:last-child {
  margin-bottom: 0;
}
._1_zQ0 {
  margin-bottom: 1.25rem;
  padding: 0;
  border: none;
}
._1_zQ0 h3 {
  margin: 0 0 0.625rem;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.085625rem;
  line-height: 1.0625rem;
  color: #031c33;
  text-transform: uppercase;
}
._1_zQ0 ._GZtdp {
  position: relative;
}
._1_zQ0 ._GZtdp ._34A-D {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5625rem;
  padding: 0.5625rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 0.75rem;
  color: #6c757d;
  box-sizing: border-box;
  cursor: pointer;
}
._1_zQ0 ._GZtdp ._VRjCp {
  position: relative;
  top: -42px;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  z-index: 1;
}
._1_zQ0 ._GZtdp ._VRjCp._2D7EZ {
  display: none;
}
._1_zQ0 ._GZtdp ._VRjCp li {
  padding: 0.5625rem 0.75rem;
  border-bottom: 1px solid #ced4da;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 0.75rem;
  background: #fff;
  cursor: pointer;
}
._1_zQ0 ._GZtdp ._VRjCp li:last-child {
  border: none;
}
._1_zQ0 ._GZtdp ._VRjCp li:hover {
  background: #ced4da;
}
._2Jxpq {
  display: flex;
  flex-direction: column;
  font-family: proxima-nova, "Montserrat", sans-serif;
}
._2Jxpq ._26p71 {
  display: flex;
}
._2Jxpq ._26p71 ._3btOZ {
  width: 100%;
  padding: 0.5em;
  background: #f2f4f5;
  border: none;
  border-radius: 3px;
  font-family: proxima-nova, "Montserrat", sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  color: #495057;
  box-sizing: border-box;
}
._2Jxpq ._26p71 ._3805M {
  padding: 0 0.5rem;
  background: #f2f4f5;
  border: none;
  cursor: pointer;
}
._2Jxpq ._26p71 ._1fTi4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
  font-size: 1.5rem;
  background: #f2f4f5;
  cursor: pointer;
}
._2Jxpq ._1Y6gP {
  display: flex;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  border-radius: 0.1875rem;
  background: #fff;
}
._2Jxpq ._1Y6gP ._1LsMf {
  flex-grow: 1;
  max-height: 100%;
  overflow-y: scroll;
  padding: 0 1rem 1rem;
  background: #f8fafb;
}
._2Jxpq ._1Y6gP ._1LsMf ._2nmvE {
  padding-bottom: 1rem;
}
._2Jxpq ._1Y6gP ._1LsMf ._E3Hjs {
  font-weight: normal;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.1875rem;
  color: #526173;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN {
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem 1rem;
  background: #fff;
  border: 1px solid #ebecee;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN:hover {
  border: 1px solid #7769e9;
  cursor: pointer;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN:hover ._3ZhrQ div span {
  display: inline-block;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN:hover ._3ZhrQ svg {
  display: inline-block;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN ._3ZhrQ {
  display: flex;
  justify-content: space-between;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN ._3ZhrQ div {
  display: flex;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN ._3ZhrQ div ._2lfAc {
  margin: 0 0 0.5rem;
  padding: 0;
  font-size: 0.625rem;
  letter-spacing: 0;
  line-height: 0.875rem;
  color: #6c757d;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN ._3ZhrQ div span {
  display: none;
  margin-left: 0.5rem;
  font-size: 0.625rem;
  letter-spacing: 0;
  line-height: 0.875rem;
  color: #007bff;
  text-decoration: none;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN ._3ZhrQ svg {
  display: none;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.0625rem;
  color: #7769e9;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j {
  display: flex;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j > div {
  display: flex;
  flex-direction: column;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j > div._2bjYO {
  margin-right: 0.625rem;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j > div._2bjYO img {
  width: 41px;
  height: 41px;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j > div ._3GJKV {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.1875rem;
  color: #37373c;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j > div ._3GJKV em {
  background-color: rgba(119, 105, 233, 0.3);
  font-style: normal;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j > div ._1KB1D {
  font-size: 0.8rem;
  letter-spacing: 0.018125rem;
  line-height: 1.125rem;
  color: #868e96;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j > div ._1kO2B {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.3125rem;
  color: #343a40;
}
._2Jxpq ._1Y6gP ._1LsMf ._2OkSp ._2UqWN .__Wb8j > div ._1kO2B em {
  background-color: rgba(119, 105, 233, 0.3);
  font-style: normal;
}
._2Jxpq ._1Y6gP ._2Ti_R {
  position: relative;
  overflow-y: scroll;
  min-width: 289px;
  padding: 1.25rem;
  background: #fff;
  box-sizing: border-box;
}
._2Jxpq ._1Y6gP ._2Ti_R ._3GtL9 {
  position: initial;
}
._2Jxpq ._1Y6gP ._2Ti_R ._3GtL9 > h4 {
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.0625rem;
  color: #6c757d;
}
._2Jxpq ._1Y6gP ._2Ti_R ._3GtL9 > div {
  margin-bottom: 1.25rem;
}
._KS9CD {
  display: flex;
}
._KS9CD ._1zzil {
  width: 100%;
  padding: 0.5em;
  background: #f2f4f5;
  border: none;
  border-radius: 3px;
  font-family: proxima-nova, "Montserrat", sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  color: #495057;
  box-sizing: border-box;
}
._KS9CD ._BeshK {
  padding: 0 0.5rem;
  background: #f2f4f5;
  border: none;
  cursor: pointer;
}
._KS9CD ._1J9_6 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
  font-size: 1.5rem;
  background: #f2f4f5;
  cursor: pointer;
}
._1L4-- {
  flex-grow: 1;
  max-height: 100%;
  overflow-y: scroll;
  padding: 0 1rem 1rem;
  background: #f8fafb;
}
._1L4-- ._3ameM {
  padding-bottom: 1rem;
}
._1L4-- ._2ubZs {
  font-weight: normal;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.1875rem;
  color: #526173;
}
._1L4-- ._2l4Dg ._SgOmC {
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem 1rem;
  background: #fff;
  border: 1px solid #ebecee;
}
._1L4-- ._2l4Dg ._SgOmC:hover {
  border: 1px solid #7769e9;
  cursor: pointer;
}
._1L4-- ._2l4Dg ._SgOmC:hover ._EOrTX div span {
  display: inline-block;
}
._1L4-- ._2l4Dg ._SgOmC:hover ._EOrTX svg {
  display: inline-block;
}
._1L4-- ._2l4Dg ._SgOmC ._EOrTX {
  display: flex;
  justify-content: space-between;
}
._1L4-- ._2l4Dg ._SgOmC ._EOrTX div {
  display: flex;
}
._1L4-- ._2l4Dg ._SgOmC ._EOrTX div ._3weWa {
  margin: 0 0 0.5rem;
  padding: 0;
  font-size: 0.625rem;
  letter-spacing: 0;
  line-height: 0.875rem;
  color: #6c757d;
}
._1L4-- ._2l4Dg ._SgOmC ._EOrTX div span {
  display: none;
  margin-left: 0.5rem;
  font-size: 0.625rem;
  letter-spacing: 0;
  line-height: 0.875rem;
  color: #007bff;
  text-decoration: none;
}
._1L4-- ._2l4Dg ._SgOmC ._EOrTX svg {
  display: none;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.0625rem;
  color: #7769e9;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox {
  display: flex;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox > div {
  display: flex;
  flex-direction: column;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox > div._3RgAi {
  margin-right: 0.625rem;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox > div._3RgAi img {
  width: 41px;
  height: 41px;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox > div ._36dla {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.1875rem;
  color: #37373c;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox > div ._36dla em {
  background-color: rgba(119, 105, 233, 0.3);
  font-style: normal;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox > div ._2NVVa {
  font-size: 0.8rem;
  letter-spacing: 0.018125rem;
  line-height: 1.125rem;
  color: #868e96;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox > div ._1RmGk {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.3125rem;
  color: #343a40;
}
._1L4-- ._2l4Dg ._SgOmC ._2Ymox > div ._1RmGk em {
  background-color: rgba(119, 105, 233, 0.3);
  font-style: normal;
}
._ePFe0 {
  height: 100%;
  position: relative;
  overflow-y: scroll;
  min-width: 289px;
  padding: 1.25rem;
  background: #fff;
  box-sizing: border-box;
}
._ePFe0 ._2Owd8 {
  position: initial;
}
._ePFe0 ._2Owd8 > h4 {
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.0625rem;
  color: #6c757d;
}
._ePFe0 ._2Owd8 > div {
  margin-bottom: 1.25rem;
}