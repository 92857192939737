.theme-card-container {
	display: flex;
	flex-direction: column;
	width: 300px;
	min-height: 160px;
	border: 1px solid $color-gray-2;
	background-color: $color-white;
	padding: 1rem 1.125rem;

	&:hover {
		.theme-card-footer {
			.view-theme {
				display: inline-block;
			}
		}
	}

	.theme-card-header {
		display: flex;

		.color-dot {
			flex-shrink: 0;
			position: relative;
			top: 7px;
			width: 8px;
			height: 8px;
			margin-right: 0.5rem;
			border-radius: 100%;
			background-color: $color-gray-9;
		}

		h5 {
			flex-grow: 1;
			margin-right: 0.5rem;
		}

		.ellipsis-icon {
			color: $color-gray-6;
			font-size: 1.25rem;
			cursor: pointer;
		}

		.dropdown-toggle {
			&:after {
				display: none;
			}
		}
	}

	.theme-card-keywords {
		font-size: 0.75rem;
		font-weight: 600;
		margin-bottom: 0.75rem;

		.theme-card-words {
			font-weight: normal;
		}
	}

	.theme-card-footer {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: flex-end;
		min-height: 1.3125rem;

		.view-theme {
			display: none;
			padding: 0;
		}
	}
}
