.segment-value {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .default {
    color: $color-gray-6;
  }

  .segment-carrot {
    margin-left: 8px;

    &.default {
      color: $color-gray-6
    }
  }
}

.segments-dropdown {
  .casted-dropdown-button {
    box-sizing: border-box;
  
    .dropdown-menu {
      max-width: 258px;
      min-width: 258px;
      padding: 0;
    
      .dropdown-item {
        padding: 0;
      }
    
      a {
        white-space: normal;
      }
    
      h6 {
        font-weight: 700;
      }

      .segment-menu {
        max-height: 400px;

        .segment-menu-items {
          max-height: 300px;
          overflow: auto;
        }

        .dropdown-header {
          padding: 14px 20px 18px 20px;
          color: $brand-font-color;
          font-weight: 600;
        }

        .segment-menu-groups {
          display: flex;
          padding: 0px 20px;

          .remove {
            margin-left: 12px;
            font-size: 14px;
            cursor: pointer;
          }
        }

      }

      .segment-button {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 20px 20px 14px 20px;
        background-color: $color-gray-1;

        .button-item {
          display: flex;
          justify-content: center;
          
          &:hover {
            background: none;
          }
        }

        .button {
          width: 60%;
          height: 38px;
        }
      }

    }		
  }
}
