.password-strength-container {
	margin: 0px 16px 16px;
	.password-strengh-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 4px;

		.pw-strength-bar {
			height: 4px;
			border-radius: 2px;
			background-color: $color-gray-6;
			width: 60px;
			margin-top: 2px;

			&.pw-too-weak {
				background-color: $brand-red;
				margin-right: 3px;
			}

			&.pw-could-be-stronger {
				margin-right: 3px;

				&.yellow {
					background-color: $brand-yellow;
				}
			}

			&.pw-very-strong {
				&.green {
					background-color: $brand-green;
				}
			}
		}

		.pw-strength-msg {
			font-size: 14px;
			opacity: 0.4;
			margin-left: 6px;
		}
	}

	.pw-warning-msg {
		font-size: 16px;
		opacity: 0.6;
		padding-left: 16px;

		.warning-icon {
			margin-right: 0.75rem;
		}
	}
}
