.theme-drawer-card {
	border-bottom: 1px solid $color-gray-10;

	&:first-child {
		border-top: 1px solid $color-gray-10;
	}

	a {
		text-decoration: none;
	}

	.theme-link {
		position: relative;
		padding: 1.3125rem 1rem 0;
		cursor: pointer;

		&:hover {
			.link-text {
				display: block;
			}
		}

		.link-text {
			display: none;
			position: absolute;
			top: 0.375rem;
			font-size: 0.625rem;
			line-height: 0.9375rem;
			font-weight: 400;
			color: $color-blue;
		}

		.theme-color {
			width: 0.5rem;
			margin-right: 0.5rem;
		}

		.theme-name {
			font-size: 0.75rem;
			font-weight: 700;
			line-height: 1.4375rem;
			letter-spacing: 0.84px;
		}
	}

	.matched-excerpt {
		position: relative;
		padding: 0 1rem 1.3125rem;
		font-size: 0.875rem;
		line-height: 1.3125rem;
		cursor: pointer;

		&:hover {
			.link-text {
				display: inline;
			}
		}

		.link-text {
			margin-left: 0.5rem;
			display: none;
			font-size: 0.625rem;
			line-height: 0.9375rem;
			font-weight: 400;
			color: $color-blue;
		}
	}
}
