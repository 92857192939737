.playlist-search-container {
	display: flex;
	flex-direction: column;
	padding: 2.5rem 2.375rem;
	background: $color-white;
	border: 1px solid $color-gray-4;

	.search-header {
		display: flex;
		align-items: center;
		margin-bottom: 2.625rem;
		border: 1px solid $color-gray-10;

		svg {
			margin: 0.75rem 0.75rem 0.75rem 1.5rem;
		}

		div {
			width: 100%;
		}
		
		input, button {
			background: $color-white;
		}
	}

	.search-body {
		display: flex;
		column-gap: 2.5rem;

		.search-sidebar {
			border: 1px solid $color-gray-10;
		}

		.search-results {
			width: 100%;
			& > div {
				padding: 0;
				background: $color-white;

				& > div:first-child {
					padding: 0;
				}
			}

			& > h5 {
				margin: 0 0 0.5rem;
				font-size: 1rem;
				font-weight: 600;
				line-height: 1.5rem;
			}

			button {
				background: none;
				border: none;
				font-size: 0.875rem;
				font-weight: 600;
				line-height: 150%;
				color: $color-green;

				&:disabled {
					opacity: 0.56;
				}
			}

			.search-selected-theme {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 2rem;
				padding: 1.125rem 1.625rem;
				background: $color-white;
				border: 1px solid $color-gray-10;

				h5 {
					margin: 0;
					font-size: 0.875rem;
					font-weight: 600;
					line-height: 19.04px;
				}
			}

			.playlist-search-row {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 1rem;
				padding: 1.125rem 1.625rem;
				background: $color-white;
				border: 1px solid $color-gray-10;

				.row-info {
					display: flex;
					align-items: center;
					column-gap: 0.625rem;

					img {
						max-width: 40px;
						max-height: 40px;
						border: 2px solid $color-gray-10;
						border-radius: 4px;
						box-sizing: border-box;
					}
	
					h5 {
						margin: 0;
						font-size: 0.875rem;
						font-weight: 600;
						line-height: 19.04px;
					}
				}
			}
		}
	}
}