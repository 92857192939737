.metric-percentage-list {
	.card-body {
		padding: 32px 0 0 !important;
	}

	.column-title {
		text-transform: uppercase;
		padding: 0px 20px 10px;
	}

	.list-row {
		border-bottom: 1px solid $color-gray-2;

		.list-row--percentage-fill {
			background-color: $brand-purple;
			opacity: 0.2;
			height: 100%;
			position: absolute;
			left: 0;
		}

		.list-row--title {
			padding: 10px;

			img,
			svg {
				height: 1rem;
				width: 1rem;
				font-size: 1rem;
			}
		}

		.list-row--text {
			margin: 5px 0px 5px 10px;
			font-size: 16px;

			&.list-row--percentage {
				padding-right: 1.5em;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		&.loading {
			padding: 10px;
		}
	}
}

// Animate bar fill on load
.list-row--percentage-fill {
	transition: width 1s ease-in-out;
}
.list-row--percentage-fill.fill-enter,
.list-row--percentage-fill.fill-appear {
	width: 0 !important;
}
.list-row--percentage-fill.fill-enter-active,
.list-row--percentage-fill.fill-appear-active {
	width: auto;
}
