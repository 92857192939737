.episode-studio {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 217px);

	> .container-fluid {
		padding: 1em 3em 0 1em;
	}

	.studio--content {
		display: flex;
		justify-content: stretch;
		align-items: stretch;
		flex: 1;
		max-width: 1500px;
		margin: 0 auto;
		overflow-x: hidden;
		overflow-y: auto;
		min-height: 0;
		position: relative;

		> .row {
			flex: 1;
			> div {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
		}

		.drawer-tabs {
			position: absolute;
			right: 1rem;
			top: 4rem;

			.drawer-tab {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 32px;
				height: 32px;
				margin-bottom: 0.5rem;
				border-radius: 3px;
				cursor: pointer;

				&:hover,
				&.active {
					background: $color-gray-10;
				}

				.pulse {
					animation: pulse 1s linear infinite;

				}

				@keyframes pulse {
					0% {
						-webkit-transform: scale(0.95);
						transform: scale(0.95);
						opacity: 0.5;
					}
					70% {
						-webkit-transform: scale(1);
						transform: scale(1);
						opacity: 1;
					}

					100% {
						-webkit-transform: scale(0.95);
						transform: scale(0.95);
						opacity: 0.5;
					}
				}
			}
		}
	}

	.studio--waveform {
		display: flex;
		justify-content: stretch;
		align-items: stretch;
		flex-direction: column;
		background: $brand-primary;
		margin-top: auto;
		height: 100px;
		padding: 0 1em;
		z-index: 5;
	}

	.btn--create-clip {
		padding-left: 2em;
		padding-right: 2em;
	}

	.info-icon {
		margin-top: -4px;
	}
}

.studio--content {
	.studio-content--title {
		font-size: 1rem;
		font-weight: 600;
		margin-left: 0.5em;
		margin-bottom: 1em;
		text-transform: uppercase;
	}
	.studio-content--card {
		display: flex;
		min-height: 0;
	}
	.studio--transcript-card {
		flex: 1;
	}

	.row-transcript-container {
		height: 100%;

		& > div {
			position: initial;
		}

		& > .show-drawer {
			padding-right: 2rem;
		}
	}
}

.studio--clips {
	display: flex;
	flex-direction: column;
	min-height: 0;
}

.hidden {
	display: none !important;
}
