.guest-row {
	padding: 1em;
	margin: 0 1px 1em;
	background: $color-white;
	border: 1px solid $color-gray-2;
	border-radius: 4px;
	align-items: center;
	justify-content: space-between;

	.guest-row--thumbnail {
		width: 100%;
		height: auto;
		border-radius: 2px;
	}

	.guest-row--initials-thumbnail {
		border-radius: 999px;
		height: 70px;
		width: 70px;
		background-position: center;
		background-size: cover;
		background-color: $color-gray-2;
	}

	.guest-row--name {
		font-size: 1rem;
		margin-bottom: 0;
		padding-top: 1em;
	}

	.guest-row--title {
		font-size: 0.8rem;
		color: $color-gray-7;
		opacity: 0.75;
	}

	.guest-row--description {
		padding: 1.5em;
	}

	.guest-row--link {
		color: $brand-purple;
		text-decoration: underline;
		padding-left: 1.25em;
		&:hover {
			color: darken($brand-purple, 10%);
		}
	}

	.guest--actions {
		text-align: right;
		padding-right: 0.5em;
		.dropdown-toggle {
			display: inline-block;
			border-radius: 100%;
			width: 30px;
			height: 30px;
			text-align: center;
			align-items: center;
			border: 1px solid $color-gray-7;
			color: $color-gray-7;
			cursor: pointer;
			transition: all 0.1s ease-in-out;
			svg {
				margin-top: 1px;
			}
			&:hover {
				background: $color-gray-7;
				color: $color-white;
			}
			&:after {
				content: none;
				display: none;
			}
		}
	}
}

.guest-info {
	position: relative;
	margin-top: 1em;

	&:first-of-type {
		margin-top: 0;
	}
}

.divider {
	height: 1px;
	background-color: $color-gray-2;
	position: absolute;
	left: 0;
	right: 0;
}
