.new-permission-button {
	display: flex;
	align-items: center;
	margin-left: -20px;
	letter-spacing: normal;

	&.btn-success:disabled {
		background: transparent;
		opacity: 0.5;
		cursor: not-allowed;
	}

	&.btn-success:focus {
		outline: none !important;
		outline-offset: none !important;
		border-color: none !important;
		box-shadow: none !important;
	}

	&.button-flat:hover {
		color: #007994 !important;
	}

	&.button-flat {
		color: #007994 !important;
	}

	&.button-flat.remove:hover {
		color: $brand-red !important;
	}

	&.button-flat.remove {
		color: $brand-red !important;
	}

	.permission-button-text {
		text-transform: capitalize !important;
		font-size: 14px;
		font-weight: 600;
		margin-left: 4px;
	}
}

.permissions-list {
	height: 350px;
	overflow: auto;

	.permissions-list-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 0px;
		margin: 0px 20px;
		border-bottom: 1px solid #e8e8e8;

		.collection-name {
			font-weight: 600;
		}
	}
}

.permission-list-empty {
	display: flex;
	justify-content: center;
	padding-top: 50px;
	font-size: 20px;
	font-weight: 600;
}

.add-permission-body {
	background: $color-gray-1;
}

.add-permission-footer {
	background: $color-gray-1;
	display: flex;
	justify-content: center;

	.add-permission-button {
		width: 45%;
	}
}
