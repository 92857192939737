.casted-search-modal {
	.modal-dialog {
		max-width: none;
		margin-top: 0.5rem;

		.modal-content {
			background: none;
			flex-direction: row;
			justify-content: center;
			border: none;

			.fake-sidebar {
				width: 230px;
			}

			.search-container {
				position: relative;
				max-width: 921px;
				background: $color-white;
				border-radius: 0.1875rem;
				width: 100%;
				border: 1px solid rgba(0, 0, 0, 0.2);

				.casted-search-modal-close {
					position: absolute;
					right: 0rem;
					top: 0rem;
					height: 2.5rem;
					width: 2rem;
					font-size: 1.5rem;
					background: #f2f4f5;
					cursor: pointer;
				}
			}
		}
	}
}
