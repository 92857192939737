h1, h2, h3, h4, h5, a {
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

h1, h2, h3, h4, h5 {
    letter-spacing: .5px;
}

.form-label {
    margin-bottom: 0;
}

.label-btn-text,
.form-label {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

.helper-text {
    font-size: 0.75rem;
}

.helper-text-sm {
    font-size: 0.6rem;
}

.int-link {
    line-height: 20px;
    font-size: 0.875rem;
    color: $color-blue;

    &:hover {
        text-decoration: none;
        color: darken($color-blue, 10%);
    }
}

.ext-link {
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 1.2px;
    text-decoration: underline;
    text-transform: capitalize;
    color: $brand-purple;

    &:hover {
        color: darken($brand-purple, 10%);
    }
}