html {
	height: 100%;
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	font-family: proxima-nova, 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
	background-color: $brand-background;
	color: $brand-font-color;
	overflow: hidden;
}

input,
textarea,
button,
a,
span {
	font-family: proxima-nova, 'Montserrat', sans-serif;
}

.root {
	height: 100%;
	overflow: scroll;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

ul,
ol,
li {
	list-style-type: none;
}

::-webkit-scrollbar {
	display: none;
}

.__react_component_tooltip {
	z-index: 99999 !important;
}

.ReactModal__Content {
	border: none !important;
}

.ReactModal__Overlay {
	background-color: rgba(3, 28, 51, 0.42) !important;
}

.break {
	flex-basis: 100%;
	height: 0;
	line-height: 0;
}

// BOOTSTRAP OVERRIDES
.app {
	// Cards
	.card {
		box-shadow: 0 0 1px rgba($color-gray-6, 0.75);
		border: none;
	}

	// Badges
	.badge.badge-pill {
		text-transform: uppercase;
		font-weight: normal;
		font-size: 0.75rem;
		padding: 0.5em 1em;
	}

	// Btns
	.btn {
		&.btn-light {
			background: $color-gray-4;
			&:hover {
				background: darken($color-gray-4, 10%);
			}
		}
	}
}

.modal .modal-dialog {
	@media (min-width: 576px) {
		max-width: 580px;
	}
	.modal-content {
		border-radius: 1em;
		.modal-header {
			border-bottom: none;
			.modal-title {
				margin: 0.5em auto 0;
			}
			.close {
				margin: 0;
				padding: 0;
				width: 1em;
				height: 1em;
				position: absolute;
				top: 1em;
				right: 1em;
				& > span {
					display: none;
				}
				&:after {
					content: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 352 512" height="1.25em" width="1.25em" xmlns="http://www.w3.org/2000/svg"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>');
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
		.modal-footer {
			border: none;
		}
	}
}
