.casted-toggle-button-group {
	width: 100%;

	.btn {
		background-color: $brand-background;
		color: $color-gray-7;

		&.active {
			background-color: $color-gray-8 !important;
		}
	}

	&.green {
		.btn {
			background-color: $color-green-1;
			border: none;
			padding: 0.75rem 0.5rem;
			color: $color-white;
			font-weight: 700;
			font-size: 0.75rem;
			line-height: 1.125rem;
			letter-spacing: 0.075rem;
			text-transform: uppercase;

			&.active {
				background-color: $color-green-2 !important;
			}

			&:hover {
				background-color: $color-green-2;
			}
		}
	}
}
