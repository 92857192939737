.modal-dialog.modal-extrawide {
  max-width: 80%;
}

.metric-clip-modal {
  padding: 1em 2em;
  
  .top-card {
    background-color: $color-gray-2;
    padding: 2em;
    
    .card {
      height: 100%;
    }
    
    .clip-title {
      max-width: 90%;
    }
  }
}