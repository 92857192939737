.api-keys-screen {
	.title {
		margin: 0;
	}

	.api-docs--view {
		color: $brand-purple;
		text-decoration: underline;
		height: auto;
		&:hover {
			color: darken($brand-purple, 10%);
		}
	}

	.keys-list--header {
		margin-left: 15px;
	}
}
