.four-oh-four {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  > .fof-topbar {
    height: 10%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 30px 0 30px;
    align-self: flex-start;

    .ab-fof-home-btn {
      color: $brand-font-color;
      text-decoration: none;

      &:visited {
        text-decoration: none;
      }

      > .fof-go-home-logo-btn {
        margin: 0;
        font-size: 20px;
        color: $brand-font-color;
      }
    }
  }

  > .fof-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: calc(100vh - 140px);

    > .fof-content-left {
      display: flex;
      flex: 1;
      flex-direction: column;

      > .fof-message {
        font-size: 26px;
        margin-top: 0;
        opacity: 0.75;
        max-width: 500px;
      }

      > .fof-error-code {
        font-size: 18px;
        opacity: 0.5;
        margin-top: 0;
      }

      .fof-go-home-btn {
        width: 200px;
        margin: 15px 0 0 0;
        height: 45px;

        > .fof-go-home-text {
          font-size: 14px;
        }
      }
    }

    .fof-content-right {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      > .fof-illustration {
        height: auto;
        max-width: 500px;
      }
    }
  }
}
