// brand colors
$brand-background: #f6f9fa;
$brand-font-color: #212020;
$brand-primary: #14283d;
$brand-secondary: #134ac1;
$brand-yellow: #ffb830;
// $brand-green is #3EBB85
$brand-green: rgb(62, 187, 133);
$brand-red: #f6605f;
$brand-red-dark: #8b2d32;
$brand-pink: #f7d3ff;
$brand-blue: rgb(44, 31, 155);
$brand-orange: #f3a43a;
$brand-yellow: #f0b725;
// $brand-purple is #7769e9
$brand-purple: rgb(119, 105, 233);
$brand-light-purple: rgba(119, 105, 233, 0.2);
$brand-dark-purple: #231f70;
$brand-dark-purple-2: #010a49;
$brand-background-blue: #010c2e;

$ai-blue: #14A7D6;

// bootstrap override
$theme-colors: (
	'primary': $brand-primary,
	'secondary': $brand-secondary,
	'success': $brand-green,
	'danger': $brand-red,
	'warning': $brand-yellow,
	'info': $brand-purple,
);

// Overwrite Bootstrap defaults
$theme-colors: (
	'primary': $brand-primary,
	'secondary': $brand-secondary,
	'success': $brand-green,
	'danger': $brand-red,
	'warning': $brand-yellow,
	'info': $brand-purple,
);

// alerts
$alert-green: $brand-green;
$alert-yellow: $brand-yellow;
$alert-red: $brand-red;
$alert-blue: $brand-secondary;

// grays
$color-white: #fff;
$color-off-white: #fcfcfc;
$color-gray-1: #fcfcff;
$color-gray-2: #f2f4f5;
$color-gray-3: #d3d0cb;
$color-gray-4: #efeff4;
$color-gray-5: #f7f7f7;
$color-gray-6: #b3b3b3;
$color-gray-7: #424b5a;
$color-gray-8: #031c33;
$color-gray-9: #868e96;
$color-gray-11: #ced4da;
$color-gray-12: #6c757d;
$color-gray-13: #e7e7e7;
$color-gray-14: #7c8a95;
$color-gray-15: #f8fafb;
$color-gray-16: #e3e3e3;
$color-gray-17: #f1f1f1;
$color-gray-18: #585858;
$color-gray-19: #e9e9e9;

//Design system colors start https://www.figma.com/file/mu3I9laSymYi83KyXnc9rW/Casted-Design-System-%7BIn-Progress%7D?node-id=0%3A1
$color-gray-10: #ebecee;
//Design system colors end

// blues
$color-blue: #007bff;
$color-blue-1: #1F78B4;

// greens
$color-green: #01a662;
$color-green-1: #3ebb85;
$color-green-2: #347e5e;

// purples
$color-purple: #ced5ec;
$color-purple-1: #beabfa;

// responsive breakpoints
$mobile-portrait-width: 415px;
$mobile-landscape-width: 800px;
$tablet-portrait-width: 768px;
$tablet-landscape-width: 1025px;

// dimensions
$appbar-height: 52px;
$sidebar-width: 230px;
$shared-episode-fixed-player-height: 60px;

// z-index
$appbar-z-index: 10;
$sidebar-z-index: 11;
$popover-z-index: 100001;
$toast-z-index: 9999999;

// box shadows
$box-1: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
$box-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$box-bottom: 0px 11px 13px -8px rgba(189, 183, 189, 0.3);

// scrollbar
%scrollbar-base {
	display: block;
	width: 5px;
	height: 5px;
	background-color: $color-gray-2;
	border-radius: 5px;
}

%scrollbar-thumb {
	background-color: #b6c7d0;
	border-radius: 5px;
}

%scrollbar-track {
	border-radius: 5px;
	background-color: $color-gray-2;
}

// text
$font-family-sans-serif: proxima-nova, 'Montserrat', sans-serif;
$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;
