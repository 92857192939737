.studio--waveform {
	color: $white;
}

.casted-waveform-wrapper {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0.5em auto 0;
}

.casted-waveform--play {
	width: 60px;
	height: 60px;
	margin-right: 1em;
	color: $white;
	border: 2px solid $white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:hover {
		background: rgba($white, 0.1);
	}
	.play {
		margin-right: -2px;
	}
}

.casted-waveform--total-length {
	margin-left: 1em;
	font-weight: 600;
	font-size: 14px;
}

.casted-video-processing {
	display: flex;
	justify-content: center;
	position: relative;
	width: 120px;
	//Something is preventing the waveform from being flush with the bottom of the page.
	//Setting height to 97px is a temporary fix to match the waveform height
	height: 97px;
	padding: 0.5rem;
	box-sizing: border-box;
	background: $brand-dark-purple-2;
	cursor: pointer;

	.info-circle {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		color: #f8f9fa;
	}
}

.casted-waveform--waveform {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-self: stretch;
}

.casted-waveform {
	flex: 1;

	wave {
		cursor: crosshair;

		.wavesurfer-region {
			height: calc(100% - 0.5em) !important;
		}

		&::-webkit-scrollbar {
			@extend %scrollbar-base;
		}

		&::-webkit-scrollbar-thumb {
			@extend %scrollbar-thumb;
			background: #838e93;
		}

		&::-webkit-scrollbar-track {
			@extend %scrollbar-track;
			background: lighten($brand-primary, 15%);
		}
	}
	&.has-region wave {
		cursor: auto;
	}
	showtitle {
		> div {
			position: absolute;
			transform: translateX(-50%);
			padding: 4px 12px;
			background-color: #64b889;
			border-radius: 5px;
			color: #fff;
			font-size: 12px;
			font-weight: bold;

			&::before {
				content: '';
				position: absolute;
				bottom: -5px;
				left: 50%;
				margin-left: -4px;
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid $brand-green;
			}
		}
	}
	cursor::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: -4px;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid $brand-green;
	}
}

.wavesurfer-region {
	background-color: rgba(144, 134, 224, 0.4) !important;
	// cursor: grabbing !important;
	z-index: 9 !important;
	border: 1px solid rgba(238, 243, 247, 0.05);
	border-radius: 4px;
	overflow: visible;

	&[data-region-class='suggested'] {
		background-color: rgba($ai-blue, 0.4) !important;
		border: 1px solid rgba($ai-blue, 0.8) !important;
	}

	&[data-region-class='hide'] {
		display: none !important;
	}

	wave {
		border-right: none !important;
	}

	&[data-region-class='selected'] {
		background-color: rgba(144, 134, 224, 0.4) !important;
		// background-color: rgba(144, 134, 224, 0.9) !important;
		border: 1px solid $brand-purple;
		opacity: 1;
	}

	> .wavesurfer-handle {
		cursor: col-resize;
		position: absolute;
		left: 0;
		top: 50% !important;
		// vertically center absolute positioned handle
		transform: translate(-50%, -50%) !important;
		width: 7px !important;
		max-width: 100px !important;
		height: 35px !important;
		background-color: $color-white !important;
		border: 1px solid rgba(0, 0, 0, 0.32);
		border-radius: 4px;
		z-index: 99;
	}

	&[data-region-class='hovering'] {
		.wavesurfer-handle,
		.wavesurfer-remove {
			display: none;
		}
	}

	> .wavesurfer-handle-end {
		left: 100% !important;
	}
}

.wavesurfer-remove {
	position: absolute;
	top: -5px;
	right: -25px;
	width: 25px;
	height: 20px;
	cursor: pointer;
	z-index: 999;
	&:after {
		content: '\e436';
		font-family: 'uifont';
		position: relative;
		display: block;
		vertical-align: top;
		text-align: center;
		line-height: 20px;
		font-size: 1em;
		color: $color-white;
	}
	&.hovering {
		display: none;
	}
}

.waveform-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -1em;
	margin-left: -1em;
}
