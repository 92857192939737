.content-mix-chart {
	.recharts-legend-wrapper {
		padding-left: 5rem;

		.legend {
			display: flex;
			flex-wrap: wrap;

			li {
				display: flex;
				padding: 0.3125rem 1rem;
				align-items: center;
				min-width: 230px;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1rem;
				cursor: pointer;

				&:hover,
				&.active {
					font-weight: 700;
					line-height: 19.49px;
				}

				.item-color {
					display: inline-block;
					margin-right: 0.75rem;
					width: 1.5rem;
					height: 1.5rem;
					border-radius: 8px;
				}

				.recharts-wrapper {
					margin-right: 0.75rem;
				}
			}
		} 
	}

	&.legend-vertical {
		.recharts-legend-wrapper {
			left: 0 !important;
			padding: 0;

			.legend {
				flex-direction: column;
				padding: 0;

				li {
					padding: 0.3125rem 0;
                    cursor: default;

                    &:hover,
                    &.active {
                        font-weight: unset;
                        line-height: unset;
                    }
				}
			}
		}
    }
}
