$progress-bar-height: 20px;

.upload-progress {
	flex: 1;
	background: $color-white;
	padding: 3px;
	box-sizing: border-box;
	height: $progress-bar-height;
	border-radius: 4px;

	.bar {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.7s ease-out;
		border-radius: 4px;
		background: $brand-purple;
		width: 0;
		height: $progress-bar-height - 6;
		color: white;
	}
}
