.base-table {
	border: 1px solid #E9E9E9;
	border-radius: 10px 10px 0 0;

	* {
		font-size: 14px;
	}

	.rdt_TableHeader > div {
		font-size: 1rem;
		font-weight: 600;
		letter-spacing: 0.5px;
	}

	.rdt_TableCol,
	.rdt_TableCell {

		&:first-of-type {
			position: sticky;
			left: 0;
			background-color: inherit;
			z-index: 2;
			border-right: 1px solid rgba(0, 0, 0, 0.12);
			font-weight: 600;

			> div:first-child {
				white-space: normal;
				padding: 1em 0;
			}
		}
	}

	&.clickable {

		.rdt_TableCell:first-of-type {
			text-decoration: underline;
			text-decoration-thickness: from-font;

			&:hover {
				font-weight: 700;
			}
		}
	}

	.rdt_Pagination {
		justify-content: space-between;
		padding: 0 1.5em;
		color: black;

		> span {

			&:before {
				content: 'Showing ';
			}

			margin: 0;
		}

		button {
			border: 1px solid $color-gray-6;
			border-radius: 3px;
			padding: 3px;
			margin: 0 2px;
			height: 1.5em;
			width: 1.5em;

			svg {
				position: absolute;
				top: 50%;
				margin: 0 auto;
				transform: translateY(-50%);
				left: 0;
				right: 0;
			}
		}
	}

	.table-logo {
		margin-left: 0.5em;
		max-height: 2em;
	}
}

.long-title-popover {
	border: 1px solid #F5F5F5;
	box-shadow: 0px 4px 4px 0px #00000040;
	border-radius: 8px;

	.arrow {
		visibility: hidden;
	}
}
