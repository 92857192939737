.share-search-container {
	display: flex;
	flex-direction: column;

	.share-search-header {
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		border: 1px solid $color-gray-10;

		.search-icon {
			width: 60px;
			background-color: $color-white;
			height: 40px;
			border-radius: 3px 0px 0px 3px;
		}

		svg {
			margin: 0.75rem 0.75rem 0.75rem 1.5rem;
		}

		div {
			width: 100%;
		}

		input,
		button {
			background: $color-white;
		}
	}

	.share-search-body {
		display: flex;
		column-gap: 1.5rem;

		.share-search-sidebar {
			border: 1px solid $color-gray-10;
		}

		.share-search-results {
			width: 100%;
			& > div {
				padding: 0;

				& > div:first-child {
					padding: 0;
				}
			}

			& > h5 {
				margin: 0 0 0.5rem;
				font-size: 1rem;
				font-weight: 600;
				line-height: 1.5rem;
			}
		}
	}
}
