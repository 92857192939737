.playlist-action {
	color: #007994;
	display: flex;
	align-items: center;
	font-weight: 600;
	padding-right: 40px;

	.toggle-icon {
		margin-right: 4px;
	}

	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}
