.rss-feed-section {
	height: 100%;
}

.show-admin--section {
	padding-bottom: 2em;
	margin-bottom: 2em;
	border-bottom: 1px solid $color-gray-4;
	&:last-child {
		border-bottom: none;
	}

	a {
		color: $brand-secondary;
	}

	.admin-section--checkbox {
		font-size: 14px;
	}

	.btn {
		padding-left: 2em;
		padding-right: 2em;
		margin-left: 1em;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 0.9rem;
	}
}

.rss-feed-input-group {
	input {
		flex: 1;
	}
}

.show-admin--danger-zone {
	p {
		margin-bottom: 0;
	}
	.col-action {
		margin-left: auto;
		text-align: right;
	}
}
