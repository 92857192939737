.theme-drawer {
	width: 100% !important;

	.theme-dropdown {
		margin: 1rem;

		.dropdown-menu {
			margin: 0 1rem !important;
		}
	}
}
