.modal-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex; 
  align-items: center; 
  justify-content: center;
  z-index: 1000; 
}

.modal-container {
  background: #fff; 
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  position: relative; 
}

.modal-container h2 {
  margin-bottom: 20px;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.mapping-section {
  margin-bottom: 20px;

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .mapping-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .field-name {
      flex: 1; 
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px; 
      text-align: left; 
    }

    .field-dropdown {
      flex: 2; 
      select {
        width: 100%;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        color: #333;
        transition: border-color 0.3s ease;

        &:focus {
          border-color: #007bff; 
          outline: none;
        }
      }
    }
  }
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

}