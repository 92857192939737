.episodes {

  .create-new-episode-link {
    width: 120px;
    height: 30px;

    .create-new-episode-btn {
      height: 30px;
      width: 120px;
      margin: 0;
    }
  }

  .episodes-list {
    margin-top: 10px;
  }
  .infinite-scroll--bottom {
    color: $color-gray-6;
  }
}