.add-edit-promotion {
	display: flex;
	flex-direction: column;
	padding: 1.875rem 2.5rem;
	width: 100%;
	max-width: 830px;

	.promotion-nav {
		h2 {
			display: flex;
			align-items: center;
			width: max-content;
			margin: 0 0 1.5rem;
			text-transform: capitalize;
			cursor: pointer;
			font-size: 1.75rem;
			font-weight: 400;
			line-height: 2.125rem;
		}

		.arrow-left {
			margin-right: 0.5rem;
			font-size: 1rem;
		}
	}

	.promotion-loading-container {
		padding: 2.5rem;
		background: $color-white;
		border-radius: 4px;
	}

	.promotion-body {
		padding: 2.5rem;
		background: $color-white;
		border-radius: 4px;

		.promotion-conversion {
			display: flex;
			flex-direction: column;
			row-gap: 1rem;
			margin-bottom: 2rem;
			padding: 1rem;
			border: 1px solid $color-gray-11;
			border-radius: 4px;

			& > div {
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					margin-right: 0.5rem;
				}
			}

			p {
				margin: 0;
				font-weight: bold;
				font-size: 14px;
				text-align: center;
			}
		}

		.casted-toggle-button-group {
			display: flex;
			max-width: 490px;

			.btn {
				flex: 1;
			}
		}

		.promotion-dates {
			display: flex;
			align-items: center;
			margin-top: 1.5rem;
			margin-bottom: 2.5rem;

			& > span {
				margin: 0 0.625rem 0.625rem;
				font-size: 1rem;
				font-weight: 700;
				line-height: 150%;
			}

			.react-date-picker {
				max-width: 170px;

				.react-date-picker__wrapper {
					flex-direction: row-reverse;
				}
			}
		}
	}

	.promotion-loading-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.audio-player,
	.video-player {
		margin-bottom: 1em;
	}
}

.overwrite-promotion-modal {
	.modal-confirmation-text {
		white-space: pre-wrap;
		line-height: 1rem;
		margin: 0.5em 0;
	}

	.promotion-name {
		padding-left: 1em;
	}

	.promotion-warning {
		font-weight: bold;
	}

	.modal-footer {
		display: block;
	}

	.btn-danger {
		float: right;
	}
}
