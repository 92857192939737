.resources {
	.remove-item--dropdown {
		display: none;
		position: absolute;
		top: 1.5em;
		right: 0;
		z-index: 999;
		background-color: $color-white;
		box-sizing: border-box;
		border: 1px solid #dddddd;
		border-radius: 4px;
		text-align: center;
		transition: background-color 0.1s ease, color 0.15s ease;

		p {
			margin: 0;
			padding: 0.5em 1em;
			font-size: 16px;
			transition: 0.3s all ease;
		}

		&.visible {
			display: block;
			box-shadow: $box-1;

			&:hover {
				background: $brand-primary;
				color: $color-white;
				cursor: pointer;
			}
		}
	}

	.add-item {
		border: 1px solid $color-gray-2;
		border-radius: 3px;
		background-color: $color-white;
		transition: 0.1s all ease-in;
		max-height: 115px;

		&:hover {
			transform: translate(0px, -3px);
			-webkit-box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
			-moz-box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
			box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
			cursor: pointer;
		}

		.add-item--inner {
			background-color: $color-gray-2;
			text-align: center;

			p {
				margin: 0;
				font-weight: 700;
				text-transform: uppercase;
			}

			.add-item--icon {
				font-size: 24px;
			}

			p,
			.add-item--icon {
				color: $color-gray-8;
				opacity: 0.5;
			}
		}
	}

	.resources-info {
		color: #7c8a95;
		font-size: 12px;
	}

	.resource {
		background-color: $color-white;
		transition: 0.1s all ease-in;

		.container {
			position: relative;
		}

		&:hover {
			transform: translate(0px, -3px);
			-webkit-box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
			-moz-box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
			box-shadow: 2px 2px 5px 0px rgba(211, 208, 203, 1);
		}

		&.add-resource {
			padding: 5px;

			.add-resource--inner {
				background-color: $color-gray-2;
				text-align: center;
				padding: 90px 0px;

				.add-resource--icon,
				p {
					color: $color-gray-8;
					opacity: 0.5;
					transition: 0.2s all ease-in-out;
				}

				&:hover {
					.add-resource--icon,
					p {
						color: $brand-purple;
						opacity: 0.9;
					}
				}
			}

			.add-resource--icon {
				color: $color-gray-8;
				font-size: 24px;
				margin-bottom: 20px;
			}
		}

		.delete-resource {
			.icon-circle-container {
				top: 0;
				right: 0;
			}
		}

		.icon-circle-container {
			height: 20px;
			width: 20px;
			border-radius: 50%;
			border: 1px solid $color-gray-6;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			margin-right: 12px;
			position: absolute;
			right: 10px;
			top: 0;

			.icon-trash {
				font-size: 12px;
				color: $color-gray-6;
			}

			&:hover {
				border-color: $brand-red;
				.icon-trash {
					color: $brand-red;
				}
			}
		}

		.name-and-url-container {
			flex: 1;

			.input-group {
				width: 100%;

				input {
					box-sizing: border-box;
					font-size: 16px;
					border: 1px solid #c6d0d3;
					border-radius: 3px;
					outline: none;
					width: 100%;
				}
			}
		}
	}
}
