.share-card {
	display: flex;
	padding: 1rem 1.5rem 1.5rem;
	background: $color-white;
	box-shadow: 0px 4px 4px rgba(106, 106, 106, 0.15);
	border-radius: 4px;
	margin-bottom: 20px;

	&.disabled {
		opacity: 0.4;
	}

	.share-card-left {
		display: flex;
		align-items: center;

		.share-thumbnail {
			margin-right: 15px;
			height: 65px;
			width: 65px;

			.thumbnail {
				max-width: 100%;
				max-height: 100%;
			}

			.no-thumbnail {
				max-width: 100%;
				max-height: 100%;
				background: $color-purple-1;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 2px solid $color-gray-10;
			}
		}
	}

	.share-card-right {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		width: 100%;

		.share-text {
			display: flex;
			flex-direction: column;

			.share-details {
				display: flex;
				justify-content: space-between;

				.share-info {
					display: flex;
					padding-bottom: 5px;

					.detail {
						margin-right: 21px;
						display: flex;

						b {
							margin-right: 4px;
						}

						.share-card-title {
							max-width: 280px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							display: block;
						}

						.share-card-title:before {
							content: attr(title);
						}
					}
				}

				.content-type {
					display: flex;
					align-items: center;

					.type {
						padding-right: 8px;
						font-weight: 600;
						font-size: 15px;

						img {
							height: 15px;
						}

						&.gray {
							color: $color-gray-9;
						}

						&.sky {
							color: #72a8d7;
						}

						&.navy {
							color: #01315f;
						}

						&.purple {
							color: #633ce7;
						}
					}
				}
			}

			.share-description {
				max-height: 45px;
				overflow: hidden;

				.empty-description {
					color: $color-gray-9;
				}
			}
		}

		.share-card-actions {
			button {
				width: 220px;
				margin-top: 1.5rem;
			}

			.edit-button {
				margin-right: 20px;

				&.disabled {
					cursor: not-allowed;
				}
			}
		}
	}
}
