.metric-bar {
  position: relative;
  width: 100%;
  height: 14px;
  background-color: $brand-light-purple;
  border-radius: 3px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .metric-bar--value {
    background: $brand-purple; 
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all .2s;

    .metric-bar--label {
      position: absolute;
      top: -2em;
      color: $color-gray-6;
      &.bar-max--label {
        right: 0;
      }
    }
  }
}

@keyframes loadingBar {
  from {
    background-position: 100% 0;
  }

  to {
    background-position: 0% 0; 
  }
}