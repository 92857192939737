.imported-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1.875rem 2.5rem;

	p {
		max-width: 650px;

		&.bold {
			font-size: 1.125rem;
			font-weight: 600;
			line-height: 15px;
		}
	}

	.podcat {
		margin-top: 4rem;
	}

	.no-content {
		p {
			margin-bottom: 1.5rem;

			&.bold {
				margin-bottom: 3.4375rem;
			}
		}
	}

	.connected-no-content {
		margin-top: 1rem;

		p {
			margin-bottom: 1.875rem;
		}

		button {
			padding: 1rem 2.5rem;
			font-size: 1rem;
			line-height: 15px;
			font-weight: 600;
		}
	}
}

.import-content-modal {

	.available-blogs-loading {
		display: flex;
		justify-content: center;
	}

	.blog-checkbox {
		display: flex;
		align-items: center;
		margin-top: 0;
	}

	.import-content-submit {
		display: block;
		margin: 0 auto;
	}
}
