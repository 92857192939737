.casted-modal {
	display: flex !important;
	align-items: center;

	&.xsm {
		.modal-dialog {
			width: 30%;
			max-width: 548px;
		}
	}
	&.sm {
		.modal-dialog {
			width: 80%;
			max-width: 580px;
		}
	}
	&.wide,
	&.md {
		.modal-dialog {
			width: 80%;
			max-width: 720px;
		}
	}
	&.lg {
		.modal-dialog {
			width: 80%;
			max-width: 1140px;
		}
	}
	.modal-dialog {
		.modal-content {
			padding: 1.5rem;
			border-radius: 0.25rem;

			.modal-header {
				margin-bottom: 0.75rem;
				padding: 0 2.5rem 0 0;

				.modal-title {
					margin: 0;
				}

				.close {
					z-index: 1;
				}
			}

			.modal-body {
				padding: 0;

				p {
					font-size: 1rem;
					letter-spacing: 0;
					line-height: 1.5rem;
				}
			}

			.modal-footer {
				margin-top: 1.75rem;
				padding: 0;
			}
		}
	}

	&.company-profile-modal {
		display: block !important ;
	}
}
