.metric-circles {
	padding: 10px;
	box-shadow: none!important;
	
	.metric-circles--title {
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 1px;
		font-weight: 600;
		margin-top: -2px;
		padding-right: 10px;
		padding-bottom: 0;
		padding-left: 20px;
	}
	.info-icon {
		transform: none;
	}
	svg {
		transform: rotate(-90deg);
	}
	circle {
		stroke-width: 8;
		transition: all 0.1 ease-in-out;
	}
	text {
		transform: rotate(90deg);
	}
	.metric-circle--background {
		stroke: $color-gray-2;
	}
	.metric-circle--fill {
		stroke-linecap: round;
	}
	.metric-circle {
		&:nth-child(1n) .metric-circle--fill {
			stroke: $brand-purple;
		}
		&:nth-child(2n) .metric-circle--fill {
			stroke: $brand-orange;
		}
		&:nth-child(3n) .metric-circle--fill {
			stroke: $brand-pink;
		}

		&.is-active .metric-circle--fill {
			stroke-width: 10;
		}
	}

	.metric-circles--total {
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
		.circles-total--value {
			display: block;
			font-size: 1.5rem;
			text-align: center;
		}
		.circles-total--label {
			display: block;
			color: $color-gray-6;
			text-align: center;
		}
	}

	.metric-circle--legend-row {
		margin-top: 1em;
		.metric-circles--circle-value {
			color: $color-white;
			border-radius: 3px;
			text-align: center;
			font-weight: bold;

			&.color-0 {
				background: $brand-purple;
			}
			&.color-1 {
				background: $brand-orange;
			}
			&.color-2 {
				background: $brand-pink;
			}
		}

		.metric-circles--circle-label {
			text-align: left;
			margin-left: 0.5em;
		}

		.metric-circles--circle-total {
			text-align: right;
			color: $color-gray-6;
		}
	}
}
