.new-show-modal-permissions {
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex: 1;

	&.hidden {
		display: none;
	}

	.new-show-details-header-row {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.new-show-help-text {
		opacity: 50%;
	}

	.form-label {
		margin-bottom: 10px;
	}

	.new-show-details-container {
		display: flex;
		flex-direction: column;
		overflow: auto;
		flex: 1;
		padding: 30px 45px 35px 45px;

		.thumbnail-styles {
			width: 110px;
			height: 90px;
		}

		.error {
			font-size: 11px;
			color: $alert-red;
		}

		.new-show-details-col {
			display: flex;
			flex-direction: column;
		}

		.new-show-details-row {
			display: flex;
			flex-direction: row;
			margin-bottom: 1.5rem;
		}

		.new-show-details-form-input {
			border-radius: 4px;
			border: 1px solid $color-gray-10;
			margin-bottom: 1.5rem;

			.quill {
				padding-bottom: 40px;

				.ql-toolbar {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
				}
			}
		}
	}

	.new-show-actions-container {
		display: flex;
		flex-direction: column;
		padding-bottom: 0.5rem;

		.new-show-actions-row {
			display: flex;
			flex-direction: row;
			justify-content: center;

			button {
				height: 36px;
				text-transform: uppercase;
				padding: 4px 20px;
				display: flex;
				align-items: center;

				&.new-show-rss-btn {
					text-transform: none;
					font-size: 13px;
					font-weight: 500;
					margin-top: 13px;
					text-decoration: underline;
					letter-spacing: 0;

					&:hover {
						color: $brand-purple;
					}
				}
			}
		}
	}
}
