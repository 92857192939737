.managed-content {
	.plus-icon {
		margin-right: 0.5rem;
		color: $brand-purple;
		font-size: 1.25rem;
	}

	.content-page-button-text {
		font-weight: 600;
		font-size: 1.25rem;
	}

	.no-content {
		align-self: center;
	}
}
