$card-size: 180px;
$icon-spacing: 140px;

.contentCard-container {
	height: 243px;
	margin-bottom: 1em;

	.contentCard {
		position: relative;
		width: $card-size;
		display: flex;
		flex-direction: column;
		border: 1px solid $color-gray-10;
		border-radius: 8px;
		box-sizing: border-box;
		background-color: $color-white;
		transition: box-shadow 0.1s ease;

		&:hover {
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
		}

		*:first-child {
			border-radius: 8px 8px 0px 0px;
		}

		.contentCard-thumbnail {
			object-fit: contain;
			border-bottom: 1px solid $color-gray-10;
		}

		.contentCard-no-thumbnail {
			background: $color-purple-1;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				height: 100%;
				padding: 1rem;
			}
		}

		.contentCard-type-icon {
			position: absolute;
			background-color: $color-white;
			max-width: fit-content;
			display: flex;
			border-radius: 50%;
			padding: 7px;
			top: 0.5em;
			right: 0.5em;
			color: $brand-purple;
		}

		.contentCard-title {
			max-height: 42px;
			font-size: 1em;
			font-weight: 700;
			margin: 0.75em 1em;
			overflow: hidden;
		}
	}
}
