.theme-details {
	.theme-property {
		margin-bottom: 1.5rem;

		.property-value {
			display: flex;
			font-size: 1rem;

			&.capitalize {
				text-transform: capitalize;
			}

			.theme-color {
				height: 2em;
				width: 4em;
				position: relative;
				border-radius: 4px;
				border: 1px solid #343a40;
			}
		}
	}
}
