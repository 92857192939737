@font-face {
  font-family: 'uifont';
  src: url("fonts/uifont-solid.ttf") format("truetype"), url("fonts/uifont-solid.woff") format("woff");
}

@font-face {
  font-family: 'ss-standard';
  src: url("fonts/ss-standard.ttf") format("truetype"), url("fonts/ss-standard.woff") format("woff");
}

@font-face {
  font-family: 'ss-steedicons';
  src: url("fonts/ss-steedicons.ttf") format("truetype"), url("fonts/ss-steedicons.woff") format("woff");
}

[class^="icon-"], [class*=" icon-"] {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bookmark-solid:before {
  font-family: 'uifont' !important;
  content: '\e285';
}

.icon-microphone:before {
  font-family: 'uifont' !important;
  content: '\e266';
}

.icon-edit:before {
  font-family: 'ss-standard' !important;
  content: '\270e';
}

.icon-globe:before {
  font-family: 'uifont' !important;
  content: '\e515';
}

.icon-document:before {
  font-family: 'uifont' !important;
  content: '\e212';
}

.icon-check:before {
  font-family: 'uifont' !important;
  content: '\e435';
}

.icon-eyeball:before {
  font-family: 'uifont' !important;
  content: '\e584';
}

.icon-play:before {
  font-family: 'uifont' !important;
  content: '\e242';
}

.icon-play-alt:before {
  font-family: 'ss-standard' !important;
  content: '\25b9';
}

.icon-pause:before {
  font-family: 'uifont' !important;
  content: '\e244';
}

.icon-gear:before {
  font-family: 'ss-standard' !important;
  content: '\2699';
}

.icon-page:before {
  font-family: 'uifont' !important;
  content: '\e367';
}

.icon-screen:before {
  font-family: 'ss-standard' !important;
  content: '\1f4bb';
}

.icon-fastforward:before {
  font-family: 'ss-standard' !important;
  content: '\21bb'
}

.icon-fastbackward:before {
  font-family: 'ss-standard' !important;
  content: '\21ba'
}

.icon-plus:before {
  font-family: 'uifont' !important;
  content: '\e464';
}

.icon-trash:before {
  font-family: 'uifont' !important;
  content: '\e521';
}

.icon-goto:before {
  font-family: 'uifont' !important;
  content: '\e175';
}
