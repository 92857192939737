.login-button {
  margin-bottom: 14px;
  width: 254px;

  &.google-btn, &.microsoft-btn {
    border-radius: 4px;
    height: 52px;
    border: 1px solid $color-gray-2;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px 0 12px;
    background-color: $color-white;
    box-sizing: border-box;
    position: relative;

    &:hover {
      box-shadow: $box-1;
      color: $brand-secondary;
    }

    > .google-logo {
      height: 26px;
      width: 26px;
      position: absolute;
      left: 18px;
    }

    > .microsoft-logo {
      height: 23px;
      width: 23px;
      position: absolute;
      left: 20px;
    }

    > .google-login-txt {
      font-size: 15px;
      text-align: center;
      flex: 1;
      opacity: 0.8;
    }

    > .microsoft-login-txt {
      font-size: 15px;
      text-align: center;
      flex: 1;
      margin-left: 8px;
      opacity: 0.8;
    }
  }
}