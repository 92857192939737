.themes {
	display: flex;
	flex-direction: column;
	padding: 1.875rem 2.5rem;

	.themes-header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.themes-header {
			margin: 0;
			margin-bottom: 1.5625rem;
		}

		.themes-button {
			display: flex;
			padding: 1.25rem 1.5rem;
			border: 1px solid $color-gray-4;
			background-color: $color-white;
			border-radius: 53px;
			align-items: center;
			justify-content: space-around;
			font-size: 1.25rem;

			.plus-icon {
				margin-right: 0.5rem;
				color: $brand-purple;
			}

			.themes-button-text {
				font-weight: 600;
			}
		}
	}

	.themes-list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		height: 100%;

		.theme-loading-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		.theme-content-empty {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;

			.podcat {
				width: 400px;
			}

			p {
				margin-top: 1.5rem;
				max-width: 450px;
				font-size: 1.5rem;
				font-weight: 600;
				line-height: 2.0625rem;
				text-align: center;
			}
		}
	}
}
