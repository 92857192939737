.episode-detail--header {
	position: sticky;
	top: 0;
	z-index: 9;
	background: $color-white;

	.episode-detail--nav {
		justify-content: center;
		align-items: center;
		padding: 1em 0;
		margin-left: -1em;
		border-bottom: 2px solid $color-gray-4;
		.nav-item {
			position: relative;

			.episode-nav--separator {
				position: absolute;
				width: 2em;
				height: 90%;
				top: 0;
				right: -2em;
				stroke-width: 1;
				color: $color-gray-3;
			}
		}

		.nav-link {
			color: $brand-primary;
			font-weight: 600;
			margin: 0 3em;
			padding: 0.5em;
			border-bottom: 4px solid transparent;
			text-transform: uppercase;

			a {
				text-decoration: none;
			}

			&.active {
				border-bottom-color: $brand-purple;
			}

			.in-progress {
				color: $brand-red;
			}
		}
	} // end nav

	.episode-header--button {
		margin-left: 1.25rem;
	}

	.episode-header--button,
	.episode-header--button .btn {
		min-width: 5em;
		min-height: 2.375rem;
		text-align: center;
		text-transform: uppercase;
	}

	.episode-header--name {
		display: flex;
		align-items: center;
		margin: 0;
		text-transform: capitalize;

		.badge {
			margin-left: 1em;
			font-size: 0.6875rem;
			font-weight: bold;
			letter-spacing: 0.03125rem;
			line-height: 0.5rem;
			text-transform: capitalize;
		}
	}

	.episode-header--pub-date {
		font-size: 0.75em;
		font-weight: 600;
		opacity: 0.5;
		margin: 0;
	}

	.episode-detail--status {
		border-bottom: 2px solid $color-gray-4;

		.row {
			padding: 1.375rem;
			align-items: center;
			justify-content: space-between;
		}
	}

	.episode-detail--edit {
		padding: 1.375rem;
		align-items: center;
		justify-content: space-between;
		color: $color-white;
		background-color: $color-gray-8;

		.episode-header--view {

			.light {
				color: $color-white;

				&:hover {
					color: darken($color-white, 10%);
				}
			}
		}

		.episode-edit--undo {
			color: #343a40;
		}

		.episode-edit--save {
			color: white;
		}
	}
}
