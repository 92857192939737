.metric-card.card {
	position: relative;
	height: 150px;

	.metric-card--value {
		text-align: center;
		font-size: 35px;
		font-weight: 400;
		&.has-tooltip {
			cursor: help;
		}

		&.card-title {
			text-align: left;
		}
	}
	.metric-card--label {
		color: #758ea5;
		font-size: 18px;
		letter-spacing: normal;
	}
	.info-icon {
		position: absolute;
		top: 1em;
		right: 1em;
	}
	.metric-card--loading {
		text-align: center;
		margin-bottom: 1.2em;
	}
	.metric-card--comparison {
		text-align: center;
		font-weight: bold;
		display: flex;
		flex-direction: row;
		min-height: 37px;
		font-size: 25px;

		&.increase {
			color: $brand-green;
		}

		&.decrease {
			color: $brand-red;
		}

		&.neutral {
			color: #ffc107;
		}

		.comparison-icon {
			margin-top: 6px;
		}

		.empty-comparison {
			color: black;
		}

		.metric-value {
			font-weight: normal;
		}
	}
}

.hidden {
	display: none;
}

.metric-card-tooltip {
	p,
	ul,
	li,
	hr {
		margin: 0;
		padding: 0;
		text-align: left;
	}
	ul {
		margin-left: 5px;
	}
	hr {
		border: 0.5px solid $color-white;
		margin: 4px 0;
	}
}
