.generate-item-info {
    position: absolute;

    &.simple-input {
        right: 20px;
        top: 50%;
    }

    &.simple-editor {
        right: 5px;
        top: 5px;
    }

    &.rich-editor {
        right: 5px;
        top: 10px;
    }

    #generate-error-icon {
        color: $brand-red;
        margin-right: 3px;
    }

    .generate-info-button {
        &:hover {
            cursor: pointer;
            
            svg {
                color: $ai-blue;
            }
        }

        &[click-disabled="true"] {
            cursor: not-allowed;

            svg {
                color: inherit
            }
        }
       
    }

}

.generate-spinner {
    max-height: 1.5em;
    max-width: 1.5em;
}